const zhHansDictionary = {
 "commons": {},
 "customer_report": {
  "customer": "客户名称",
  "customer_report_date_range": "本报表可查询%{days} 天内资料(e.g. 2022/07/01-2022/07/30),并查询起始日期应小于等于终止日期",
  "report": "报表列表",
  "report_exclusive": "报表选项",
  "country": "进口国家选项",
  "tooltip_import_date": "进口日期",
  "option_label_import_date": "进口日期",
  "error_exceed_row_limit": "Over %{row_limit} Records, please input any milestone to limit the data scope.",
  "error_invalid_filter": "You have specified filters that this report is not supported. Please try to remove them."
 },
 "utilities_mgmt": {
  "long_polling_status_success": "成功!",
  "long_polling_status_failed": "获取资源失败，请稍后再试一次",
  "long_polling_status_running": "获取资源中，请稍候...",
  "no_match_data": "没有资料",
  "long_live_task_status_success": "成功!",
  "long_live_task_status_failed": "获取资源失败，请稍后再试一次",
  "long_live_task_status_running": "获取资源中，请稍候...",
  "long_live_task_upload_process_result_title": "Upload Process Result",
  "long_live_task_upload_process_result_total": "Total",
  "long_live_task_upload_process_result_complete": "Complete",
  "long_live_task_upload_process_result_in_complete": "In-complete",
  "long_live_task_upload_process_result_error": "error",
  "undefined": ""
 },
 "cfs": {
  "acting_station": "操作打印机站点",
  "actual_pickup": "实际提货日",
  "actual_pickup_at": "实际提货时间",
  "add_one_row": "新增一行",
  "add_route": "新增航段",
  "additional_scan": "要在物件上扫描什麽",
  "adjust_column": "Adjust Column",
  "agent": "出货代理",
  "airline_name": "航空公司名称",
  "alert_confirm_leave": "更新的资讯将不会被储存，你确定要离开？",
  "alert_save_confirm": "你确定要储存？",
  "alert_save_success": "Successfully updated.",
  "already_checked": "此物件已经检查完毕！",
  "back": "返回",
  "before_a_week": "过去1週之前",
  "before_today": "今天以前",
  "booking_at": "HAWB建立时间",
  "browse": "预览",
  "call_printer": "呼叫打印机",
  "call_printer_success": "成功呼叫打印机",
  "Can not export over 300 row": "Can not export over 300 row",
  "can_not_export_over_x_row": "无法输出%{number}以上行数",
  "Cannot Export": "无法输出数据",
  "cannot_be_blank": "不可空白",
  "cannot_export": "无法输出",
  "caution": "注意!",
  "check_failed_hint": "比对失败！请重新扫描！",
  "check_ok_hint": "比对成功！请扫描下一个！",
  "check_result": "检查结果",
  "choose_input_mode": "选择输入方式",
  "column_adjustment": "栏位调整",
  "column_with_error": "发生错误的栏位",
  "compress_img_before_upload": "上传前压缩相片",
  "consignee_name": "收货方",
  "create_date": "生成日期",
  "csv_upload": "CSV档上传",
  "customer_name": "客户名称",
  "data_in": "EDI Info",
  "data_input": "信息录入",
  "data_uploaded_failed": "注意！檔案資料中發現錯誤，請修正資料並重新上傳",
  "data_uploaded_successfully": "数据上传成功",
  "delete_confirm": "你确定要删除？",
  "delete_success": "删除成功",
  "delivery_package_number": "Pallet ID",
  "details": "细节",
  "digits": "字元",
  "dn": "DN",
  "download_template": "模板下载",
  "download_the_csv_template": "下载CSV模板",
  "edi_recieve_at": "EDI Receive Time",
  "equals": "完全相等",
  "error_message_is_already_exist": "%{column} 已存在",
  "estimated_pickup": "Estimated Pickup Date",
  "estimated_pickup_at": "预计提货时间",
  "eta": "ETA",
  "etd": "ETD",
  "export": "汇出",
  "extra_route": "额外航段",
  "failed_check": "检核失败",
  "fast_label_check_finish": "结束",
  "fast_label_check_hold_scan": "请继续扫码",
  "fast_label_check_wrong_label": "这票货扫码尚未结束！",
  "fill_in_the_house_airway_bill_no": "填入HAWB号码",
  "fill_in_the_master_airway_bill_no": "填入MAWB号码",
  "fill_in_the_pieces_of_hawb": "填入HAWB的件数",
  "fill_in_the_pieces_of_mawb": "填入MAWB的总件数",
  "fill_in_the_short_message_for_note": "填入要额外注意的资讯",
  "fill_in_the_station_code": "填入站点代码，例如TPE、HKG、PVG等",
  "finish_time": "最后扫码时间",
  "flight_number": "航班",
  "generate_pdf": "生成PDF",
  "group_box": "Group Box",
  "group_id": "Group ID",
  "hawb": "HAWB",
  "hawb_create_date": "HAWB数据建立日期",
  "hawb_dest": "HAWB进口站",
  "hawb_details": "HAWB细节",
  "hawb_done_by_fast_checking": "货品为多箱盒类型的HAWB",
  "hawb_not_completed": "尚未确认完成的HAWB",
  "hawb_orig": "HAWB出口站",
  "hawb_pallet_count_loose_carton_hint": "通常HAWB件数会等于栈板数加上散箱数。请再确认，若有必要则修改HAWB件数与包装信息",
  "hawb_pcs": "HAWB件数",
  "hawb_gw": "HAWB GW",
  "hawb_validation_error_message": "HAWB格式错误",
  "hold_on_scan_ref_no_on_package": "请扫描物件上的 %{ref_no_name}！",
  "how_to_fill_in_the_template": "如何填写模板",
  "include_in_the_list": "在清单内",
  "incorrect_qr_code": "QR Code格式错误",
  "information_input": "信息录入",
  "information_search": "信息搜寻",
  "input": "录入",
  "input_hint": "输入提示",
  "inv_no": "Invoice No",
  "INVOICE_NUMBER": "Invoice No",
  "is_additional_scan": "",
  "is_additional_scan_alert": "注意：这裡面有些标籤得要额外扫条码比对",
  "it_is_ok_to_leave_it_blank": "可以空着不填",
  "Label print record is over 5000,please contact your account manager!": "Label print record is over 5000,please contact your account manager!",
  "Label Quantity per Pallet": "每栈板标籤印列张数",
  "label_check_cannnot_scan_hint": "*注意：如果你无法扫描条码，请手动输入%{ref_no_name}的%{place}+%{number}码",
  "label_check_rule": "标籤核对规则",
  "label_check_scan_barcode_hint": "请扫描物件上的%{ref_no_name} 条码",
  "label_check_scan_hint": "请扫描鸿霖标籤上的二维码",
  "label_quantity_per_pallet": "每栈板标籤印列张数",
  "label_size": "标籤尺寸",
  "last_three_days": "过去3天",
  "last_three_days_to_a_week": "过去3天至1週",
  "last_update": "最后一次更新：%{time}",
  "latest_update_by": "最近更新人员",
  "less_than_3_days_before_etd": "距离ETD只有不到三天",
  "location_setting": "站点位置设定",
  "loose_carton": "散箱",
  "loose_cartons": "散箱数",
  "mapping_info_not_in_database": "%{column}: 数据库无此纪录",
  "mapping_info_upload_failed": "上传失败",
  "mapping_information_upload": "比对用资料上传",
  "mapping_method": "比对方法",
  "mapping_target": "要与标籤二维码的什么对应",
  "master_number": "MAWB",
  "master_pallet": "MAWB栈板数",
  "master_pieces": "MAWB件数",
  "mawb_dest": "MAWB进口站",
  "mawb_orig": "MAWB出口站",
  "move_to_urgent_confirm": "你确定要在紧急输入页面修改？",
  "necessary_to_fill_in": "必填栏位？",
  "not_same_label_size": "标籤非相同尺寸",
  "not_scanned": "未扫描",
  "not_the_same_shipment": "Fast check must to be same shipment",
  "number": "No",
  "ok": "OK!",
  "option_milstone_actual_pickup": "Actual Pickup Date",
  "option_milstone_estimated_pickup": "Estimated Pickup Date",
  "option_milstone_eta": "ETA",
  "option_milstone_etd": "ETD",
  "ordinary_label_print": "一般标籤打印",
  "other": "其他",
  "overwrite": "复写",
  "package_checked": "已确认完成的物件数",
  "package_checked_successfully": "物件检核完成",
  "package_detail": "HAWB包装细节",
  "package_failed_checked": "比对失败的物件数",
  "package_failed_checking": "比对失败的物件",
  "package": "Package ID",
  "package_id": "Package ID",
  "package_information": "包装信息",
  "package_not_checked_in_this_hawb": "未检核的物件",
  "package_not_checked_ok": "尚未确认完成的物件数",
  "package_not_checked_ok_yet": "尚未确认完成的物件",
  "package_qty": "货物件数",
  "pallets": "栈板",
  "pallet_counts": "栈板数",
  "pallet_is_not_in_task": "这张标籤不在最近检核范围内",
  "pallet_number": "栈板数",
  "part_no": "Part No",
  "PART_NUMBER": "Part No",
  "Please check row first": "Please check row first",
  "please_refresh_task_first": "Please click Refresh button first",
  "please_set_hawb_pcs": "要设定HAWB件数",
  "POSTFIX": "后",
  "PREFIX": "前",
  "print_at": "打印时间",
  "print_status": "列印状态",
  "print_status_not_printed": "未打印",
  "print_status_printed": "已打印",
  "REF": "参照编码",
  "ref_cartons": "Ref#内箱数",
  "ref_no": "参照编码",
  "ref_no_name": "参照编码名称",
  "ref_number": "Ref#",
  "refresh_task": "刷新数据",
  "refresh_task_done": "数据刷新完成",
  "refresh_task_failed": "数据刷新失败",
  "refreshing": "数据刷新中...",
  "remark": "备注",
  "reprint_label": "重印标籤",
  "result": "结果",
  "routes": "航段",
  "routing": "包含的航段",
  "routing_leg": "航段",
  "routing_all_legs": "所有航段",
  "routing_first_leg": "第一段",
  "routing_others": "第二段以及其他",
  "routing_number": "航段数",
  "row_no": "行数",
  "rule_book": "规则手册",
  "rules": "规则",
  "scan": "扫码",
  "scenario": "情境",
  "search": "查询",
  "shipment_number": "HAWB",
  "shipper_name": "发货方",
  "single_input": "单笔输入",
  "skipped_pcs": "未扫描件数",
  "sscc_code": "SSCC编码",
  "SSCC_CODE": "SSCC编码",
  "staff_belonged_station": "人员所属站点",
  "step": "步骤",
  "summary": "摘要",
  "task_summary": "任务摘要",
  "The Export Send Your Email!": "The Export Send Your Email!",
  "today": "今天",
  "todo_item_hawb_details": "今日待办事项 - HAWB细节",
  "todo_item_summary": "今日待办事项 - 总览",
  "total_cartons": "总箱数",
  "total_for_today": "今天所有的",
  "trigger_condition": "触发条件",
  "trigger_condition_package_id": "客户 + Package ID",
  "trigger_condition_place_first": "最初",
  "trigger_condition_place_last": "最后",
  "trigger_condition_vip_name": "客户",
  "trigger_condition_word_is": "字是",
  "trigger_condition_word_isnot": "字不是",
  "turnaround_station": "中转站",
  "type": "型态",
  "update": "更新",
  "update_hawb_pkg_data": "更新HAWB包装细节",
  "update_user_station_success": "使用者所在站点已成功更新",
  "upload_date": "上传日期",
  "upload_failed": "上传失败",
  "upload_step_by_step": "逐步完成上传",
  "upload_task_count": "上传任务数：%{number}",
  "upload_the_csv_file": "上传CSV档案",
  "urgent_input": "紧急输入",
  "urgent_input_error_msg_airline_not_found": "%{column}: 找不到此航空公司名",
  "urgent_input_error_msg_cannot_be_zero": "%{column}: 总件数不能为零",
  "urgent_input_error_msg_customer_not_found": "%{column}: 找不到此客户资料",
  "urgent_input_error_msg_incorrect_hawb": "%{column}: HAWB格式错误",
  "urgent_input_error_msg_incorrect_scenario": "%{column}: 错误的情境值",
  "urgent_input_error_msg_incorrect_station": "%{column}: 站点名称错误",
  "urgent_input_error_msg_negative": "%{column}: 不是正整数",
  "urgent_input_error_msg_no_data": "%{column}: 没有数据",
  "urgent_input_error_msg_incorrect_shipment_type": "%{column}: 错误的货物类型",
  "validation_failed_message": "%{type} : %{msg}",
  "vip_name": "客户",
  "watch_out": "注意!",
  "we_have": "我们有",
  "worksheet": "工作清单",
  "you_can_only_fill_u_e_or_a": "只能填入U、E或A",
  "no_label_print": "No Label Print",
  "no_mapping_info": "No Mapping info",
  "create_failed": "Create Failed",
  "no_presigned_url": "No presigned url",
  "upload_s3_failure": "Upload S3 Failure",
  "no_download_url": "No Download url",
  "site": "地点",
  "series_type": "序列号类型",
  "series_number": "序列号码",
  "reserve": "保留",
  "storage_location": "储位",
  "reference_number": "参照号码",
  "damaged": "受损",
  "record_cargo_information": "纪录货物信息",
  "temporary_series_number": "临时序列号码",
  "receive": "收货",
  "reject": "退回",
  "received_quantity": "实际收货数量",
  "last_received_date": "最后收货日期",
  "confirm_receiving": "确认收货？",
  "confirm_receiving_message": "要现在结束这笔HAWB的收货过程吗？ (HAWB件数会被已收到货物件数替换)",
  "click_here_to_print_the_storage_remark": "点击此处打印暂存单",
  "confirm_delete": "确定删除？",
  "do_you_want_to_remove_selected_photos": "确定移除选择的照片？",
  "over_max_file_count %{max_length}": "超过档案数量上限：${max_length}",
  "call_printer_success_task": "成功呼叫打印机",
  "call_printer_in_progess": "打印中请稍候",
  "selected_label": "已选标籤份数",
  "completed": "处理完成份数",
  "create_temporary_series": "建立暂存系列",
  "more_information": "更多资讯",
  "whether_to_show_on_the_label": "是否要在标签上显示",
  "display_mawb_pcs": "显示MAWB件数",
  "display_hawb_pcs": "显示HAWB件数",
  "do_you_want_to_save_the_changed_information  ": "你要储存已更动的资讯吗？",
  "actually_shown_on_the_label": "实际显示于标签：",
  "security_declaration": "保安声明书",
  "what_to_print_out": "哪些要打印",
  "label": "标签",
  "shipping_remark": "唛头",
  "display_mawb": "Display MAWB",
  "display_hawb": "Display HAWB",
  "shipment_type_hint": "只能填入字母H或D",
  "shipment_type": "出货类型",
  "revise_the_major_information_on_the_label": "修改标签上的主要信息",
  "revise_the_destination_airport": "修改目的地机场",
  "revise_reference_information_for_this_shipment": "修改此货件的参考信息",
  "revise_the_package_details_for_reference": "修改包裹详情（供参考）",
  "revise_the_remark_information_on_the_label": "修改标签上的备注信息",
  "directly_find_the_package_id_to_revise_the_remark": "直接找到 Package ID 修改备注",
  "select_the_package_id_above_and_input_the_revised_remark_information_then_click_overwrite_button": "选择上面的 Package ID，输入修改后的备注信息。然后点击覆盖按钮",
  "it_is_required_to_revise_in_urgent_input_tab_if_needed_to_change_the_package_pieces_of_apple_gar_or_garmin_or_ask_customer_to_resending_the_edi_continue": "Apple GAR或Garmin的货物若要调整件数，须在紧急输入功能下修改，否则请客户重传EDI。\\n是否继续处理？",
  "whe_cannot_you_find_the_labels": "为什么您找不到标签？",
  "URGENT_USE": "未收到EDI",
  "paste_the_label_information": "贴上标签的资讯",
  "multiple_input": "多笔输入",
  "key_points": "要点",
  "only_mawb_pcs_customer_and_remark_are_not_required_columns": "只有 MAWB Pcs、客户和备注是不要求填写的",
  "customer_is_required_to_fill_in_while_the_scenario_is_urgent_use": "当情境是 Urgent Use 时，客户是要求要填写的",
  "not_necessary_to_input_capitalized_letters": "字首不需要是大写",
  "options_for_scenario_and_label_size_are_fixed": "情境和标签大小的选项是固定的",
  "system_will_inspect_whether_you_input_the_information_correctly_or_not": "系统会检查您输入的资讯是否是正确的",
  "information_will_not_be_saved_until_all_columns_are_correct": "直到所有的栏位都正确，否则资讯都不会储存",
  "urgent_use": "未收到EDI",
  "click_here_to_update_package_id": "点击此处更新Package ID",
  "notes_of_revising_hawb_pcs": "*注意：如果你有修改HAWB件数，要记得点击%{logo}图案",
  "urgent_input_instruction_1": "只有MAWB Pcs、客户名、备注不是必填栏位。",
  "urgent_input_instruction_2": "系统预设情境是\"未收到EDI\"，如果你要选择其他情境，请将此格内容清空。",
  "urgent_input_instruction_3": "在\"未收到EDI\"情境下，标签尺寸固定是4x6。",
  "urgent_input_instruction_4": "如果情境不是\"未收到EDI\"，你可以自行选择标签尺寸。",
  "urgent_input_instruction_5": "标签尺寸只能输入4x6或4x3。",
  "urgent_input_instruction_6": "在货物类型这栏，输入H代表Hub，输入D则代表Direct Ship。",
  "urgent_input_instruction_7": "如果客户是Apple GAR，则货物类型会变成必填栏位。",
  "urgent_input_instruction_8": "英文字母输入后会自动转变为大写。",
  "urgent_input_instruction_9": "系统会检查你输入的资料是否正确。如果任何一栏存在错误，系统就不会储存输入的资料。",
  "multiple_sets_tooltip": "您可以输入多组文本，系统将根据文本准确搜索数据。",
  "type_in_keyword_tooltip": "输入关键字，并选择一个系统提示的项目",
  "select_the_scenario": "请选择适用情境",
  "input_the_conditions": "输入搜寻条件",
  "option_milstone_created_at_utc": "生成日期",
  "necessary_to_input": "必填的",
  "the_text_will_be_shown_on_all_of_the_labels": "这段文字会在所有的标签上显示",
  "necessary_to_input_only_if_scenario_is_urgent_use": "只有在情境是\"未收到EDI\"，才是必填的",
  "supplement_label": "补充标签",
  "saved_items": "已储存项目",
  "select_all": "全部选择",
  "cancel": "取消",
  "cancel_all": "全部取消",
  "printing_quantity": "打印数量",
  "why_cannot_you_find_the_labels": "为什么找不到标签？",
  "error_code_request_item_is_invalid": "%{item}是无效输入",
  "error_code_search_not_found": "找不到信息\n",
  "error_code_print_size_not_same": "不能够同时生成不同尺寸的标签档案",
  "main_page": "主页面",
  "package_level": "包装层级",
  "delivery_level": "DN层级",
  "photo_management": "图片管理",
  "select_the_milestone_to_check": "选择Milestone",
  "enter_the_hawb_or_package_id": "輸入 HAWB 或 Package ID",
  "enter_hawb_or_dn_or_package_id": "輸入 HAWB，DN 或 Package ID",
  "enter": "进入",
  "export_receiving": "出口 - 收货",
  "fuzzy_tooltip": "模糊搜寻：只须输入部分文字",
  "supplement_column_tooltip": "上传图片来生成 4x3 大小的标签",
  "choose_the_scenario": "选择情境",
  "fill_in_the_label_information": "填入标签的资讯",
  "display_airline_logo": "显示航空公司标誌",
  "hawb_postfix": "在HAWB后加上后缀",
  "urgent_input_error_msg_incorrect_label_size": "%{column}: 尺寸设定错误",
  "urgent_input_error_msg_incorrect_mawb": "%{column}: MAWB格式错误",
  "urgent_input_error_msg_cannot_type_in_numbers": "%{column}: 不能是数字",
  "urgent_input_error_msg_exceed_4_characters": "%{column}: 字节最多4个",
  "hawb_suffix": "HAWB 后缀",
  "add_suffix_in_hawb": "HAWB 加上后缀字符",
  "notice_multiple_shipment_selected": "注意！已经选择多笔出货数据",
  "photo": "照片",
  "text": "文字",
  "mnl_hongkong_airline_name_cargo": "修改航空公司名称",
  "mawb": "MAWB",
  "simple_mode": "简易模式",
  "here_you_can_only_search_the_data_that_booking_is_made_within_30_days": "这裡你只能搜寻到30天内建立的数据",
  "select_the_information_level": "請選擇資料層級",
  "photo_taking": "拍照上传",
  "take_photo_now": "開始拍照",
  "check_all_photo": "確認照片",
  "pouch_label": "小袋标签",
  "please_input_valid_shipment_number_or_package_number": "Please input valid shipment number or package ID",
  "select_the_photos_you_surely_want_to_upload": "請選擇您確定要上傳的圖片",
  "check": "確認",
  "error_label_size_not_set": "尚未设定这一尺寸的标签模板",
  "error_source_folder_not_set": "打印机路径(资料夹)尚未建立",
  "change_label_size": "自选标签尺寸",
  "choose_what_to_upload": "Choose what to upload",
  "you_cannot_select_both_hawb_and_package_id": "You cannot select both HAWB and Package ID",
  "partial_receive": "Partial Receive",
  "handled_today": "Handled Today",
  "label_quantity": "Label Quantity",
  "print_label": "Print Label",
  "you_should_select_at_least_one_hawb": "You should select at least one HAWB",
  "receive_by_package": "Receive By Package",
  "receiving": "Receiving",
  "please_scan_the_qrcode": "Please scan the QR Code",
  "just_scanned_in": "Just Scanned In",
  "status": "Status",
  "customer": "Customer",
  "package_to_receive": "Package To Receive",
  "repeat_scan": "Repeat Scan",
  "received": "Received",
  "error_shipment_can_not_split_combine": "Shipment can not split & combine",
  "carton_qty": "Carton Qty",
  "document_type": "文件类型",
  "printer_name": "打印机名称",
  "now_you_are_at": "现在你在",
  "choose_a_printer": "选择打印机",
  "select_the_default_printer": "选择默认打印机",
  "fix_the_printer_setting": "固定打印机设定",
  "please_select_printer_for_each_label_size": "请为每个标签尺寸选择打印机",
  "photo_upload": "前往拍照",
  "pallet_split_and_combine": "Pallet Split & Combine",
  "scan_the_qrcode_on_the_label": "Scan the QRcode on the label",
  "pcs": "件数",
  "urgent": "Urgent ",
  "dn_to_reallocate": "DN's to re-allocate",
  "dn_no": "DN No.",
  "print_out": "Print Out",
  "reallocate_the_dn": "Re-allocate the DN's",
  "qty": "Qty",
  "edit": "Edit",
  "save": "Save",
  "selected_qty": "Selected Qty",
  "combine_with": "Combine With",
  "splitted_packages": "调整后的物件",
  "choose_a_pallet_to_put_these_cartons": "Choose a pallet to put these cartons",
  "consignee": "Consignee",
  "brief_address": "Brief Address",
  "type_in_the_carton_qty_and_click_the_button_below": "输入箱盒数，然后点击下方按钮",
  "split_out": "Split out",
  "please_type_in_qty": "这边必须输入数字",
  "please_input_correct_number": "Please input correct number",
  "exceed_carton_qty": "Exceed Carton Qty",
  "please_allocate_all_delivery": "Please allocate all delivery",
  "already_pick_up_ofd": "Aleady Pickup (OFD)",
  "not_received": "没有Receive扫码",
  "already_put_away": "Already Put Away",
  "not_put_away_yet": "Not Put Away Yet",
  "no": "No.",
  "to_do_list": "To-do List",
  "group_put_away": "Group Put-away",
  "refresh_data": "Refresh Data",
  "open_pcs": "Open Pcs",
  "scanned": "Scanned",
  "do_you_want_to_clear_all_shipments": "Do you want to clear all shipments",
  "put_away": "Put Away",
  "error_code_cannot_find_the_location": "数据库中找不到此一储位号码",
  "error_code_resource_not_found": "%{resource} is not found",
  "error_delivery_package_can_not_put_away": "%{delivery_package_number} can not put away",
  "stored_package_qty": "Stored Package Qty",
  "delivery_no": "Delivery No.",
  "package_checker": "Package Checker",
  "consignee_address": "Consignee Address",
  "current_milestone": "Current Milestone",
  "next_milestone": "Next Milestone",
  "action_required": "Action Required",
  "latest_updated_by": "Latest Updated By",
  "latest_updated_at": "最后更新时间",
  "put_away_by": "Put away by",
  "put_away_time": "Put away time",
  "put_away_combo_mode_data_dialog_message": "为确保信息完整性，我们会在切换模式时清除暂存的扫描信息。你确定仍要切换模式？",
  "carton_sorting": "箱盒分类",
  "result_summary": "成果总结",
  "sorting_info_upload": "整理信息上传",
  "start": "开始",
  "stop": "停止",
  "scan_the_barcode": "扫描条码",
  "repeated_scan": "重复扫描",
  "wrong_barcode": "条码错误",
  "scan_result": "扫描结果",
  "package_number": "包裹号码",
  "get_report": "取得报告",
  "linked_with_hawb": "与 HAWB 连结",
  "sorting_input_date": "整理键入日期",
  "start_date": "开始日期",
  "end_date": "结束日期",
  "date_range_should_be_within_n_days": "日期范围应该在 %{days} 日内",
  "start_date_should_be_before_or_eqaul_to_end_date": "开始日期应该早于或等于结束日期",
  "date": "日期",
  "packslip_number": "装箱单号",
  "import_receive": "Import Receive",
  "remember_to_get_pod_sign_off": "Remember to get POD sign-off",
  "check_if_you_get_the_pod_copy": "Check if you get the POD copy",
  "package_checker_milestone_import_cfs_not_arrived": "Not arrived yet",
  "package_checker_milestone_truck_arrived": "Truck arrived",
  "package_checker_milestone_import_cfs_received": "Received",
  "package_checker_milestone_import_cfs_reweigh": "Reweigh & Take photos",
  "package_checker_milestone_import_cfs_sorting": "Sorting",
  "package_checker_milestone_available_for_delivery": "Put Away",
  "package_checker_milestone_import_cfs_pickup": "Picking",
  "package_checker_milestone_ofd": "Out for delivery",
  "package_checker_milestone_actual_pod": "POD",
  "cannot_find_this_delivery_package": "数据库中找不到此号码",
  "latest_updated_at_utc": "Latest Updated at (UTC)",
  "field_is_required": "%{field} is required",
  "field_is_duplicate": "%{field} is duplicate",
  "error_delivery_package_can_not_pick_up": "%{delivery_package_number} can not pick up",
  "picking_entry": "Picking-Entry",
  "scan_the_qrcode_on_the_picking_order": "Scan the QRcode on the Dispatch Manifest",
  "dispatch_no": "Dispatch No",
  "truck_pickup_time": "Truck Pickup Time",
  "bol_no": "BOL No",
  "pallet_is_not_in_dispatch": "这栈板不在此Dispatch內",
  "package_ref_info": "包装参照信息",
  "flight_status": "航班状态",
  "on_way": "在途",
  "landed": "已着陆",
  "is_checked": "已扫码检查",
  "shipment_information": "出货信息",
  "lot_no": "Lot No.",
  "bag_id": "Bag ID",
  "quantity": "数量",
  "scanned_quantity": "已扫描数量",
  "Bag Checking": "包裹检查",
  "input_the_mawb": "输入MAWB单号",
  "scan_the_qr_code_on_the_package": "扫描货件上的二维码",
  "there_is_still_information_not_uploaded": "尚有信息未上传",
  "confirm_upload": "是否确定要上传？",
  "bag_id_is_not_belong_to_master_number": "%{bag_id} 不属于 %{master_number}",
  "weight": "重量",
  "get_weight": "取得重量",
  "reweigh": "重新秤重",
  "record_the_weight_result": "Record the weight result",
  "reweigh_summary": "Reweigh Summary",
  "select": "选取",
  "link_the_scale": "连接磅秤仪器",
  "reweigh_pcs": "已重新秤重的件数",
  "reweigh_sum": "重新秤重数字加总",
  "package_to_be_reweighted": "尚待重新秤重的物件",
  "are_you_sure_to_submit_the_scanned_information": "确定要提交扫描的信息?",
  "destination": "目的地",
  "error_has_already_received": "%{number} 已收到",
  "error_has_been_already_ofd": "已经被派送出去",
  "error_has_not_received_yet": "%{number} 尚未收到",
  "error_has_not_received_all_shipment_packages_yet": "尚未收到所有栈板",
  "error_must_reweigh_and_take_pictures": "%{number} 还未对所有栈板秤重与拍照存证",
  "error_remember_reweigh_and_take_pictures": "记得要重新秤重与拍照！",
  "error_no_need_to_split_combine": "%{number} 不可拆分",
  "error_has_already_pick_up": "已扫码提货",
  "error_has_not_put_away_yet": "此栈板尚未扫码入库",
  "error_has_not_split_and_combine_yet": "还没完成栈板拆分！",
  "error_freight_is_damaged": "%{number} 货物有受损！",
  "error_delivery_can_not_recreate": "DN已经被绑定到其他单据上",
  "field_must_be_positive_integer": "%{field} 必须是正整数",
  "error_cant_upload_after_bag_checking": "扫描后无法上传货物清单",
  "bag_checking": "Bag Checking",
  "error_cannot_update_import_received_date": "Cannot Update Import Receivee Date",
  "sheet_status": "单据状态",
  "sheet_number": "单据号码",
  "destination_mec_station": "目的地(鸿霖站点)",
  "delivery_number": "DN",
  "estimated_delivery_date_sla": "SLA",
  "origin_station": "出口站",
  "destination_station": "进口站",
  "target_mec_station": "目的地(鸿霖站点)",
  "estimated_truck_pickup_at": "预计提货日期",
  "estimated_truck_pickup_at_time": "预计提货时间",
  "actual_truck_pickup_at": "实际提货日期",
  "estimated_delivery_at": "预计送达日期",
  "estimated_delivery_at_time": "预计送达时间",
  "delivery_created_at_utc": "DN创建日期(UTC)",
  "sheet_created_at": "单据生成时间",
  "is_delivery_put_on_bol": "DN是否已放上BOL？",
  "is_delivery_readiness": "DN派送准备状态",
  "split": "从单据中拆分出去",
  "created_at_utc": "创建时间(UTC)",
  "ready_for_delivery": "已准备就绪",
  "not_ready": "尚未完备",
  "at_least_one_row": "请勾选至少一个项目",
  "at_least_two_row": "请勾选至少二个项目",
  "at_least_one_transfer_manifest": "请至少勾选一个调转清单",
  "at_least_one_need_transfer_delivery": "请至少勾选一个DN",
  "cannot_combine_transfer_manifests": "无法将多张调转清单组合起来",
  "cannot_split_transfer_manifest": "无法拆分这张调转清单",
  "only_can_print_transfer_manifests": "没在调转清单上的项目无法印列",
  "cannot_create_more_transfer_manifest": "一个DN仅能用来建立一张调转清单",
  "cannot_revise_picked_data": "此单据已提货，无法异动",
  "sheet_information_cannot_be_revised": "单据资料已锁定，无法异动",
  "are_you_sure_to_create_the_sheet": "你确定要生成新的%{type}？",
  "are_you_sure_to_update_the_sheet": "你确定要更新单据信息？",
  "are_you_sure_to_cancel_the_sheet": "你确定要删除%{type}？",
  "are_you_sure_to_combine_the_sheet": "你确定要将DN併入此张%{type}？",
  "are_you_sure_to_split_the_sheet": "你确定要将DN从现有单据拆分出？",
  "carrier_code": "承运人",
  "is_package_damaged": "货物受损",
  "select_ble_device": "请选取蓝芽装置",
  "disconnect": "与现有装置断开连结",
  "parse_message_failed": "重量数据接收失败",
  "sync_message_failed": "与装置连线失败",
  "additional_milestone": "--额外新增--",
  "latest_printed_label_qty": "最后一次打列张数",
  "hawb_prefix": "HAWB前缀字元",
  "label_shipment_number_postfix": "后缀",
  "reweigh_is_number": "必须要在这裡输入数字",
  "import_receive_multibox_step1": "第一步",
  "import_receive_multibox_step1_text": "请将货物放置整齐，然后开始盘点箱盒的件数",
  "import_receive_multibox_step2": "第二步",
  "import_receive_multibox_step2_text": "请输入这些箱盒的实际件数",
  "import_receive_multibox_step3": "第三步",
  "import_receive_multibox_step3_text": "这些箱盒被放置在多少栈板上？",
  "import_receive_multibox_confirm_text": "注意：从现在开始，这票货会以下列型态处理",
  "import_receive_multibox_confirm_warning": "在这之后你就不能够改变货物的包装型态了",
  "import_receive_multibox_confirm_print": "现在要打印新标签吗？",
  "import_receive_multibox_check_text": "根据系统中登记的货物件数与包装单位，这票货不应该改变其包装型态",
  "import_receive_multibox_check_warning": "你仍然要继续下去？",
  "import_receive_multibox_leave_confirm": "你确定要离开目前页面的操作？",
  "import_receive_multibox_leave_info": "继续扫码确认",
  "photo_of_the_scale": "磅秤读数相片",
  "cargo_outlook": "货物外观",
  "error_received_when_have_inventory_manifest": "请直接进行货物入库扫码",
  "is_damaged": "Is Damaged",
  "missing_dispatch_info": "派车单信息不存在",
  "you_should_scan_delivery_packages_first": "请先扫描标签上的二维码",
  "error_package_type_transformation": "栈板类型货物不能够转变包装类型",
  "combo_mode_name": "Combo Mode",
  "ship_to_name": "收货者名称",
  "ship_to_city": "目的地城市",
  "error_has_been_already_ofd_by_tms": "%{number} - 扫码完成 (已经在TMS做完OFD)",
  "please_select_at_least_one_photo": "你至少要选择一张照片才行",
  "handover": "Handover",
  "mawb_arrived": "%{mawb} 抵达",
  "mawb_incorrect": "主单号错误",
  "sheet_number_incorrect": "系统中不存在此单号",
  "check_mawb": "检查MAWB",
  "new_package_id": "新的Package ID",
  "old_package_id": "原有Package ID",
  "the_pallet_is_not_for_this_truck": "这个栈板不在派送清单内",
  "location": "储位",
  "urgent_label": "紧急输入的标签？",
  "picking": "Picking",
  "label_print": "标签是否打印？",
  "label_type": "标签类型",
  "ofd": "OFD",
  "error_received_must_split_combine": "务必进行 Pallet Split and Combine 流程",
  "select_source_of_photo": "Choose how to get the photos",
  "photo_source_camera": "前往拍照",
  "photo_source_device": "从装置选取",
  "station": "所在站点",
  "inventory_management_notice": "这裡我们只看还未被派送出去的货物",
  "photo_selection_info": "相片: %{number} / %{total}",
  "photo_move": "移动相片",
  "photo_move_title": "移动相片到:",
  "photo_move_target_information_level": "资料层级",
  "photo_move_target_milestone": "Milestone",
  "photo_move_target_target_number": "Target Number",
  "no_printer_set_up_in_this_site": "系统中尚未建立此站点的打印机信息",
  "must_split_combine_modal_message": "注意：在做Put Away之前，要先Pallet Split & Combine功能使用生成新标签",
  "timestamp": "显示详细时间",
  "handling_record": "货物流转纪录",
  "delivery_id": "Delivery ID",
  "partial_receive_confirmation": "Are you sure to partially receive current freights?",
  "error_sync_from_prolink_failed": "数据同步失败",
  "sync_from_prolink": "正在进行数据同步",
  "error_need_reprint_label": "由于客户代码设定错误，你须要重新打印标签",
  "error_vip_name_need_checked_and_revised": "客户代码被改成错误的类型，请通知出口站修改booking信息",
  "split_and_combine": "Split & Combine",
  "single_package": "单一货件",
  "scan_qrcode_or_enter_hawb_below": "扫描QR Code或是在下方输入HAWB号码",
  "break_pallet": "要拆分栈板",
  "record": "时间纪录",
  "unfold": "展开",
  "pod": "POD",
  "truck_arrives": "卡车到场",
  "ata": "ATA",
  "footprints": "时间轨迹",
  "double_click_to_select_the_package_id": "连续点击两次以选择Package ID",
  "fold": "收束",
  "delivery_number_more": "...其他",
  "cannot_create_different_delivery_method": "不同派送方式的货物，不能同时选择！",
  "cannot_combine_different_delivery_method": "你选择的项目裡面有包含不同派送方式的货物",
  "sheet_type": "表单类型",
  "service_type": "服务类型",
  "transfer_manifest": "调转清单",
  "customer_pickup": "客户自提单",
  "inventory_adjustment_create_error": "不同派送方式的货物，不能同时选择！",
  "inventory_adjustment_combine_error": "你选择的项目裡面有包含不同派送方式的货物",
  "error_cannot_delete_no945_shipment": "用\"未收到EDI\"情境生成的数据不能被删除",
  "pickup_some_not_picked_up": "Please keep scanning the rest of packages",
  "pickup_all_picked_up": "Remember to sign up Customer Pickup Form and take pictures on it",
  "warning_cargo_need_to_break_down": "此栈板须要拆分并根据DN重新包装",
  "error_shipment_not_exists": "",
  "warning_delivery_package_already_rebuild": "有几笔HAWB的数据以前曾被重建过<br/>确定要继续？<br/><br/>%{shipments}",
  "pallets_to_break_down": "待拆解的栈板",
  "choose_the_method_to_reallocate_the_dn": "请选择重新分配DN的方式",
  "manual_way": "手动分配",
  "automatic_way": "自动分配",
  "system_suggestion": "系统提供的建议",
  "update_pcs": "更新后的件数",
  "save_and_reprint_labels": "储存并重印标签",
  "split_qty": "拆分出的箱盒数",
  "please_select_one_package_to_combine": "请选择一个物件作为合併标的",
  "need_to_reprint_labels": "你须要重印标签！",
  "you_have_not_reprinted_the_labels": "你还没重印标签！",
  "do_you_want_to_reprint_labels_now": "你现在要重印标签吗？",
  "just_leave_anyway": "不用，先离开此功能",
  "let_me_do_it_now": "是的，现在进行",
  "last_received_time": "最后收货时间",
  "inbound_checking": "Inbound Checking",
  "error_pick_up_cancelled_dispatch": "这份派车单已经被取消",
  "error_pick_up_dispatch_with_all_splittable_packages": "请先操作Pallet Split & Combine",
  "error_pick_up_dispatch_with_all_urgent_created_packages": "这些都是紧急生成的标签，请先完成Receiving与Sorting",
  "info_not_show_goods_on_table": "*This table does not show %{brand} freights.",
  "input_location_code": "Input the location code",
  "data_rebuild": "数据重建",
  "data_rebuild_input_hawb": "输入分单号",
  "data_rebuild_button_hint": "点此按钮，开始重建数据",
  "data_rebuild_rebuild_button": "重建物件数据",
  "data_rebuild_result_success": "重建成功<br/><br/>%{shipments}",
  "data_rebuild_result_some_failed": "重建成功<br/>%{successes}<br/><br/>但有几笔重建失败<br/>%{failures}",
  "data_rebuild_result_error": "重建失败<br/><br/>%{shipments}",
  "urgent_multiple_input_instruction_1": "只有MAWB Pcs、备注不是必填栏位。",
  "urgent_multiple_input_instruction_2": "标签尺寸只能输入4x6、4x5或4x3。",
  "urgent_multiple_input_instruction_3": "英文字母输入后会自动转变为大写。",
  "urgent_multiple_input_instruction_4": "系统会检查你输入的资料是否正确。如果任何一栏存在错误，系统就不会储存输入的资料。",
  "label_configuration": "标签佈局",
  "click_to_partially_print_the_label": "点击此处以打印部分标签",
  "missing_record_alert": "注意：你所要搜寻的标签信息并非全部都能找到",
  "check_details": "查看细节",
  "data_availability": "标签信息完善状况一览",
  "available_or_not": "标签信息是否完善？",
  "get_label_information": "获取标签信息",
  "get_label_information_notice": "*如果客户名称是APPLE_GAR，系统会生成紧急输入类型的标签供使用",
  "advanced_search": "进阶搜寻",
  "fuzzy_search": "模煳搜寻",
  "edi945_in": "收到EDI945",
  "latest_print_time": "最后一次打印时间",
  "first_print_time": "第一次打印时间",
  "edi_receive_time": "EDI收到时间",
  "revise_airport_code": "修改机场代码",
  "notes_of_revising_package_details": "*注意：通常HAWB件数应该是栈板数量与散箱数量的加总。若有必要则在此修改HAWB件数",
  "revise_the_package_information_on_the_label": "修改标签上显示的包装信息",
  "reset_with_edi945": "用EDI945信息重置",
  "cannot_input_mawb_and_hawb_at_the_same_time": "你不能同时输入MAWB与HAWB进行搜寻",
  "notice_of_revising_package_of_apple_gar_or_garmin": "Apple GAR或Garmin的货物若要调整件数，须在紧急输入功能下修改，否则请客户重传EDI。",
  "sort_date": "分类日期",
  "update_date": "更新日期",
  "must_larger_than_hawb_pcs": "主单件数不能小于分单件数",
  "package_count": "物件数量",
  "atd": "ATD",
  "need_breakdown": "须要拆分栈板",
  "missing_milestone": "缺少Milestone信息",
  "no_truck_arrival_time": "没有卡车抵达时间",
  "not_broken_down": "没有栈板拆分纪录",
  "not_picked": "没有货物被提取的纪录",
  "carton_sorting_incorrect_field": "错误的%{field}",
  "check_consignee_for_packslip": "请确认下列Packslip No.的收件人名称",
  "scan_record": "扫描纪录：",
  "type_in": "输入",
  "type_in_the_packslip_no": "请输入Packslip No.",
  "manual_input": "手动输入",
  "time": "时间",
  "adjust_quantity": "数量调整",
  "total_quantity": "总数量",
  "carton_sorting_error_msg_incorrect_shipper": "发货方名称错误",
  "carton_sorting_error_msg_incorrect_consignee": "收货方名称错误",
  "identify": "辨识",
  "re_select_photo": "请重新选取相片范围",
  "airline_code": "Airline Code",
  "airline_code_max_length": "Code max length is %{length}",
  "airline_modal_create_title": "Create Airline",
  "airline_modal_edit_title": "Edit Airline",
  "error_code_others": "未知因素所导致，请洽IT工程师",
  "cant_sync_received_shipment": "进口站收到货物后，就不能在此刷新信息",
  "direct_upload": "直接上传",
  "label_printing": "标籤列印",
  "specific_list": "特殊清单",
  "default_setting_for_the_station": "站点的初始设定",
  "add_site": "新增地点",
  "1_printers": "1. 打印机",
  "2_labels": "2. 标签",
  "delete_station": "删除站点",
  "template_name": "模板名称",
  "list_name": "清单名称",
  "location_code": "储位代码",
  "tpe_cs_list": "TPE客服人员清单",
  "employee_id": "员工编号",
  "chinese_name": "中文姓名",
  "phone_number": "电话号码",
  "close": "关闭",
  "only_handle_ptt": "这裡我们只处理PTT档案",
  "directly_put_away_with_ia": "请直接将这些货物上架存放",
  "notify_to_do_inbound_check": "请使用Inbound Checking功能",
  "can_not_less_than_scanned_qty": "不可小于Scanned qty",
  "abnormal_receive": "在此记录异常情况",
  "1_type_in_hawb": "1. 输入HAWB",
  "2_select_issue_on_label": "2. 选择标签发生的问题类型",
  "3_reprint_labels": "3. 重印标签",
  "4_record_situation": "4. 记录实际情况",
  "import_receive_issue_bad_printing_quality": "印列品质不佳",
  "import_receive_issue_damaged_label": "标签破损",
  "import_receive_issue_different_package_ids_on_pallet": "不同的Package ID被贴在同一件货上",
  "import_receive_issue_others": "其他",
  "volume_download": "批量下载",
  "notify_the_result_of_uploading": "提示上传结果",
  "you_have_to_select_photos_first": "你须要先选择照片",
  "undefined": ""
 },
 "billing": {
  "charge_amount": "金额",
  "allocation_edit_header_new": "创建PO Request",
  "allocation_edit_header_edit": "编辑PO Request:",
  "allocation_edit_header_view": "检视PO Request:",
  "allocation_sum": "加总检查",
  "credit_to_id": "厂商",
  "issued_by_station": "站点",
  "payment_method": "付款方式",
  "origin_currency": "币别",
  "origin_amount": "金额",
  "station": "站点",
  "department": "部门",
  "charge_code": "Charge Code",
  "quantity": "数量",
  "origin_charge_rate": "费率",
  "unit": "单位",
  "calculate_by": "计算方式",
  "allocation_method": "分配方式",
  "confirm": "确认",
  "allocation_history_header": "历史纪录",
  "po_request_id": "采购订单编号",
  "created_date": "Created Date",
  "prolink_id": "Prolink ID",
  "view": "检视采购订单:",
  "edit": "编辑",
  "sum_error": "总和不符!",
  "add_new_allocation": "新增",
  "calculate": "计算",
  "allocation_new_dispatch_must_has_allocation": "派车单必须要有相应的采购订单",
  "allocation_sum_check_not_match": "金额加总不符",
  "ttl_amount": "TTL Amount",
  "ref_sharp": "REF#",
  "dept": "部门",
  "reference_number": "Reference No",
  "vip_name": "客户",
  "bill_to_id": "Debit To/ Vendor",
  "category": "类别",
  "billing_type": "Billing Type",
  "invoice_type": "发票类型",
  "invoice_voucher_number": "Invoice/ Voucher No",
  "shipment_number": "Shipment No",
  "print_at_from": "Printed Date From",
  "print_at_to": "Printed Date To",
  "void": "Void",
  "client_id": "Client ID",
  "sales_number": "Sales Code",
  "ap_status": "AP状态",
  "customer": "客户",
  "printed": "已打印",
  "request": "Request",
  "delete": "删除",
  "amount": "金额",
  "currency": "币别",
  "bcr_category_id": "BCR类别",
  "bcr_reason_code": "BCR原因",
  "voucher_history_header": "PO 信息 (来自 Prolink/ CW1)",
  "void_confirm_signature": "已跟Accounting团队确认可以Void此笔 Invoice/ Voucher",
  "invouce_voucher_number": "Invouce/ Voucher No",
  "reference_no": "Reference No",
  "billing_correction_request_bcr": "帐单更正请求 - BCR",
  "trade_type": "Transaction",
  "transaction": "Transaction",
  "tips": "提示",
  "mass_upload_tips_detail": "若选择\"General\"，1笔上传数据会建立1笔PO或是Invoice。 \n 若选择\"3rd Party Station\", 1笔上传数据会建立1笔PO和两笔Invoices。",
  "mass_upload_tips_detail_step2": "所列出的结果会被传送到Prolink/ CW1并执行打印",
  "created_by": "创建者",
  "charge_category": "费用类别",
  "project_id": "Project ID",
  "fd_no": "FD No",
  "pr_no": "PR No",
  "request_type": "Request种类",
  "request_status": "Request状态",
  "is_late": "Late Billing",
  "mawb_mbl": "MAWB/ MBL",
  "create_date_utc": "Created Date (UTC)",
  "print_at": "打印日期",
  "voided_date": "Void日期",
  "voucher_type_invoice": "Invoice",
  "voucher_type_voucher": "Voucher",
  "trade_type_trade": "Trade",
  "trade_type_non_trade": "Non Trade",
  "ap_status_c": "C",
  "request_type_displayed_create": "Create",
  "request_type_displayed_void": "Void",
  "request_status_in_progress": "In Progress",
  "request_status_failure": "Failure",
  "billing_tab_posted_tab": "Printed/ Voided",
  "billing_tab_requested_tab": "Request",
  "bill_credit_to_id": "Debit To/ Vendor",
  "bill_credit_to": "Debit To/ Vendor",
  "charge_line_origin_currency": "Rate币别",
  "charge_line_origin_amount_in_usd": "金额(USD)",
  "allocated_origin_allocation_amount": "Allocated金额",
  "allocated_origin_allocation_amount_in_usd": "Allocated金额(USD)",
  "request_serial_number": "Request ID",
  "charge_line_description": "费用描述",
  "invoice_amount": "发票金额",
  "invoice_amount_in_usd": "发票金额(USD)",
  "invoice_currency": "发票币别",
  "nvat_amount": "税前金额",
  "vat_amount": "VAT金额",
  "ap_amount": "已处理AP金额",
  "cant_void_void_request_is_processing": "不能作废，作废请求正在处理中",
  "amount_usd": "金额(USD)",
  "allocated_amount_usd": "Allocated金额(USD)",
  "failure_reason": "失败原因",
  "type": "种类",
  "billing_type_main": "MAIN",
  "billing_type_credit_note": "CREDIT",
  "billing_type_debit_note": "DEBIT",
  "ap_status_complete": "完成",
  "ap_status_delete": "删除",
  "mass_upload": "批次上传",
  "billing_mass_upload_type_general": "General",
  "billing_mass_upload_type_third_station": "3rd Party Station",
  "mass_upload_step1_title_general_upload": "步骤1: 一般上传",
  "mass_upload_step2_title_check_result": "步骤2: 检查结果",
  "mass_upload_step2_no_data_info": "请先确认数据正确性，并且点选 \"检查并传送到下表\" ",
  "mass_upload_column_key_check_result": "检查结果",
  "mass_upload_column_key_upload_type": "上传类别",
  "mass_upload_column_key_station": "站点",
  "mass_upload_column_key_dept": "部门",
  "mass_upload_column_key_customer": "客户",
  "mass_upload_column_key_debit_to_vendor": "Debit to/ Vendor",
  "mass_upload_column_key_payment_term": "Payment Term",
  "mass_upload_column_key_sales_code": "Sales Code",
  "mass_upload_column_key_reference_no": "Reference No",
  "mass_upload_column_key_charge_code": "Charge Code",
  "mass_upload_column_key_qty": "数量",
  "mass_upload_column_key_unit": "单位",
  "mass_upload_column_key_rate": "Rate",
  "mass_upload_column_key_rate_currency": "Rate币别",
  "mass_upload_column_key_rate_amount": "Rate金额",
  "mass_upload_column_key_charge_description": "费用描述",
  "mass_upload_column_key_3rd_station": "3rd 站点",
  "mass_upload_column_key_3rd_dept": "3rd 部门",
  "mass_upload_column_key_3rd_sales_code": "3rd Sales Code",
  "mass_upload_column_error_key_value_can_not_be_blank": "不能为空白",
  "mass_upload_column_error_key_invalid_upload_type_value": "无效的上传类别",
  "mass_upload_column_error_key_invalid_station_value": "无效的站点",
  "mass_upload_column_error_key_invalid_dept_value": "无效的部门",
  "mass_upload_column_error_key_invalid_customer_value": "无效的客戶",
  "mass_upload_column_error_key_invalid_debit_to_vendor_value": "无效的Debit to/ Vendor",
  "mass_upload_column_error_key_invalid_payment_term_value": "无效的Payment Term",
  "mass_upload_column_error_key_invalid_sales_code_value": "无效的Sales Code",
  "mass_upload_column_error_key_invalid_reference_no_value": "无效的Reference No",
  "mass_upload_column_error_key_invalid_charge_code_value": "无效的Charge Code",
  "mass_upload_column_error_key_invalid_unit_value": "无效的数量",
  "mass_upload_column_error_key_invalid_rate_currency_value": "无效的Rate币别",
  "mass_upload_column_error_key_invalid_rate_amount_value": "无效的Rate金额",
  "mass_upload_column_error_key_invalid_3rd_sales_code_value": "无效的3rd Party Sales Code",
  "download_template": "下载模板",
  "upload_file": "上传档案",
  "or_paste_data": "或于表格中贴上数据",
  "warning_data_over_write_after_upload": "(如果选择新档案，所有已输入的数据将会被覆盖)",
  "file": "档案",
  "total_records": "所有纪录",
  "total_amount": "总金额",
  "error": "错误",
  "checked": "已检查",
  "check_and_transfer": "检查并传送到下表",
  "mass_upload_post_all_to_prolink": "POST到Prolink",
  "mass_upload_post_all_to_prolink_success": "已成功执行，需要跳转至列表页面吗?",
  "check_first_memo": "请先执行\"检查\"",
  "warning_change_type_clear_data": "改变\"种类\"会清除所有数据，确定吗?",
  "warning_would_you_like_to_send_to_prolink": "确定要传送到Prolink吗?",
  "check_confirm_to_send_to_prolink": "确认",
  "voucher_no": "凭单号码",
  "invoice_voucher_date": "Invoice/ Voucher Date",
  "acting_station": "操作打印机站点",
  "warning_code_billing_mass_upload_duplicate_row_in_input_data": "与第 %{row_id} 行的 Debit to/ Vendor, Reference No.相同，请确认是否重複上传",
  "warning_code_billing_mass_upload_duplicate_row_in_db": "系统中有相同的 Debit to/ Vendor, Reference No. 帐单 (提单号:%{reference_no}) ，请确认是否重複上传",
  "value_can_not_be_blank": "不能为空白",
  "invalid_upload_type_value": "无效的上传类别",
  "invalid_station_value": "无效的站点",
  "invalid_dept_value": "无效的部门",
  "invalid_customer_value": "无效的客戶",
  "invalid_debit_to_vendor_value": "无效的Debit to/ Vendor",
  "invalid_payment_term_value": "无效的Payment Term",
  "invalid_sales_code_value": "无效的Sales Code",
  "invalid_reference_no_value": "无效的Reference No",
  "invalid_charge_code_value": "无效的Charge Code",
  "invalid_unit_value": "无效的数量",
  "invalid_rate_currency_value": "无效的Rate币别",
  "invalid_rate_amount_value": "无效的Rate金额",
  "invalid_3rd_sales_code_value": "无效的3rd Party Sales Code",
  "cant_void_voided_voucher": "不能作废已作废的凭证",
  "cant_void_voided_invoice": "不能作废已作废的发票",
  "cant_void_ap_completed_voucher": "不能作废 AP 完成的凭证",
  "cant_void_ap_completed_invoice": "不能作废 AP 完成的发票",
  "cant_void_voiding_voucher": "无法作废作废中的凭证",
  "cant_void_voiding_invoice": "不能作废作废中的发票",
  "ap_inv_no": "AP Inv No",
  "cw1_chg_code": "Charge Code",
  "voucher_source": "Source",
  "source_tx_pk": "PK",
  "source_tx_creator": "Creator",
  "package": "PKG",
  "rate_per_package": "Rate per Package",
  "rate_per_kg": "Rate per KG",
  "missing_required": "Missing Required Field",
  "status": "状态",
  "action": "操作",
  "allocated_amount": "分摊金额",
  "undefined": ""
 },
 "tms": {
  "alert_at_least_bol": "至少需加一张送货单(BOL)到派车单(Dispatch)。",
  "alert_at_least_one_bol": "至少选择一笔送货单(BOL)。",
  "alert_at_least_one_shipment": "至少需加一笔货物到送货单(BOL)。",
  "alert_at_least_one_shipment_per_bol": "至少需加一笔货物到送货单(BOL)。",
  "alert_bol_and_dispatch_is_different_carrier_code": "送货单（BOL/DN）的承运人和派车单（Dispatch）的承运人不同，请重新确认。",
  "alert_cancel_edit_dispatch": "放弃所做的修改吗 ?",
  "alert_confirm_cancel": "确认要取消吗?",
  "alert_confirm_delete_dispatch": "确认要删除派车单(Dispatch)吗?",
  "alert_confirm_edit_dispatch": "确认要编辑派车单(Dispatch)吗?",
  "alert_confirm_save_dispatch": "您确认要保存派车单(Dispatch)吗？",
  "alert_different_carrier_code": "所选货物的预指派承运人不同。",
  "alert_different_consignee_address": "所选货物的收货人地址不同。",
  "alert_different_consignee_id": "所选货物的收货人代码不同。",
  "alert_different_delivery_method": "所选货件的递送方式不同。",
  "alert_different_payer": "所选货物的递送费用支付方不同。",
  "alert_different_payer_account": "所选货物的递送费用支付帐号不同。",
  "alert_different_payer_name": "所选货物的递送费用支付方不同。",
  "alert_different_quotation_level": "所选货件的递送费用报价层级不同。",
  "alert_have_different_bol_carrier_confirm_go_on": "送货单(BOL)的承运人不同，是否继续？",
  "alert_no_dispatch": "没有派车单(Dispatch)!",
  "alert_no_dispath_bols": "没有送货单(BOL)！",
  "alert_required_quotation_fields": "资料未填写完整，请检查确认。",
  "accept_quotation_tooltip": "接受报价，报价状态将更新为\"已接受\"。",
  "actual_carrier": "实际承运人",
  "actual_driver_arrival_time": "实际司机到达时间",
  "actual_out_for_delivery_date": "外出送貨日期",
  "actual_out_for_delivery_time": "外出送貨时间",
  "add_quotation": "新增报价",
  "add_quotation_tooltip": "新增一笔报价",
  "advanced_search": "进阶搜寻",
  "agent_role": "H Agent",
  "alert_accept_quotation_fail_do_retry_or_manual_or_return": "系统连线问题无法线上完成接受报价，请选择\"重试\"、\"手动预订\"、或者\"返回\"。",
  "alert_accept_quotation_manual": "Morrison系统已完成报价，接下来请记得线外跟承运人下Booking。",
  "alert_are_you_sure_to_cancel_quotation": "您是否要取消报价?",
  "alert_are_you_sure_to_submit_quotation": "您是否要送出报价请求?",
  "alert_assigned_to_driver": "派车状态已修改为 “15 - 已指派司机”。",
  "alert_cancel_quotaion_confirm": "您是否要取消报价?",
  "alert_cancel_quotation_fail_do_retry_or_manual_or_return": "系统连线问题无法线上完成取消报价，请选择\"重试\"、\"手动取消报价\"或者\"返回\"?",
  "alert_cancel_quotation_manual": "Morrison系统已完成报价，接下来请记得线外跟承运人取消Booking。",
  "alert_confirm_leave": "未保存的资料将会遗失，请确认。",
  "alert_confirm_not_mobile_save": "输入的司机号码不是手机号码，确认要保存吗？",
  "alert_confirm_send_sms": "确认发送简讯到手机 %{number} ?",
  "alert_create_bol_fail": "新增 BOL/ DN 失败，请重试一次。",
  "alert_custom_value_have_multiple_currency_and_go_on": "貨件的价值币别不同，无法加总Custom Value。",
  "alert_document_ready_is_not_ready": "\"出货文件\"尚未就绪，请确认出货文件。",
  "alert_fast_create_remove_bol_in_bol_step_warning": "确认要移除送货单(BOL/ DN)吗？该操作不可撤销。",
  "alert_have_different_bol_carrier": "您选择的货件，送货单(BOL/ DN)的\"承运人\"不同，无法加入到同一个派车单(Dispatch)。",
  "alert_have_different_bid_required": "您选择的货件，送货单(BOL/ DN)的\"比价标记\"不同，无法加入到同一个派车单(Dispatch)。",
  "alert_have_different_bol_payment_to_carrier_by_and_go_on": "您选择的货件，\"递送费用预设方\"不同，确认要加入同一个派车单(Dispatch)吗?",
  "alert_have_different_bol_station": "您选择的货件，\"送货单(BOL/ DN)站点\"不同，无法加入到同一个派车单(Dispatch)。",
  "alert_have_different_delivery_method": "您选择的货件，送货单(BOL/ DN)的\"递送方式\"不同，无法加入到同一个派车单(Dispatch)。",
  "alert_have_different_ship_mode": "您选择的货件，\"运输模式\"不同。无法加入到同一个派车单(Dispatch)。",
  "alert_no_quotation": "送货单(BOL/ DN)报价尚未就绪，若要继续请先完成报价。",
  "alert_out_for_delivery": "派车单(Dispatch) %{dispatchNumber} 状态为“20 - 外出递送”。",
  "alert_planned_out_for_delivery_at": "计划外出递送时间",
  "alert_print_label_confirm": "是否打印标签?",
  "alert_required_all_fields": "栏位资料有误，请检查后再送出。",
  "alert_save_success": "更新成功",
  "alert_selected_shipments_need_add_to_bol": "尚有货件未被加入到任何送货单(BOL/ DN)中，请先完成加入。",
  "alert_send_sms": "短信发送成功!",
  "alert_submit_quotation_fail_do_retry_or_manual_or_return": "是否继续重试，返回，手动预订？",
  "alert_submit_quotation_manual": "请记得跟承运人完成预订。",
  "alert_are_you_sure_to_accept_quotation": "您是否要接受报价？",
  "already_has_quotation_cannot_edit_address": "货件已送达或者送货单(BOL/ DN)已有报价，无法编辑地址。",
  "already_has_quotation_cannot_edit_shipment": "货件已送达或者送货单(BOL/ DN)已有报价，无法新增或移除货物。",
  "actual_appointment_date": "Actual Appt Date",
  "appointment_confirmed_date": "Appt Confirm Date",
  "appointment_made_date": "Appt Made Date",
  "are_you_sure_to_change_bid_required": "所有送货单(BOL/ DN)的比价标记都将被修改，如已接受承运人报价，将一并取消，送货单(BOL/ DN)和派车单(Dispatch)需要重新确认，确认要修改吗?",
  "are_you_sure_to_change_delivery_method": "所有送货单(BOL/ DN)的递送方式都将被修改，如已接受承运人报价，将一并取消，送货单(BOL/ DN)和派车单(Dispatch)需要重新确认，确认要修改吗？",
  "are_you_sure_to_change_carrier_code": "所有送货单(BOL/ DN)的承运人都将被修改，如已接受承运人报价，将一并取消，送货单(BOL/ DN)和派车单(Dispatch)需要重新确认，确认要修改吗？",
  "assistant_driver_1_name": "随车人员一",
  "assistant_driver_2_name": "随车人员二",
  "bid_required": "比价",
  "bid_required_tooltip": "出运前进行比价",
  "bol": "送货单(BOL)详情",
  "bol_booking_status_is_completed_please_cancel_booking": "此送货单(BOL)已完成报价，请在删除送貨單之前先取消跟承运人的报价。",
  "bol_carrier": "承运人",
  "bol_delivery_method": "递送方式",
  "bol_details": "送货单(BOL)详情",
  "bol_dn_carrier": "承运人",
  "bol_dn_delivery_date": "到货日期",
  "bol_dn_delivery_from": "预期递送时间",
  "bol_dn_form": "送货单 (BOL)",
  "bol_dn_no": "送货单(BOL)号码",
  "bol_dn_pod_from": "POD时间(起)",
  "bol_dn_shipment_mode": "运输方式",
  "bol_dn_shipto_state": "州/省",
  "bol_dn_station": "站点",
  "bol_dn_type": "送货单(BOL)类型",
  "bol_edit": "送货单(BOL)编辑",
  "bol_grouping_rule": "货物合并到送货单(BOL)规则",
  "bol_indicator": "送货单(BOL)指标",
  "bol_mgmt": "送货单(BOL)管理",
  "bol_new": "新建送货单(BOL)",
  "bol_no": "送货单(BOL)号码",
  "bol_status": "送货单(BOL)状态",
  "bol_status_update": "送货单(BOL)状态更新",
  "booking_date": "预订",
  "booking_status": "报价状态",
  "cancel_dispatch": "将货件从派车单移除",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "送货单(BOL) %{bol_number} 若已接受报价，删除送货单(BOL/ DN)将同时取消报价，确认要删除送货单(BOL)吗？",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "派车单(Dispatch)%{dispatchnumber} 若已接受报价，删除派车单(Dispatch)将同时取消报价，确认要删除派车单(Dispatch)吗？",
  "cancel_quotation_tooltip": "取消已经接受的报价",
  "carrier": "承运人",
  "carrier_service_level": "承运人服務",
  "column_adjustment": "栏位调整",
  "comment_category": "注释类别",
  "complete_time": "完成时间",
  "confirm_complete": "确认已完成",
  "cannot_be_empty": "不可为空",
  "consol_closed_at": "Console Close",
  "creation_date": "创建日期",
  "delivery_date": "POD 时间",
  "delivery_method": "递送方式",
  "delivery_method_tooltip": "Express 以送货单报价，LTL/FTL 以派车单报价。",
  "delivery_no": "Delivery No",
  "destination_doc_status": "目的站文件状态",
  "dispatch_delivery_method": "派车单递送方式",
  "dispatch_header": "派车单(Dispatch)表头",
  "dispatch_no": "派车单(Dispatch)号码",
  "dispatch_station": "站点",
  "dispatch_type": "Dispatch Type",
  "document_ready_tooltip": "出运前请确认所需文件已经就绪",
  "error_you_have_different_dispatch_no": "所选的多个货件有超过一个以上的派车单(Dispatch)，请重新选择。",
  "exception": "例外",
  "exception_message": "例外讯息",
  "exception_number": "例外讯息笔数",
  "fcr": "FCR",
  "fcr_c": "子单",
  "fcr_n": "一般提单",
  "fcr_y": "母单",
  "forwarder_cargo_receipt": "货运承揽收据",
  "grouping_rule_a": "收货人地址相同的货件合并到一个送货单",
  "grouping_rule_b": "收货人代码和收货人地址相同的货件合并到一个送货单",
  "grouping_rule_c": "发货人代码和收货人代码相同的货件合并到一个送货单",
  "grouping_rule_d": "发货人、收货人和收货地址相同的货件合并到一个送货单",
  "grouping_rule_e": "一个货件一个送货单",
  "gw": "毛重",
  "hawb_no": "HAWB / HBL",
  "input_error_code": "请输入错误代码",
  "instruction": "特殊指示",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "logno": "LOG No",
  "manual": "手动",
  "master_agent_role": "Consol Agent",
  "misc_docs": "其他文件",
  "ofd_time": "外出递送时间",
  "off_load_at": "Offload",
  "open_delivery_status_update": "更新递送状态",
  "open_handover_edit": "交接货件",
  "out_for_delivery_date": "外出递送",
  "pallet_status": "进口栈板状态",
  "payment_method": "付款方式",
  "pickup_date_over_arrival_date": "计划司机抵达仓库时间必须早于送货单(BOL)的要求送達時間",
  "pkg_qty": "包裹数",
  "pkg_unit": "包装类型",
  "planned_driver_arrival_time": "计划司机到达仓库时间",
  "planned_driver_time_tooltip": "计划安排承运人到仓库的时间",
  "planned_out_for_delivery_time": "计划外出递送时间",
  "pod": "POD 文件",
  "pod_checked": "POD 已检核",
  "pod_date": "POD 日期",
  "pod_file": "POD 文件",
  "preassigned_carrier_updated_at": "指派承运人时间",
  "preassigned_carrier_updated_by": "指派人",
  "primary_driver_license_no": "驾驶证号",
  "primary_driver_license_number": "驾驶证号",
  "primary_driver_name": "司机",
  "primary_driver_phone_no": "司机手机",
  "primary_driver_phone_number": "司机手机",
  "print": "打印",
  "print_bol": "打印送货单(BOL)",
  "print_label_tooltip": "接受线上报价后可打印标签",
  "print_manifest": "打印派车单(Dispatch)",
  "quotation": "报价编号",
  "quotation_is_accepted": "报价已接受",
  "quotation_is_submitted": "报价已提交",
  "quotation_status": "报价状态",
  "reference_files": "参考文件",
  "retry": "重试",
  "rule_a": "Rule A",
  "rule_b": "Rule B",
  "rule_c": "Rule C",
  "rule_d": "Rule D",
  "rule_e": "Rule E",
  "sharp_bols": "送货单(BOL)数",
  "sharp_deliveries": "Delivery数",
  "sharp_gross_weight": "毛重",
  "sharp_gw": "毛重",
  "sharp_loading_meter": "#LDM",
  "sharp_packages": "包裹数",
  "sharp_pkgs": "包裹数",
  "sharp_shipments": "货件数",
  "sharp_shpts": "货件数",
  "sharp_chargeable_weight": "付费重量",
  "ship_from": "起运",
  "ship_to_city": "收货城市",
  "ship_to_country": "收货国家/地区",
  "ship_to_name": "收货方",
  "ship_to_state": "收货州/省",
  "shipment_customer": "客户",
  "shipment_mode": "运输方式",
  "shipment_no": "HAWB / HBL",
  "special_instruction_max_length_tooltip": "最多可输入150个字元",
  "station": "站点",
  "status": "状态",
  "submit_quotation_tooltip": "送出报价请求",
  "tag_api_manual": "线下",
  "tag_api_manual_tooltip": "线下报价",
  "tag_quotation_accepted": "就绪",
  "tag_quotation_accepted_tooltip": "报价已就绪",
  "tag_quotation_not_accepted": "未就绪",
  "tag_quotation_not_accepted_tooltip": "报价未就绪",
  "to_address": "收货地址",
  "to_city": "收貨城市",
  "to_country": "收货国家/地区",
  "to_name": "收货人",
  "to_state": "收货州/省",
  "tracking_device": "追踪器号码",
  "tracking_device_no": "追踪器号码",
  "tracking_device_number": "追踪器号码",
  "tracking_no": "追踪号码",
  "trailer_load_by": "托车装载",
  "trailer_no": "拖车号码",
  "truck": "卡车",
  "truck_no": "车牌",
  "truck_type": "车型",
  "type": "类型",
  "unlink_bol": "你确定要把货件从派車单移出吗？",
  "urgent_delivery": "急件",
  "urgent_delivery_tooltip": "急件送货",
  "vip_name": "客户",
  "verification_code": "验证码",
  "alert_check_customer_pickup_shipments": "This selection including %{shipmentCounts} shipments with “To port” service type. (ATA or DTA)\b\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD) before entering dispatch creation.",
  "alert_check_customer_pickup_before_check_in_counter": "This selection including %{shipmentCounts} shipment(s) with “To port”  service type ( ATA or DTA or PTP or DTP or ATP or PTA) .\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD or PTD) before Assign Carrier.",
  "alert_invalide_customer_shipments": "Apple GAR, Garmin, Gap cannot mix with other customer in a disaptch.",
  "warning_has_dispatch_shipments": "Warning:\n\nThis selection including %{shipmentCounts} shipments have already had OFD/POD.\nSystem will update OFD & POD timestamps and POD files on these shipments based on TMS and ePOD.\nIncluding HAWB: %{shipmentNumbers}.\n\nDo you want to proceed BOL & Dispatch creation?",
  "warning": "警告",
  "warning_unable_to_change_appt": "Unable to change below Delivery's Actual Appt Date and/or EDD. Please click to go to TRCK to change Actual Appt Date and/or EDD.",
  "service_type_change_to": "Service Type Change To",
  "delivery_method_change_to": "Delivery Method Change To",
  "change_service_type": "Change Service Type",
  "original_service_type": "Original\nService Type",
  "new_service_type": "New\nService Type",
  "original_delivery_method": "Original\nDelivery Method",
  "new_delivery_method": "New\nDelivery Method",
  "error_add_fast_create_dispatch": "你不能选择多个派车单",
  "allocation_sum_check_not_match": "请按计算按钮或手动把金额调整至与加总金额一致",
  "allocation_not_save": "分摊结果未保存",
  "allocation_has_posted_allocation": "There are POs created in Prolink/CW1, please make sure to void previous POs and add new request with new shipments.",
  "allocation_cant_remove_delivery_with_posted_allocation": "There are POs created in Prolink/CW1, please make sure to void previous POs.",
  "change_delivery_package_data": "修改递送包裹数据",
  "third_party": "第三方",
  "new_delivery_need_new_allocation": "Need Allocation when Add New Delivery",
  "confirm_is_delete_delivery_allocation": "All PO requests will be recalculated by GW. You can modify in dispatch detail if needed. ",
  "cancel_allocation_failed": "Cancel Allocation Failed",
  "reference_number": "Ref",
  "estimated_delivery_date": "EDD",
  "customs_cleared_at": "出口清关时间",
  "split_delivery_tooltip": "Split Delivery",
  "split_delivery_title": "Split: HAWB#:%{shipment_number} Delivery#:%{delivery_number}",
  "selected_packages": "Selected Packages",
  "estimated_gw": "Estimated GW",
  "estimated_cw": "Estimated CW",
  "selected_pallets_packages_will_be_split_to_a_new_delivery": "Selected pallets/packages will be split to a new Delivery#.",
  "remaining_packages": "Remaining Packages",
  "remaining_gw": "Remaining GW",
  "remaining_cw": "Remaining CW",
  "split_pkg_qty": "Split Package Qty",
  "split_pkg_unit": "Split Package Unit",
  "split_gw": "Split GW",
  "split_cw": "Split CW",
  "selected_package_unit_and_weight_will_be_split_to_a_new_delivery": "Selected Package, Unit and weight will be split to a new Delivery#.",
  "location": "Location",
  "pallet_id_package_id": "Pallet ID/Package ID",
  "hawb_allocated_amount": "HAWB Allocated Amount",
  "hawb_allocated_chargeable_weight_rate": "HAWB Allocated CW Rate",
  "upload_proof_of_handover": "上传交接文件",
  "deliveries_have_been_assigned_to_BOL": "%{assignedDeliveries}/%{totalDeliveries} Deliveries have been assigned to BOL",
  "deliveries_have_not_been_assigned": "%{notAssignedDeliveries} Deliveries have not been assigned",
  "different_consignee_address": "%{deliveryNumber} 的收货地址不同.",
  "alert_update_pod_time_and_pod_file": "Please update POD time and upload POD file for the new added BOL in Delivery Status Update .",
  "managed_in_cfs_tooltip": "Managed in CFS",
  "appointment_confirmed_number": "Appt Confirm No",
  "original_actual_appointment_date": "Original Actual Appt",
  "new_actual_appointment_date": "New Actual Appt",
  "original_estimated_delivery_date": "Original EDD",
  "new_estimated_delivery_date": "New EDD",
  "estimated_delivery_date_sla": "SLA",
  "print_pallet_label": "Print CNEE Pallet Label",
  "print_pallet_label_failed": "Print CNEE Pallet Label Failed",
  "department_of_transportation_number": "US DOT No",
  "motor_carrier_number": "US MC No",
  "go_to_trck": "Go to TRCK",
  "billing_country_code": "Country Code",
  "billing_postal_code": "Zip Code",
  "bol_and_manifest": "BOL and Manifest",
  "cnee_pallet_label": "CNEE Pallet Label",
  "should_print_pallet_label": "货件 %{shipmentList} 需要打印收货人托盘标签，请在货物交接给司机前打印收货人托盘标签。",
  "notify_id": "通知方代码",
  "notify_name": "通知方",
  "notify_address": "通知方地址",
  "notify_city_name": "通知方城市",
  "notify_state": "通知方州/省",
  "notify_country": "通知方国家/地区",
  "tracking_status_p44_inactive": "P44即将追踪",
  "tracking_status_p44_active": "P44追踪中",
  "tracking_status_p44_completed": "P44追踪结束",
  "tracking_status_p44_invalid_data": "P44追踪无效",
  "tracking_status_p44_request_processing": "P44处理中",
  "tooltip_tracking_status_p44_inactive": "追踪即将开始",
  "tooltip_tracking_status_p44_active": "追踪正在进行",
  "tooltip_tracking_status_p44_completed": "追踪已结束",
  "tooltip_tracking_status_p44_invalid_data": "追踪无效",
  "tooltip_tl_type": "当一批货物重量或容积装满一辆货车时，称整车货运(truckload, TL)。当一批货物的重量或容积不满一辆货车时，可与其他几批甚至上百批货物共享一辆货车装运时，称零担货运(less-than- truckload, LTL)",
  "tooltip_license_plate_no": "车牌号码，官方交通单位对道路车辆所发出的唯一编码，挂在车辆的前后",
  "tooltip_fleet_vehicle_id": "车队号码，由商用车队公司对旗下车辆配给唯一资产编号，一般显示在(拖车)车头的车门",
  "truck_info": "卡车信息",
  "license_plate_no": "车牌号码",
  "tl_type": "TL Type",
  "tracking_status": "Tracking Status",
  "dispatch_status": "Dispatch Status",
  "fleet_vehicle_no": "Fleet Vehicle No",
  "alert_fleet_vehicle_required": "This is a P44-enabled truck vendor, but there is no Fleet Vehicle No provided. Do you want to proceed?",
  "alert_address_not_valid": "Some shipping addresses may not be valid, do you want to continue?",
  "bol_status_passed": "Passed!",
  "bol_status_address_error": "Address validation fail - %{errorReason} Please check and modify on \"Address\", \"City\", \"State\", and \"Zipcode\" if needed.",
  "truck_tracking_processing_reason": "P44 Tracking establishment is still in progress, please refresh again later.",
  "truck_tracking_success": "P44 Tracking established successfully, P44 Tracking Status is Active",
  "modal_truck_tracking_error_reason": "P44 Tracking is still %{status} - %{reason}. Please check and revise data.",
  "truck_tracking_error_reason": "Tracking Error Reason",
  "truck_tracking_error_suggestion": "Contact station power user if you want to proceed printing PDF without P44 Tracking.",
  "truck_tracking_reminder_message": "Please make sure Tracking Device is activated and placed on a pallet.",
  "tracking_p44_dispatch_required_message": "Either SCAC, DOT No or MC No field is required.",
  "error_required_fleet_vehicle_number": "Please check with trucker and input Fleet Vehicle No for P44 Tracking",
  "tracking_p44_bol_delivery_date_required": "Checked Fail: Target Delivery Date is mandatory",
  "tracking_p44_bol_delivery_date_not_match_planned": "Checked Fail: Invalid Target Delivery Date, should be later than Planned Driver Arrival Time",
  "tracking_p44_bol_pdat_must_earlier_than_tdt": "Check Fail: Planned Driver Arrival Time must be earlier than Target Delivery Date.",
  "tracking_p44_bol_delivery_date_not_from_future": "Checked Fail: Invalid Target Delivery Date, should be a future time",
  "tracking_p44_bol_delivery_date_passed": "Checked Pass: Valid Target Delivery Date",
  "tracking_p44_bol_delivery_all_passed": "Pass checks.",
  "tracking_p44_bol_delivery_date_invalid_range": "Checked Fail: Invalid Target Delivery Date Range",
  "tracking_p44_bol_delivery_time_earlier_than_current": "Target Delivery Date is earlier than the current time, please double check and input.",
  "alert_bol_has_error": "BOL data have issues, please revise.",
  "alert_bol_has_warning": "BOL data have issues, do you want to continue?",
  "address_floor": "Floor/Suite/Unit",
  "p44_permission_confirm": "I confirm to skip P44 Tracking, and proceed to generate Dispatch PDF",
  "fleet_vehicle_number_placeholder": "Input Vehicle No for P44 Tracking...",
  "part_number_list": "Part Number",
  "product_description_list": "Product Description",
  "freight_charge_terms": "运费条款",
  "freight_charges_collect": "到付",
  "freight_charges_prepaid": "预付",
  "freight_charges_third_party": "第三方",
  "freight_counted_by": "货物计数",
  "freight_counted_driver_pallets": "Driver/pallets said to contain",
  "freight_counted_driver_pieces": "Driver/Pieces",
  "freight_counted_shipper": "托运人",
  "freight_term": "付款条款",
  "from": "起运",
  "ftl": "FTL",
  "preassigned_carrier": "指定承运人",
  "date_range_error": "日期区间有误",
  "must_be_before_the_end_time": "需早于结束时间",
  "must_be_after_the_start_time": "需晚于开始时间",
  "planned_driver_arrival_time_is_earlier_than_current": "早于当前时间，请仔细检查",
  "delivery_date_range_error": "指定到货日期应晚于司机预计到达时间",
  "fast_dispatch_bol_close_error_message": "栏位资料有误，修正后即可折叠。",
  "recalculate_po_request_with_allocation_method": "执行当前操作后，该派车单已经建立的PO Request(s) 将会以先前的分配方式重新计算 -",
  "recalculate_po_request_with_no_allocation_method": "执行当前操作后，该派车单已经建立的PO Request(s) 将会以毛重的分配方式重新计算。",
  "are_you_sure_to_change_vendor_code": "所选的厂商代码与此派车单的承运人不符，你确定要继续吗 ?",
  "please_double_confirm": "请在动作完成后再次检查PO Request(s)的金额分配。",
  "recalculate_po_request_with_allocation_method_period": "。",
  "new_delivery_has_zero_value": "分配金额 = 0，您确认要保存嗎 ?",
  "new_delivery_has_zero_value_tooltip_text": "请确认分配金额是否确实为 0",
  "po_request_status_pending": "待处理",
  "po_request_status_processing": "处理中",
  "po_request_status_error": "错误",
  "po_request_status_request_to_pl_po_ok": "已在 Prolink 建立 PO",
  "po_request_status_request_to_cw1_po_ok": "已在 CW1 建立 PO",
  "po_request_status_cw1_ap_completed": "PO 已在 CW1 转为 AP",
  "po_request_status_pending_explaination": "一旦派车单完成递送（30-已送达），PO Request 将发送到 Prolink/CW1。",
  "po_request_status_processing_explaination": "PO Request 已发送到 Prolink/CW1，应在 Dispatch 完成递送后 2小时内完成同步，否则请联络 mec_onehelp@morrisonexpress.com 处理",
  "po_request_status_error_explaination": "PO 发生错误，请联络 mec_onehelp@morrisonexpress.com 处理",
  "po_request_status_request_to_pl_po_ok_explaination": "已在 Prolink 建立 PO",
  "po_request_status_request_to_cw1_po_ok_explaination": "已在 CW1 建立 PO",
  "po_request_status_cw1_ap_completed_explaination": "PO 已在 CW1 转为 AP",
  "delivery_allocated_amount_tips": "TMS 卡车送货费用（Delivery 合计）请求，任何在 Prolink 或者 CW1 的修改不会反映在此。",
  "delivery_allocated_amount": "Delivery 分摊费用",
  "actual_pickup_at": "提货时间",
  "eta": "预计到达时间",
  "etd": "预计出发时间",
  "atd": "实际出发时间",
  "ata": "实际到达时间",
  "doc_released_at": "放单时间",
  "customs_released_at": "进口清关时间",
  "arrived_cfs_at": "抵達目的地 CFS 時間",
  "actual_delivery_date": "POD 时间",
  "country_code_exceed_limitation": "2码国家代码",
  "invalid_carrier_code": "由于该承运人代码无效，ePOD 登入短信将不会发送到司机的手机。请司机透过扫描派车文件上的 QRcode 来登入 ePOD",
  "carrier_sms_disabled": "由于该承运人的短信功能暂时关闭，ePOD 登入短信将不会发送到司机的手机。请司机透过扫描派车文件上的 QRcode 来登入 ePOD",
  "refresh_data": "刷新数据",
  "sync_from_prolink": "正在进行数据同步",
  "generate_dn": "Generate DN",
  "generate_dn_max_error": "Can not exceed %{max} item to generate.",
  "generate_dn_warning": "Selected HAWB is Taobao shipment. Do you want to create DN by Taobao consignee?",
  "generate_dn_leave_message": "尚有正在編輯的DN，確定要離開？",
  "generate_dn_upload_modal_title": "Upload Bag ID",
  "generate_dn_upload_modal_tips": "Please copy excel data and paste on sheet below.",
  "generate_dn_upload_modal_consignee": "Consignee",
  "generate_dn_upload_modal_bag_id": "Bag ID",
  "generate_dn_upload_success": "Upload successfully",
  "generate_dn_upload_fail": "上傳失敗",
  "import_pallet_status": "Import Pallet Status",
  "planned_driver_arrival_date": "Planned Driver Arrival Date",
  "delivery_not_found": "Delivery 资料缺失",
  "vip_name_is_not_top_partner_express": "勾选项目包含无效Customer: TOP_PARTNER_EXPRESS only",
  "delivery_status_is_not_unassigned": "勾选项目包含无效状态: 00-Initial only",
  "exceed_the_number_of_deliveries_limit": "无法勾选超过10个项目",
  "please_select_at_least_one_consignee": "Please select at least one consignee",
  "available_for_delivery": "Available For Delivery",
  "please_update_ofd_above_before_updating_pod_time": "在更新签收时间之前，请先在上方更新外出递送时间",
  "please_go_to_handover_to_input_the_drivers_infomation_before_updating_ofd": "在更新外出递送时间(OFD Time)之前，请先前往 Handover 输入司机资讯",
  "error_pod_datetime_not_future_time": "签收时间晚于当前时间，请仔细检查后再录入。",
  "error_pod_datetime_earlier_than_ofd_time": "早于外出递送时间，请仔细检查后再录入。",
  "error_ofd_datetime_not_future_time": "晚于当前时间，请仔细检查后再录入。",
  "handover_submit_error_ofd_earlier_than_adat": "外出递送时间早于司机实际到达时间，请仔细检查后再录入",
  "handover_submit_error_ofd_later_than_current": "晚于当前时间，请仔细检查后再录入",
  "handover_submit_error_ofd_later_than_pod": "签收时间早于外出递送时间，请仔细检查后再录入",
  "handover_submit_error_pod_later_than_current": "签收时间晚于当前时间，请仔细检查后再录入",
  "undefined": ""
 },
 "bk": {
  "query_failed": "请尝试重试或者刷新页面。若仍然无法查询，请联络 mec_onehelp@morrisonexpress.com。",
  "create_csv_failed": "汇出失败，请刷新页面并重试。若仍然无法汇出，请联络 mec_onehelp@morrisonexpress.com。",
  "data_not_found": "未预期的错误 (查无资料)。请联络 mec_onehelp@morrisonexpress.com。",
  "company_id_error": "使用者公司代码错误",
  "stage_error": "操作阶段错误",
  "cancel_reason_error": "删除原因错误",
  "query_parameter_error": "请求参数错误",
  "ods_api_failed": "call ods 出错",
  "field_verify_error": "发货人资讯尚未确认，请点选地址区段小铅笔图示，将发货人资讯中的\"将在稍后提供\"取消勾选。",
  "pre_assign_hawb_num_can_not_be_used": "您输入的预分配 HAWB/HBL 已被使用过，请选择一个新的号码",
  "booking_request_submit_error": "订舱委托单发送失败，请刷新页面并重试。若仍然无法发送，请联络 mec_onehelp@morrisonexpress.com。",
  "cs_not_found": "我们将尽快为您选择的路线提供在线预订服务。在此之前请选择其他起运港或直接联系您的销售",
  "hash_error": "hash code 不符合",
  "booking_request_can_not_find_customer_code": "此订舱委托单找不到对应的客户代码，请联络 mec_onehelp@morrisonexpress.com。",
  "status_is_completed": "此订舱委托单已完成，您无法进行此操作。",
  "status_is_canceled": "此订舱委托单已被取消，您无法进行此操作。",
  "task_api_failed": "未预期的错误 (呼叫 Task API)。请联络 mec_onehelp@morrisonexpress.com。",
  "common_api_failed": "未预期的错误 (呼叫 Common API )。请联络 mec_onehelp@morrisonexpress.com。",
  "not_allowed_to_modify_after_submission": "出口站与服务类型在送出订舱单后无法修改，请取消此订舱单后重新建立。",
  "sec_agent_not_existed": "Secure agent 不存在，请联络业务确认报价单内容是否正确",
  "secure_station_not_mapped": "Secure station 不存在，请联络业务确认报价单内容是否正确",
  "duplicate_reference_value": "Shipment 参考值重复，请检察您所输入的参考值",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未储存的资料将遗失，您确定要离开吗?",
  "draft_save_success": "订舱委托单草稿储存成功",
  "booking_request_submit_success": "订舱委托单已送出",
  "booking_request_confirm_success": "订舱单建立成功，您可在 20 分钟后至 Prolink/Cargo Wise 继续操作此笔订舱单",
  "booking_request_cancel_success": "订舱委托单取消成功",
  "address": "地址资讯",
  "approved": "已核可",
  "billing_info": "帐单资讯",
  "bill_of_lading_type": "House 提单种类",
  "booking": "订舱单",
  "booking_number_name": "订舱单号码",
  "booking_request_no": "订舱委託单号码",
  "booking_request_number": "订舱委託单号码",
  "booking_status_canceled": "已取消",
  "booking_status_completed": "已完成",
  "booking_status_draft": "草稿",
  "booking_status_in_progress": "订舱完成",
  "booking_status_submitted": "已送出",
  "booking_status_booking_in_progress": "订舱中",
  "brokerag_and_cargo": "附加服务",
  "calculated_by": "计算方式",
  "calculated_by_total": "按总装运量计算",
  "calculated_by_unit": "案个别货物总结",
  "cancel_at_utc": "取消订舱日期 (UTC)",
  "cancel_by": "取消订舱人",
  "cancel_check": "是否确定要取消 %{booking_id} ?",
  "cancel_notify_check": "Morrison 正在处理您的订单 %{booking_id}，若取消可能会有部分费用发生，我们将尽速确认费用后与您联繫，在此之前您的订单状态仍会维持在处理状态，是否确定取消?",
  "cancel_notify_cs": "我们已通知负责的人员，会在最短时间内联络您",
  "cargo_insurance": "货物运输保险",
  "cargo_ready_date": "货好时间",
  "city": "城市",
  "client_id": "客户代码",
  "client_name": "客户名称",
  "clone": "复制",
  "code": "代码",
  "commercial_invoice": "商业发票",
  "comments": "留言",
  "commodity": "货物类别",
  "commodity_code": "货物代码",
  "commodity_desc": "货物描述",
  "confirm_at_utc": "Morrison 确认日期 (UTC)",
  "confirm_by": "Morrison 确认者",
  "consignee": "收货方",
  "consignee_code": "收货方代码",
  "consignee_info": "收货方地址",
  "consignee_name": "收货方",
  "contact_name": "联络人",
  "container_type": "货柜类型",
  "country": "国家/地区",
  "create_booking": "建立订舱单",
  "created_at_utc": "创建日期 (UTC)",
  "created_by": "订舱委托单创建者",
  "custom_brokerage": "报关服务",
  "customer_code": "客户代码",
  "customer_code_name": "客户代码/名称",
  "customer_expected_pickup_at": "预计可提货时间",
  "customer_expected_at": "期望送达时间",
  "customer_name": "客户名称",
  "dangerous_goods_declaration": "物质安全资料表",
  "delivery_port": "交货港",
  "department": "部门",
  "dest": "进口站",
  "destination": "进口站",
  "destination_port_country": "进口站国家/地区",
  "destination_port_full": "进口站",
  "details": "详情",
  "diff_dest_area_notify": "你选择的收货人国家与目的港不同 ",
  "diff_origin_area_notify": "你选择的托运人国家与始发港不同 ",
  "direct_shipment": "直单",
  "direct_shipment_desc": "If you choose Direct Shipment, Morrison will provide the MAWB/MBL number via booking confirmation. Until then, you can use the booking request number to track the progress of the process.",
  "discharge": "卸货港",
  "documents": "文件",
  "effective_from": "有效日(起)",
  "effective_to": "有效日(迄)",
  "expected_eta": "期望送达时间",
  "expected_eta_note": "Morrison 会尽力达到您的期望，但不保证一定可满足",
  "expected_pickup_date": "预计可提货时间",
  "expected_pickup_date_note": "期望提货日期必须等于货晚于货好时间",
  "fcl": "整柜 (FCL)",
  "fcr_shipment": "FCR 货件",
  "filed": "已归档",
  "freight_payment_term": "运费付款条款",
  "freight_term": "付款条款",
  "general": "普通货品",
  "general_shipment_desc": "货物描述",
  "goods_description": "货物描述",
  "goods_info": "货物资讯",
  "goods_type": "货物种类",
  "gross_weight": "毛重",
  "gross_weight_total": "总毛重",
  "incoterm": "Incoterm",
  "insurance_value": "货物价值",
  "insurance_value_currency": "货物价值币别",
  "is_custom_service": "由 Morrison 报关",
  "dangerous_goods": "危险货品",
  "is_doc_ready": "文件就绪",
  "is_effective": "报价单是否有效",
  "is_insurance": "由 Morrison 保险",
  "is_origin_cert": "产地证明",
  "lcl": "併柜货(LCL)",
  "load": "装货港",
  "loads": "货型",
  "loose_goods": "散货",
  "method": "装运方式",
  "mode": "运输方式",
  "name": "名称",
  "need_carge": "是否需要货物运输保险?",
  "need_carge_no": "否 - 我已购买保险/我对任何丢失或损坏的货物承担全部责任",
  "need_carge_yes": "是 - 我希望 Morrison 协助购买货物运输保险，我的货物价值:",
  "not_sure_right_now": "将在稍后提供",
  "notify": "通知资讯",
  "notify_info": "通知方地址",
  "notify_name": "通知方",
  "origin": "出口站",
  "origin_cert_memo": "产地证明备注",
  "origin_port": "出口站",
  "origin_port_country": "出口站国家/地区",
  "other_reference_file": "其他参考文件",
  "others_payment_term": "其他费用付款条款",
  "others_term": "其他费用付款条款",
  "overwrite_confirm_title": "变更所选的发货方/收货方代码?",
  "overwrite_confirm_content": "更改发货方/收货方代码，报价单将重新查询，请确认报价单号码与内容。",
  "overwrite_contact_code_title": "变更所选报价单?",
  "overwrite_contact_code_content": "更改报价单后，将会以报价单中的发货方/收货方代码覆盖原本的发货方/收货方，请检查代码正确性或手动选择所需代码。",
  "overwrite_quotation_confirm_title": "变更所选的发货方/收货方代码?",
  "overwrite_quotation_confirm_content": "更改发货方/收货方代码，报价单将重新查询，请确认报价单号码与内容。",
  "packing_list": "装箱单",
  "part_description": "部品描述",
  "part_number": "部品编号",
  "parts": "部品",
  "per_height": "高 (CM)",
  "per_length": "长 (CM)",
  "per_weight": "宽 (CM)",
  "pending": "待审核",
  "pickup": "提货资讯",
  "pickup_info": "提货地址",
  "pickup_name": "提货方",
  "po_item_no": "採购单项目号码",
  "po_number": "採购单号码",
  "port_of_delivery": "交货港",
  "port_of_discharge": "卸货港",
  "port_of_landing": "装货港",
  "port_of_receipt": "收货港",
  "pre_assign": "Morrison 提供之可预先指派的 HAWB/HBL 号码",
  "pre_assign_number": "预先指派 HAWB/HBL 号码",
  "pre_assign_desc": "请先向 MEC取得预先分配给您的单号，且不不得重複使用相同号码",
  "qty_ea": "数量(每件)",
  "qty_of_outers": "数量(外包装)",
  "quantity": "数量",
  "quotation": "报价单",
  "quotation_number": "报价单号码",
  "quotation_status": "报价单状态",
  "quotation_type": "报价单类别",
  "range_in": "范围 %{start}~%{end}",
  "receipt_port": "收货港",
  "reference": "参考值",
  "reference_number": "参考值",
  "request_brokerage": "是否需要报关服务?",
  "request_brokerage_no": "否 - 我会自行处裡报关事宜",
  "request_brokerage_yes": "是-我希望 Morrison 协助我进行报关",
  "route": "航段",
  "sales_name": "业务人员",
  "same_as_consignee": "与收货方资讯相同",
  "same_as_consignee_check_label": "与收货方资讯相同",
  "same_as_notify": "与收货方资讯相同",
  "same_as_notify_check_label": "有通知方资讯",
  "same_as_shipper": "与发货方资讯相同",
  "same_as_shipper_check_label": "与发货方资讯相同",
  "save_to_address": "储存到我的地址簿",
  "secure_sales_no": "业务员工号",
  "secure_station": "所属站点",
  "select_customer_code": "请选择客户代码",
  "select_quotation_number": "请选择报价单号码",
  "service": "服务",
  "service_level": "服务等级",
  "service_type": "服务类型",
  "ship_type": "运输方式",
  "shipment_by_to": "这是哪个客户的货?",
  "shipment_mode_air": "空运",
  "shipment_mode_sea": "海运",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "参考值",
  "shipment_require": "提单种类",
  "shipment_require_option_1": "并装单 (HAWB/HBL)",
  "shipment_require_option_2": "直走单 (MAWB/MBL)",
  "shipment_require_option_3": "使用 Morrison 事先提供给您的提单号码",
  "shipment_to": "交货资讯",
  "shipment_to_info": "交货地址",
  "shipment_to_name": "交货方",
  "shipper": "发货方",
  "shipper_code": "发货方公司代碼",
  "shipper_info": "发货方地址",
  "shipper_name": "发货方",
  "shipping_marks": "外箱唛头",
  "shipping_mode": "运送方式",
  "system_quotation": "作业用报价单",
  "special_instruction": "装运须知",
  "state": "州/省",
  "status": "状态",
  "step_1_Service": "第一步：服务",
  "step_2_Address": "第二步：地址",
  "step_3_Detail": "第三步：细节",
  "step_4_Doc": "第四步：文件",
  "submit_at": "送出日期 (UTC)",
  "submit_by": "送出者",
  "submit_question_title": "确定建立订舱单?",
  "submit_question_content": "送出后只能在 Prolink/CargoWise 中修改，请确认发货方/收货方/报价单号码是否正确.",
  "submit_question": "确定送出? ",
  "submitted": "已送出",
  "submitted_at": "送出日期 (UTC)",
  "submitted_at_utc": "送出日期 (UTC)",
  "submitted_by": "送出者",
  "target_delivery_date": "期望送抵时间",
  "terms_confirm": "我已确认所有细节都是正确的并接受 Morrison的%{a1}订舱条款与条件%{a2}",
  "terms_confirm_check": "送出订舱委托前，请您先确认内容是否正确，并勾选接受 Morrison的订舱条款与条件",
  "terms_confirm_check_title": "无法送出请求",
  "terms_confirm_check_content": "送出订舱委託前，请您先确认内容是否正确，并勾选接受 Morrison的订舱条款与条件",
  "total_of_units": "总包裹数",
  "transporation": "运输",
  "transporation_mode": "运输类型",
  "type": "类型",
  "unit_weight": "毛重(每件)",
  "upload_doc": "装运单据",
  "value": "值",
  "volume_weight": "体积",
  "volume_weight_total": "总体积",
  "zip_code": "邮递区号",
  "i_will_provide_later": "将在稍后提供",
  "shipper_instruction_letter": "託运申请书",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Service Level Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port": "目的港",
  "destination_port_code": "目的港代码",
  "shipment": "货物细节",
  "rejected": "已拒绝",
  "i_am_not_sure_right_now_tip": "若您不知道发货方的姓名和地址，请勾选此框并提供托运人的联系信息，以继续进行订舱委託",
  "i_will_provide_later_tip": "请在发货前提供这类文件，以确保您的货物按时到达。如果您在预订时还没有此类文件，请勾选此框以继续订舱。",
  "status_hint_for_doc": "请提供相关文件",
  "status_hint_for_address": "请确认发货方资讯",
  "notify_section_titile": "通知方资讯",
  "address_checked_hint": "已自动带入对应的主档代号",
  "address_warning_hint": "存在多个对应的主档代号，请从清单中选择或点选放大镜图示检视细节",
  "address_error_hint": "找不到对应的主档代号，请向CCC申请新的代号或点选放大镜图示修改查询条件",
  "quotation_warning_hint": "存在多个对应的报价单，请从清单中选择或点选放大镜图示检视细节",
  "quotation_checked_hint": "已自动带入对应的报价单",
  "quotation_error_hint": "找不到对应的报价单，请点选放大镜图示修改条件查询。",
  "active": "有效的",
  "draft": "草稿",
  "booking_in_progress": "订舱中",
  "in_progress": "订舱完成",
  "completed": "已完成",
  "canceled": "已取消",
  "notify_only_allow_upload_once": "档案仅能上传一次，若上传后需要修改，请于下方留言并提供要修改的档案。",
  "query_customer_code": "客户代码/名称",
  "reference_type_value_di_values": "DI 参考值",
  "reference_type_value_in_values": "IN 参考值",
  "reference_type_value_po_values": "PO 参考值",
  "reference_type_value_sr_values": "SR 参考值",
  "reference_type_value_other_values": "其他参考值",
  "cargo_ready_date_note": "货好时间需早于期望送达时间",
  "cargo_ready_date_at": "货好时间",
  "is_dangerous_goods": "危险货品",
  "booking_date": "订舱日期",
  "actual_pickup_at": "实际提货时间",
  "etd": "预计离港(ETD)",
  "atd": "实际离港(ATD)",
  "eta": "预计到港(ETA)",
  "ata": "实际到港(ATA)",
  "service_type_dtd": "DTD",
  "service_type_dta": "DTA",
  "service_type_dtp": "DTP",
  "service_type_ptp": "PTP",
  "service_type_ptd": "PTD",
  "service_type_ata": "ATA",
  "service_type_atd": "ATD",
  "out_for_delivery_date": "外出运送",
  "actual_delivery_date": "实际运送日",
  "customer_expected_eta_at": "期望离港",
  "only_allow_decimal": "只允許輸入小數%{num}位",
  "debit_to_code_is_inactive": "报价单中的请款对象代号 %{debit_to_id} %{debit_to_name}不可使用，请与业务确认报价单，以建立booking",
  "booking_source_list": "Booking Source",
  "mop": "Created in Morrison One",
  "prolink": "Created in Prolink/CW1",
  "customer_edi": "Customer EDI",
  "summary_search_icon_hint": "To search and select the valid code.",
  "summary_search_quotation_icon_hint": "1. Please select the quotation by search \n2. Please contact Process team if no query result",
  "summary_select_shipper_hint": "Please complete shipper's information.",
  "summary_select_consignee_hint": "Please complete consignee's information.",
  "summary_select_pickup_hint": "Please complete pickup information.",
  "summary_select_delivery_hint": "Please complete delivery’s information.",
  "summary_select_notify_hint": "Please complete notify party’s information.",
  "summary_select_quotation_hint": "Please confirm quotation number.",
  "summary_select_bottom_hint": "Or please leave the comment to creator or submitter when information is uncertain.",
  "booking_no_incomplete": "The booking request no. %{number} information is incomplete. Please follow the steps to create booking.",
  "control_tower_submit_notify_title": "Online Booking hasn’t been set up PIC in %{origin_port} Station. Please follow the steps below to proceed the booking. ",
  "control_tower_submit_notify": "1. Contact %{origin_port} station and confirm PIC<br>\n2. Provide Booking Request No. link to PIC and instruct to confirm the booking<br>\n3. Provide PIC to Process Team %{email}<br>",
  "control_tower_submit_notify_sub": "You will receive an email contains above instruction."
 },
 "uld": {
  "info_existing_uld_can_append": "已存在一笔有效的ULD数据",
  "warning_mapping_exist": "此笔纪录已存在",
  "error_master_not_exist": "MAWB不存在",
  "error_uld_has_multiple_airline": "有效的ULD仅会属于一家航空公司，请确认资料",
  "error_duplicate_mapping_current_upload": "本次上传有重复数据",
  "error_violate_uld_naming": "ULD No应包括英数字",
  "error_mapping_lack_uld_no": "MAWB Bulid Type为ULD时，ULD No必有值",
  "error_loose_uld_no_number": "散货不需要ULD No",
  "error_build_type_not_support": "请确认MAWB Build Type是否有误，仅限ULD或LOOSE",
  "alert_receive_uld_list": "准备接收#%{count}个ULD，详列如下，请确认",
  "alert_remove_uld_from_request": "%{uld_no}将移出此归还清单，且ULD状态回到Dest Received，请确认",
  "alert_download_uld_return_request": "储存成功，欲下载ULD归还单?",
  "alert_cancel_return_request": "归还单内的所有ULD状态将回到Dest Received，且取消此归还单，请确认",
  "alert_uld_return_handover": "请确认已将归还单及ULD交接到卡车司机，Out For Return Date将为目前时间(可修改)",
  "alert_validate_uld_return_request": "所选ULD符合下列情况，请重选或修改数据\n-所选ULD包含多张归还单\n-所选ULD对应到多家航空公司\n-所选ULD正在归还或已经归还",
  "alert_relate_to_return_request": "请自归还单移出，才可删除所选ULD",
  "uld_status_loading": "00 未接收",
  "uld_status_dest_received": "10 已接收",
  "uld_status_dest_wait_for_return": "20 安排归还",
  "uld_status_dest_returning": "30 归还中",
  "uld_status_dest_returned": "40 已归还",
  "return_request_status_dest_returning_job_created": "00 建立归还单",
  "return_request_status_dest_out_for_return": "10 派送归还中",
  "return_request_statuss_dest_returned": "20 已完成归还",
  "return_request_status_cancelled": "90 取消归还单",
  "btn_upload_uld_mawb": "上传ULD-MAWB对应",
  "btn_uld_receiving": "准备接收",
  "btn_uld_returning": "准备归还",
  "btn_receive": "接收",
  "tooltip_master_build_type": "限填ULD或LOOSE",
  "tooltip_uld_type": "填入ULD type(非必填)，如：Q5, Q7",
  "undefined": "",
  "alert_carrier_code_not_exist": "",
  "alert_master_not_exist": "MAWB不存在",
  "alert_uld_in_another_return_request": "",
  "alert_airline_conflict": "ULD should share at most one airline",
  "alert_return_request_not_exist": "",
  "alert_return_request_is_cancelled": "Return request is cancelled",
  "alert_undefined_return_request_status": "",
  "alert_uld_conflict": "",
  "alert_cannot_add_uld_to_return_request": "",
  "master_origin_port": "MAWB Orig",
  "master_destination_port": "MAWB Dest",
  "master_number": "MAWB",
  "airline_name": "Airline",
  "uld_number": "ULD No",
  "uld_status": "ULD Status",
  "uld_return_number": "ULD Return No",
  "master_build_type": "MAWB Bulid Type",
  "note": "Note",
  "destination_uld_received_at": "Dest ULD Received Date",
  "is_uld_return_pod": "ULD Return POD",
  "destination_uld_returned_at": "Dest ULD Returned Date",
  "target_uld_returned_at": "Target ULD Returned Date",
  "master_eta_at": "MAWB ETA",
  "master_ata_at": "MAWB ATA",
  "master_atd_at": "MAWB ATD",
  "uld_received_by": "ULD Received By",
  "uld_received_truck_company": "ULD Received Truck Company",
  "uld_returned_truck_company": "ULD Returned Truck Company",
  "uld_remark": "ULD Remark",
  "uld_type": "ULD Type",
  "uld_bulk_upload": "ULD Upload",
  "flight_number": "Flight No",
  "delete_uld": "Do you want to delete this ULD?",
  "status_loading": "00 未接收",
  "status_received": "10 已接收",
  "status_wait_for_return": "20 安排归还",
  "status_returning": "30 归还中",
  "status_returned": "40 已归还",
  "receiving_submission": "Submit Receving Request",
  "received_time": "Received Time",
  "received_carrier_code": "Received Truck Company",
  "returning_card_title": "ULD Returning",
  "returning_number": "ULD Returning No",
  "returning_status": "Status",
  "not_added": "尚未加入",
  "added": "已经加入",
  "returning_delivery_details": "Delivery Details",
  "uld_number_count": "#ULD",
  "out_for_return_at": "Out For Return At",
  "out_for_return": "Out For Return",
  "remark": "Remark",
  "airline_received_by": "Airline Received By",
  "airline_received_datetime": "Airline Received Date",
  "attachments": "Attachments",
  "station": "Station",
  "airline": "Airline"
 },
 "ct": {
  "account_is_not_registered_with_morrison_one": "%{email} account is not registered with Morrison One",
  "actual_delivery_date": "已送達",
  "actual_pickup_at": "提货日期",
  "amount": "费用",
  "arrived_cfs_at": "抵达目的地集散站",
  "ata": "抵港",
  "atd": "实际离港",
  "attachment": "附件",
  "booking_date": "预定",
  "booking_number": "订舱单号码",
  "booking_request_number": "订舱委託单号码",
  "booking_request_no": "订舱委託单号码",
  "booking_number_name": "订舱单号码",
  "brokerag_and_cargo": "附加服务",
  "booking_status_draft": "草稿",
  "booking_status_submitted": "已送出",
  "booking_status_in_progress": "处理中",
  "booking_status_completed": "已完成",
  "booking_status_canceled": "已取消",
  "billing_info": "Billing Information",
  "calculated_by": "计算方式",
  "calculated_by_unit": "个别总结",
  "calculated_by_total": "按总装运量计算",
  "cancel_at_utc": "取消订舱日期",
  "cancel_by": "取消订舱人",
  "cancelled_at": "取消",
  "cancel_check": "是否确定要取消订舱委託單?",
  "cancel_notify_check": "Morrison 正在处理您的订单，若取消可能会有部分费用发生，我们将尽速确认费用后与您联繫，在此之前您的订单状态仍会维持在处理状态，是否确定取消?",
  "cargo_ready_date": "Cargo Ready Date",
  "cargo_insurance": "Cargo Insurance",
  "charge_weight": "收费重量",
  "charge_weight_unit": "收费重量单位",
  "chargeable_weight": "收费重量(Kg)",
  "client_name": "客户名称",
  "column_setting": "栏位设定",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc",
  "commodity": "货品",
  "comments": "Comments",
  "complete_date": "货运完成",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "收货方",
  "consignee_address": "收货方地址",
  "consignee_city": "收货方城市",
  "consignee_country": "收货方国家/地区",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "收货方",
  "consolidation_document": "Consolidation",
  "container": "货柜",
  "container_number": "货柜号码",
  "container_type": "货柜类型",
  "count": "统计",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "create_booking": "创建 Booking",
  "cubic_meter": "立方米",
  "currency": "货币",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "客户代码/名称",
  "customer_name": "客户名称",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customized_setting": "客制讯息栏位",
  "customized_table_header": "客制标题",
  "customs_cleared_at": "清关",
  "customs_released_at": "海关放行",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery_container_number": "Delivery/ Container No",
  "dest": "进口站",
  "destination": "进口站",
  "destination_port": "进口站",
  "destination_port_full": "Destination Port",
  "destination_port_country": "进口站国家/地区",
  "details": "详情",
  "delivery": "Delivery",
  "delivery_port": "Delivery Port",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "dimension": "尺寸",
  "do_number": "Document No",
  "doc_released_at": "放单",
  "document_slash_image": "文件 / 圖片",
  "documents": "文件",
  "download_qr_code": "下载手机扫码",
  "booking": "Booking",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "If you choose Direct Shipment, Morrison will provide the MAWB/MBL number via booking confirmation. Until then, you can use the booking request number to track the progress of the process.",
  "email": "邮箱",
  "enter_setting_name": "设定名称",
  "estimated_delivery": "预计送货时间",
  "estimated_pickup_at": "提货日期",
  "eta": "预计到港",
  "etd": "预计离港",
  "event_time_local": "事件時間 (當地)",
  "excepton": "异常",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_eta": "Target Delivery Date",
  "expected_pickup_date": "Expected Pickup Date",
  "export": "汇出",
  "fcr_shipment": "FCR Shipment",
  "fcl": "整柜 (FCL)",
  "fd_number": "FD 号码",
  "flight": "航班",
  "flight_from_port": "航班起站",
  "flight_number": "航班号",
  "flight_to_port": "航班终站",
  "freight_term": "付款条款",
  "freight_payment_term": "Freight Payment Term",
  "from": "起运",
  "general": "General",
  "goods_type": "Goods Type",
  "goods_info": "Goods information",
  "goods_description": "Goods Description",
  "gross_weight": "毛重",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "毛重单位",
  "group": "Group",
  "group_by": "显示方式",
  "handling_pieces": "货件数",
  "hawb_doc": "HAWB Doc",
  "height": "高度",
  "housebill_not_found": "找不到该笔提单",
  "housebill_number": "提单号",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "列表中如果没有申请的客户名称请选'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "发票号码",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_hazardous": "危险货品",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "發票日期",
  "last_status": "最新状态",
  "length": "长度",
  "local": "当地",
  "local_time": "当地时间",
  "logistic_handling": "物流操作",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc",
  "mec_invoice_doc": "MEC Invoice",
  "method": "装运方式",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "收信仅限Morrison One注册email",
  "name": "名称",
  "no_download_url": "No DownloadUrl !",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document!",
  "no_shipment_tracking": "No Shipment Tracking !",
  "notification_name": "Notification Name",
  "notification_settings": "通知设定",
  "notification_settings_hint": "設定啟用之後,會套用到每筆新Shipment 都發通知",
  "notify_group": "Notify Group",
  "notify": "Notify",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "通知方",
  "notify_when_shipment": "发通知当货态到达",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "实际到达",
  "option_milstone_booking_date": "预定确认",
  "option_milstone_completed_at": "货运完成",
  "option_milstone_etd": "预计出发",
  "order": "Order",
  "origin": "出口站",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "出口站",
  "origin_port_country": "出口站国家/地区",
  "other_docs": "Other Docs",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "out_for_delivery_date": "实际外出递送日期",
  "package_quantity": "包裹数",
  "packing_list": "Packing List",
  "parent_id": "Parent Code",
  "parts": "Parts",
  "part_number": "Part No",
  "part_description": "Part Description",
  "pallet": "栈板",
  "pick_up": "提货",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "收货地",
  "po_no": "PO No",
  "po_number": "PO No",
  "po_item_no": "PO Item No",
  "pod": "POD 时间",
  "pod_file": "POD 文件",
  "port_of_discharge": "卸货港",
  "port_of_delivery": "Port of Delivery",
  "port_of_landing": "装货港",
  "port_of_receipt": "Port of Receipt",
  "progress": "进度",
  "project": "专案",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "手机扫码快速查单",
  "quantity": "数量",
  "query": "查询",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "qty_ea": "Qty(EA)",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "参考",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "requester": "请求者",
  "result": "结果",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_shipper": "same as shipper",
  "same_as_consignee": "same as consignee",
  "same_as_shipper_check_label": "Pickup as same as Shipper",
  "same_as_consignee_check_label": "Delivery as same as Consignee",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "send_mail_notification_to": "送email 通知给谁",
  "send_me_pod_file": "寄发 POD 文件",
  "send_notification_from": "寄送通知",
  "send_sms_notification_to": "送SMS通知给谁",
  "secure_station": "Secure Station",
  "sequence": "顺序",
  "service": "Service",
  "service_level": "Service Level",
  "service_info": "服务资讯",
  "service_remark": "服务备注",
  "service_type": "服务类型",
  "ship_to": "收货",
  "ship_type": "Ship Type",
  "shipment_delay": "貨物延遲",
  "shipment_details": "货件详情",
  "shipment_mode": "运输",
  "shipment_number": "HAWB / HBL",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipper": "发货方",
  "shipper_address": "发货方地址",
  "shipper_city": "发货方城市",
  "shipper_country": "发货方国家/地区",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "发货方",
  "shipping_marks": "Shipping Marks",
  "shipping_instruction": "货运指示",
  "ship": "",
  "shipping_mode": "装运方式",
  "shipment_reference_no": "参考值",
  "sign_by": "签收人",
  "shipment_by_to": "这是哪个客户的货?",
  "special_instruction": "装运须知",
  "status": "状态",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "shipment_mode_air": "空运",
  "shipment_mode_sea": "海运",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "teu": "Teu",
  "to": "收货",
  "total_packages": "包裹数",
  "total_pallets": "栈板数",
  "total_pieces": "总件数",
  "total_spend": "总费用",
  "total_weight": "总重量",
  "total_weight_kg": "总重量 (Kg)",
  "trace_other_number": "追踪其他提单号码",
  "truck_size": "卡车尺寸",
  "truck_type": "车型",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "type": "类型",
  "uom": "单位",
  "upload_by": "上传者",
  "upload_date_time": "上传时间",
  "upload_doc": "装运单据",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_details": "详情",
  "uppercase_documents": "文件",
  "uppercase_in_progress": "进行中",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "追踪",
  "uppercase_tracking": "追踪",
  "unit_weight": "每件毛重",
  "value": "value",
  "vessel": "航班",
  "vessel_name": "船名",
  "vip_name": "客户",
  "volume_weight": "体积重量",
  "volume_weight_total": "总体积",
  "volumetric_weight": "体积重量",
  "voyage": "航程",
  "weight_kg": "重量 (Kg)",
  "width": "宽",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "货运配送状况",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "load": "装货港",
  "loads": "Load",
  "lcl": "併柜货(LCL)",
  "loose_goods": "散货",
  "discharge": "卸货港",
  "total_of_units": "总包裹数",
  "qty_of_outers": "Uty of Outers",
  "per_length": "长 (CM)",
  "per_weight": "宽 (CM)",
  "per_height": "高 (CM)",
  "pre_assign": "Morrison 提供之可预先指派的 HAWB/HBL 号码",
  "pre_assign_desc": "请先向 MEC取得预先分配给您的单号，且不不得重複使用相同号码",
  "pre_assign_fill": "I have Morrison provide.",
  "request_brokerage": "是否需要报关服务?",
  "need_carge": "是否需要货物运输保险?",
  "request_brokerage_yes": "是-我希望 Morrison 协助我进行报关",
  "request_brokerage_no": "否 - 我会自行处裡报关事宜",
  "need_carge_yes": "是 - 我希望 Morrison 协助购买货物运输保险",
  "need_carge_no": "否 - 我已购买保险/我对任何丢失或损坏的货物承担全部责任",
  "select_quotation_number": "Select Quotation Number",
  "shipper_code": "Shipper Code",
  "consignee_code": "Consignee Code",
  "quotation_type": "Quotation Type",
  "quotation_status": "Quotation Status",
  "is_effective": "Is Effective",
  "submitted_at": "Submit At",
  "commodity_code": "Commodity Code",
  "commodity_desc": "Commodity Description",
  "others_term": "Others Payment",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "secure_sales_no": "Secure Sales No.",
  "department": "Department",
  "select_customer_code": "Select Customer Code",
  "address": "Address",
  "city": "City",
  "state": "State",
  "zip_code": "Zip Code",
  "country": "Country/Area",
  "contact_name": "Contact Name",
  "code": "Code",
  "overwrite_confirm": "你想覆盖托运人/收货人信息吗？",
  "save_to_address": "储存到我的地址簿",
  "not_sure_right_now": "我现在还不知道",
  "submit_question": "是否送出定舱委託单?",
  "diff_origin_area_notify": "你选择的托运人国家与始发港不同 ",
  "diff_dest_area_notify": "你选择的收货人国家与目的港不同 ",
  "pod_doc": "POD档案",
  "biling_name": "客户名称",
  "teu_column_tooltip": "TEU为一预估值，可能因资料缺失导致无法正确计算",
  "shipment_query_limitation_info": "Query data is available for booking shipment after 1st June 2020",
  "milestone_status_prepare_for_depature": "准备出发",
  "milestone_status_departed_from_port": "已出发",
  "milestone_status_arrived_at_port": "已抵达",
  "milestone_status_prepare_for_delivery": "准备递送",
  "milestone_status_out_for_delivery": "外出递送",
  "milestone_status_delivered": "已送达",
  "milestone_status": "货态查询",
  "shipment_created_at_utc": "创建时间(UTC)",
  "shipment_count": "总货运数量",
  "shipment_count_descript": "总Shipment 件数",
  "ready_for_departure_count": "准备出发数量",
  "departed_at_port_count": "已出发数量",
  "arrived_at_port_count": "已抵达数量",
  "prepare_for_delivery_count": "准备递送数量",
  "out_for_delivery_count": "外出递送数量",
  "delivered_count": "已送达数量",
  "total_chargeable_weight": "总收费重量(KG)",
  "abbr_consignee_name": "收货方",
  "billing_account_name": "客户名称",
  "completed_shipment_count": "已完成货运总数",
  "completed_shipment_count_sea_tooltip": "已完成的HBL总件数",
  "completed_chargeable_weight": "已完成总收费重量(KG)",
  "cancelled_shipment_count": "已取消货运总数",
  "cancelled_shipment_count_sea_tooltip": "已取消的HBL总件数",
  "cancelled_chargeable_weight": "已取消总收费重量(KG)",
  "total_shipment_count": "总货运数量",
  "total_shipment_count_sea_tooltip": "总HBL件数",
  "total_delivery_count": "总Delivery数",
  "total_delivery_count_sea_tooltip": "总Delivery/货柜件数",
  "total_teu": "总TEU数",
  "total_cubic_meter": "总立方米",
  "completed_delivery_count": "已完成Delivery总件数",
  "completed_delivery_count_sea_tooltip": "已完成Delivery/货柜总件数",
  "total_completed_teu": "已完成总TEU数",
  "total_completed_cubic_meter": "已完成总立方米",
  "cancelled_delivery_count": "已取消Delivery总件数",
  "cancelled_delivery_count_sea_tooltip": "已取消Delivery/货柜总件数",
  "total_cancelled_teu": "已取消总TEU数",
  "total_cancelled_cubic_meter": "已取消总立方米",
  "available_for_shipment_within_six_month": "仅提供查询六个月内货况",
  "week": "货运创建周",
  "error_notification_already_exists": "Notification for shipment %{shipment_number} already exists, please refresh the page.",
  "client_id": "客户编号",
  "advanced_filter": "更多选项",
  "co2_emission_estimation": "碳排放量预估",
  "co2_emission_summary": "预估碳排放量总和",
  "co2_emission_warning": "Carbon emissions values on the Morrison One Platform are provided by EcoTransIT. EcoTransIT is a flexible tool for complex calculations that supports companies to calculate their emissions. The software is accredited by Smart Freight Centre and is in compliance with the GLEC Framework and also meets the requirements of EN 16258 and the GHG protocol (Corporate Standard). Visit https://www.ecotransit.org/ for more details.",
  "co2_emission_not_availabled": "无法使用",
  "co2_emission_transport_status": "运输类别",
  "co2_emission_estimated_distance": "预估距离 (公里)",
  "co2_emission_estimated_co2_emission": "预估碳排放量 (公斤)",
  "co2_emission_well_to_tank": "油井到油箱 (WTT)",
  "co2_emission_tank_to_wheel": "油箱到车轮  (TTW)",
  "co2_emission_well_to_wheel": "油井到车轮  (WTW)",
  "co2_emission_pick_up_from_shipper": "託运人自取",
  "co2_emission_terminal_to_terminal": "航站至航站",
  "co2_emission_delivery_to_customer": "送货至客户",
  "co2_emission_total_summary": "各项加总",
  "quick_track_wrong_format": "格式错误",
  "quick_track_submit_btn": "查询",
  "quick_track_input_placeholder": "输入提单号追踪",
  "quick_track_footer": "Morrison One 取得更多资讯,如未有Ｍorrison One帐号请与您的业务窗口申请",
  "quick_track_milestone_history": "里程碑历史",
  "quick_track_current_milestone_status": "里程碑状态",
  "quick_track_404_title": "无效的追踪单号",
  "quick_track_404_text": "请您再次确认输入信息或输入其他追踪单号",
  "quick_track_housebill_not_found": "找不到该笔提单,请您再次确认输入信息或查询另一笔提单",
  "milestone_status_cancelled": "已取消",
  "milestone_status_tooltip": "准备出发：预定/提货/出口清关\n已出发：实际离港\n已抵达：抵港/放单/海关放行\n准备递送：抵达目的地集散站\n外出递送：实际外出递送\n已送达：已送达",
  "port_list_placeholder": "请输入港口代号 范例: TPE",
  "country_code_list_placeholder": "请输入国家代号 范例: TW",
  "undefined": ""
 },
 "dashboard": {
  "add_section": "Add Section",
  "add_widget": "Add Widget",
  "add_widget_row": "Add Widget Row",
  "all": "All",
  "component_option_customer_tracking_shipments_query": "Customer Tracking Shipments Query",
  "custom_day_range": "%{daynumber} 天内",
  "custom_week_range": "%{weeknumber} 周内",
  "in_past_n_weeks": "过去 %{weeknumber} 周内",
  "life_time": "全部",
  "current_week": "本周",
  "last_week": "上周",
  "last_last_week": "上上周",
  "nth_week_of_last_year": "去年第 %{weeknumber} 周",
  "nth_week_of_this_year": "今年第 %{weeknumber} 周",
  "add_nth_week_option": "输入数字选择周数",
  "custom-widget-2d-table-column-key": "Column Key",
  "custom-widget-2d-table-column-key-order": "Column Order",
  "custom-widget-2d-table-custom-column-key": "Custom Column",
  "custom-widget-2d-table-has-filter": "Has Filter",
  "custom-widget-2d-table-column-selector-detail": "Column Selector Detail",
  "custom-widget-2d-table-key-agg": "Aggregate",
  "custom-widget-2d-table-row-key": "Row Key",
  "custom-widget-2d-table-row-key-order": "Row Order",
  "custom-widget-2d-table-nongroup-column-key": "NonGroup Column",
  "custom-widget-group-by": "Group By",
  "custom_widget_prepend_text": "前置文本",
  "dashboard": "仪表板",
  "data_selector": "\n选择日期区间",
  "edit_widget": "Edit Widget",
  "error_add_fast_create_dispatch": "You cannot fast create with different dispath no",
  "hello_word": "Hi, %{account}. Welcom to Morrison One Platform.",
  "last_update": "最后更新",
  "select_a_dashboard": "select a dashboard",
  "setting_column_aggregate_avg": "average",
  "setting_column_aggregate_count": "count",
  "setting_column_aggregate_max": "max",
  "setting_column_aggregate_min": "min",
  "setting_column_aggregate_sum": "sum",
  "total": "总数",
  "grand_total": "累计",
  "user_picker": "User Picker",
  "filter_input_placeholder": "使用逗号(,)分隔",
  "undefined": "",
  "component_option_kpi_late_milestone_query": "KPI Weekly Detail Late Milestone",
  "component_option_kpi_summary_query": "KPI Summary Query",
  "default": "Default Dashboard",
  "set_as_default": "Make Default",
  "use_i18n": "Use i18n",
  "ct_dashbaord_prepend_text": "最新货况请以Tracking/Shipment资料为准, 本页货况资料延迟至少15分钟.",
  "ct_dashboard_prepend_default_range_notice": "本仪表板预设查询区间为近3个月内资料, 可使用 \"日期区间\" 功能调整查询区间最长至近12个月",
  "ct_dashboard_teu_notice": "TEU为一预估值，可能因资料缺失导致无法正确计算,本资料仅供参考",
  "has_advanced_search_date_range_limit": "进阶搜寻日期范围限制",
  "has_advanced_search": "启用进阶搜寻",
  "prepend_text": "前置文本",
  "prepend_i18n_key": "前置 I18n 键",
  "prepend_text_classname": "Prepend Text className",
  "quick_guide_privilege_id": "Quick Guide Privilege ID",
  "ct_advanced_search_within_days_next_not_allowed": "不可超过今日日期",
  "ct_advanced_search_within_days_min_error": "最小查询范围为1天",
  "ct_advanced_search_within_days_max_error": "最大查询范围近180天",
  "ct_advanced_search": "日期区间",
  "use_custom_advanced_search_key": "定义进阶搜寻按钮文字",
  "custom_advanced_search_i18n_key": "进阶搜寻 I18n 键",
  "ct_advanced_search_within_days_min_variable_error": "最小查询范围为%{number}天",
  "ct_advanced_search_within_days_max_variable_error": "最大查询范围近%{number}天",
  "ct_advanced_search_between_min_variable_days_error": "仅提供查询%{number}日内货况"
 },
 "edi": {
  "undefined": "Updated Date",
  "hawb_number": "HAWB / HBL",
  "customer_code": "客户代码",
  "customer": "VIP Name",
  "ediMappingStatusList": "EDI处理状态",
  "ediProcessStatus": "EDI处理状态",
  "edi_type": "EDI 类型",
  "created": "Created",
  "batch_send_fail": "Batch Send Fail",
  "batch_send_success": "Batch Send Success",
  "canceled": "Canceled",
  "generate_success": "Generate Success",
  "send_ack_failed": "Send Ack Failed",
  "send_success": "Send Success",
  "send_wait_ack": "Send Wait Ack",
  "send_fail": "Send Fail",
  "validate_fail": "Validate Fail",
  "validate_retry": "Validate Retry",
  "bill_to_party": "Bill-to Party",
  "origin_port": "Orig",
  "destination_port": "Dest",
  "invoice_number": "Inv No",
  "trade_type": "Trade Type",
  "non_trade": "Non-Trade",
  "trade": "Trade",
  "index_properties": "Index Ref",
  "inv_amount": "Inv Amount",
  "inv_currency": "Inv Currency",
  "shipment_first_atd": "HAWB First ATD (Local Time)",
  "edi_sent_at": "EDI Sent Date (UTC) ",
  "process_message": "Message",
  "isa_number": "ISA No",
  "billing_issued_at": "Inv date",
  "print_at": "Print Date",
  "shipment_last_ata": "Last ATA",
  "last_atd_input_date": "Last ATA Input Date(UTC)",
  "shipment_actual_pod": "POD",
  "edi_status_hint": "Edi status ",
  "pod_input_date": "POD Input Date(UTC)"
 },
 "config": {
  "client_id": "客户编号",
  "vip_name": "Customer",
  "status_type": "Status Type",
  "edi_category": "EDI Category",
  "edi_event_code": "EDI Event Code",
  "edi_status_code": "EDI Status Code",
  "edi_type": "EDI Type",
  "event_description": "Event Description",
  "mode": "Mode",
  "mop_status_code": "Mop Status Code",
  "is_synced": "已經同步",
  "edi_charge_code": "EDI Charge Code",
  "edi_charge_description": "EDI Charge Description",
  "prolink_charge_code": "Prolink Charge Code",
  "prolink_charge_description": "Prolink Charge Description",
  "confirm_delete": "確認刪除",
  "are_you_sure_to_delete_this_system_setting": "確定要刪除此系統設定？",
  "setting_name": "設定名稱",
  "setting_value": "設定值",
  "need_sync": "需要同步",
  "are_you_sure_to_delete_this_mec_station": "確定要刪除此站點設定？",
  "code": "Code",
  "source_db": "Source DB",
  "station": "Station",
  "role": "Role",
  "time_zone": "Time Zone",
  "name_1": "Name 1",
  "name_2": "Name 2",
  "address_1": "Address 1",
  "address_2": "Address 2",
  "address_3": "Address 3",
  "address_4": "Address 4",
  "telephone": "Telephone",
  "fax": "Fax",
  "zip_code": "Zip Code",
  "city_code": "City Code",
  "state_code": "State Code",
  "country_code": "Country Code",
  "edi_city_name": "Edi City Name",
  "contact_name": "Contact Name",
  "report_region": "Report Region",
  "prolink_remark": "Prolink Remark",
  "tms_ship_from_name": "TMS Ship From Name",
  "tms_ship_from_address": "TMS Ship From Address",
  "tms_ship_from_city": "TMS Ship From City",
  "tms_ship_from_country": "TMS Ship From Country",
  "tms_ship_from_contact": "TMS Ship From Contact",
  "tms_ship_from_state": "TMS Ship From State",
  "tms_ship_from_tel": "TMS Ship From Tel",
  "default_pdf_template": "Default PDF Template",
  "edi_code": "EDI Code",
  "edi_sender": "EDI Sender",
  "need_ack": "Need Ack",
  "need_confirm": "Need Confirm",
  "need_mdn": "Need Mdn",
  "shipper_id": "发货方ID",
  "entity": "层级",
  "entity_field": "层级栏位",
  "entity_for_timezone_reference": "Entity For Timezone Reference",
  "timezone_reference_field": "Timezone Reference Field",
  "timezone_reference_type": "Timezone Reference Type",
  "notify_people": "异动此设置将会通知: ",
  "setting_description": "设定描述: ",
  "table_options": "Table Options"
 },
 "epod": {
  "can_not_upload_because_checked": "POD 档案已检核，无法继续上传，请联络 Morrison OP。",
  "input_error_code": "请输入例外代码",
  "company_stamp": "Company Stamp（公司章签收）",
  "same_as_consignee": "Same As Consignee ( 同收货人名）",
  "error_ofd_datetime_not_future_time": "晚于当前时间，请仔细检查后再录入。",
  "error_invalid_date_format": "无效的日期/时间格式",
  "error_pod_datetime_not_future_time": "签收时间晚于当前时间，请仔细检查后再录入。",
  "error_pod_datetime_earlier_than_ofd_time": "早于外出递送时间，请仔细检查后再录入。"
 },
 "shalog": {
  "truck_plate_no": "卡车车牌号码",
  "lock_info_due_to_template_setting": "此字段已锁定(依据样板设定)",
  "template": "样板",
  "error_shipment_pcs_should_not_be_zero": "货件数需大于0",
  "total_pallets_or_loose_cartons_should_not_be_zero": "货件数需大于0",
  "all": "全部",
  "shipment_template_id": "货物模板ID",
  "project": "专案",
  "handing_station": "责任站点",
  "fd_no": "FD号码",
  "pr_no": "PR号码",
  "sales_owner": "业务代码",
  "template_remark": "样板注记",
  "customer": "客户",
  "shipper": "发货人",
  "consignee": "收货人",
  "client_id": "客户代码",
  "effective_start": "生效开始日期",
  "effective_end": "生效結束日期",
  "handling_station": "责任站点",
  "billing_station": "出帐站点",
  "billing_station_conf": "出账站点设置",
  "logistic_handling": "物流操作",
  "origin_station": "始发站",
  "destination_station": "目的站",
  "origin_station_conf": "始发站设置",
  "shipment_mode": "运输方式",
  "shipment_mode_conf": "运输方式设置",
  "template_number": "样板号码",
  "template_status": "模板状态",
  "active": "啟用",
  "inactive": "撤销",
  "activate": "啟用",
  "deactivate": "撤销",
  "shipment": "货物",
  "routing": "路线",
  "milestones_and_document": "事件与文件",
  "voucher_and_invoice": "凭单与发票",
  "shipment_info": "货物信息",
  "are_you_sure_you_want_to_activate_the_shipment_template": "您确定要啟用此货物模板吗？",
  "are_you_sure_you_want_to_deactivate_the_shipment_template": "您确定要撤销此货物模板吗？",
  "step_one_shipment": "第一步: 貨物",
  "step_two_milestones": "第二步：事件",
  "step_three_document": "第三步：文件",
  "step_four_billing": "第四步：出帐",
  "cargo_details": "货物详情",
  "shipment_template_remark": "货物模板注记",
  "effective_from": "生效开始日期",
  "effective_to": "生效結束日期",
  "origin": "始发",
  "destination": "目的",
  "ship_mode": "运输方式",
  "description_of_goods": "货物描述",
  "reference_no": "参考号码",
  "special_instruction": "特殊指示",
  "dangerous_good": "危险品",
  "package": "包装",
  "pallet_qty": "栈板数",
  "loose_ctn_qty": "箱散数",
  "shipment_pcs": "货件数",
  "gw": "毛重",
  "cw": "計費重量",
  "volume": "体积",
  "volumetric_weight": "体积重",
  "delivery": "交货",
  "pick_up_name": "提货名",
  "pick_up_address": "提货地址",
  "city": "城市",
  "state": "州别",
  "zip_code": "邮递区号",
  "country": "国家/地区",
  "contact_name": "联络人",
  "contact_email": "电子信箱",
  "phone_number": "电话",
  "fd_info": "FD资讯",
  "bpo_no": "BPO号碼",
  "search_fd_info": "搜索FD资讯",
  "search": "搜索",
  "submit": "提交",
  "back": "返回",
  "next": "下一步",
  "add": "新增",
  "save": "儲存",
  "fd_manager": "FD主管",
  "choose_bpo": "选择BPO",
  "apple_dri": "苹果负责人",
  "change_info": "更改信息",
  "please_choose_station_and_customer_first": "请先选择站点及客户",
  "shipment_template": "货物模板",
  "billing": "出帐",
  "milestones": "事件",
  "document": "文件",
  "total_bpo_amount": "总BPO金额",
  "bpo_spent_balance_percentage": "BPO 消费结余 (%)",
  "total_internal_invoice_amount": "总内部帐单金额（A）",
  "total_billed_amount_in_trck": "总Apple TRCK已出帐金额",
  "spent_balance": "花费结余 (总BPO金额 - A)",
  "total_bpo_billed_balance": "总BPO已出帐结余",
  "service_type": "服务类型",
  "service_remark": "服务注记",
  "truck_type": "卡车类型",
  "truck_size": "卡车尺寸",
  "flight_number": "航班号码",
  "from_port": "起始港",
  "to_port": "目的港",
  "add_service": "新增服务",
  "delete_service": "删除服务",
  "service_info": "服务资讯",
  "routing_info": "路线资讯",
  "from": "起",
  "to": "迄",
  "stop": "停靠点",
  "same_as_shipper": "同发货人",
  "same_as_consignee": "同收货人",
  "name": "名字",
  "address": "地址",
  "floor": "Floor/Suit/Unit",
  "port_slash_location": "港 / 地点",
  "stop_remark": "停靠站注记",
  "location_name": "地点名称",
  "port_code": "港口代码",
  "add_stop": "新增停靠站",
  "milestone_info": "事件资讯",
  "timezone": "时区",
  "add_milestone": "新增事件",
  "description": "描述",
  "delete_this_stop": "删除停靠点",
  "milestone": "事件",
  "port": "港口",
  "location": "地点",
  "vendor": "供应商",
  "dept": "部门",
  "payment_method": "付款方式",
  "customer_name": "客户名称",
  "charge_code": "收费代码",
  "charge_item": "收费项目",
  "charge_description": "收费叙述",
  "qty": "数量",
  "required_voucher": "需要的凭单",
  "required_invoice": "需要的帐单",
  "add_voucher": "新增凭单",
  "station": "站点",
  "debit_to": "欠款对象",
  "debit_to_name": "欠款对象名称",
  "delete": "删除",
  "edit": "编辑",
  "unit": "单位",
  "rate": "费率",
  "charge_uvat_amount": "收费税前金额",
  "charge_vat_amount": "收费税金",
  "charge_amount": "收费金额",
  "rate_currency": "收费币别",
  "add_invoice": "新增发票",
  "sales_code": "业务代码",
  "add_item": "新增项目",
  "edit_voucher": "编辑凭单",
  "edit_invoice": "编辑发票",
  "document_type": "文件类型",
  "mandatory": "必要的",
  "document_setting": "文件设定",
  "vendor_name": "供应商名称",
  "complete": "完成",
  "add_document_type": "新增文件类型",
  "to_be_created": "待创建",
  "printed": "已列印",
  "origin_nvat_amount": "原始税前金额",
  "origin_vat_amount": "原始税金",
  "origin_amount": "原始金额",
  "origin_nvat_amount_in_usd": "原始美金税前金额",
  "origin_vat_amount_in_usd": "原始美金税金",
  "origin_amount_in_usd": "原始美金金额",
  "origin_currency": "原始币别",
  "updated_by": "更新者",
  "updated_at_utc": "更新时间(UTC)",
  "void_voucher": "废除凭单",
  "reqest_voucher_processing": "请求凭单(处理中)",
  "reqest_invoice_processing": "请求发票(处理中)",
  "void": "銷帳",
  "created_invoice": "已创建发票",
  "created_voucher": "已创建凭单",
  "invoice": "发票",
  "voucher": "凭单",
  "show_voided": "显示废除",
  "voucher_currency": "凭单币别",
  "total_sales": "总销售",
  "total_costs": "总成本",
  "gross_margin": "毛利率",
  "origin_charge_rate": "原始收费费率",
  "voucher_no": "凭单号码",
  "action": "Request类别",
  "the_field_will_be_not_editable_in_the_shipment_data": "此栏位将 <b>不可被编辑</b> 在货物资料中",
  "the_field_will_be_editable_in_the_shipment_data": "此栏位将 <b>可被编辑</b> 在货物资料中",
  "please_select_a_fd_number_for_this_template": "请为此货物模板选择一个FD号码",
  "please_note_that_this_bpo_only_has_spend_balance_budget_left": "请注意此BPO仅剩下 %{spendBalance}%",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未储存的资料将遗失，您确定要离开吗?",
  "tips": "提示",
  "customer_code_name": "客户代码/名称",
  "payment_term": "付款条件",
  "customer_code": "客户代码",
  "once_completed_this_template_cannot_be_changed_are_you_sure_to_continue": "一旦完成此模板将无法改变，您确定要继续吗?",
  "vip_name": "VIP名称",
  "shipper_city": "发货城市",
  "shipper_address": "发货地址",
  "consignee_city": "收货城市",
  "consignee_address": "收货地址",
  "consignee_address_localized": "當地的收货地址",
  "shipper_address_and_city": "发货地址 & 城市",
  "consignee_address_and_city": "收货地址 & 城市",
  "create_from_utc": "创建日期从 (UTC)",
  "create_to_utc": "创建日期到 (UTC)",
  "created_at_utc": "创建日期 (UTC)",
  "dg_y_n": "DG (Y/N)",
  "project_manager": "专案管理人",
  "shipper_id": "发货人ID",
  "consignee_id": "收货人ID",
  "to_be_created_voucher": "待创建凭单",
  "processing_voucher": "处理凭单",
  "printed_voucher": "已列印凭单",
  "to_be_created_invoice": "待创建发票",
  "processing_invoice": "处理发票",
  "printed_invoice": "已列印发票",
  "last_updated_utc": "最后更新时间 (UTC)",
  "calculating": "计算中",
  "not_including_mec_internal_billing": "仅计算 SHALOG 且状态为 PRINTED 的凭单与发票",
  "clone": "复制",
  "can_not_be_zero": "不能为 0",
  "addressbook": "地址簿",
  "clear": "清除",
  "voucher_date": "報價日期",
  "invoice_date": "發票日期",
  "request_id": "請求編號",
  "voucher_processing_and_printed": "報價單(處理中 & 印出)",
  "invoice_processing_and_printed": "發票(處理中 & 印出)",
  "bill_to": "付款對象",
  "cancel": "取消",
  "invoice_no": "發票號碼",
  "undo": "復原",
  "chargeable_weight": "收费重量(Kg)",
  "gross_weight": "毛重",
  "service_provider": "服务提供者 %{index}",
  "po_ready": "PO 就绪",
  "invoice_ready": "发票就绪",
  "bulk_update": "批量更新",
  "generate_shipper_letter": "产生签收单",
  "invoice_ready_at_utc": "发票就绪时间 (UTC)",
  "po_ready_at_utc": "PO 就绪时间 (UTC)",
  "invoice_ready_by": "发票就绪确认者",
  "po_ready_by": "PO 就绪确认者",
  "missing_pod": "缺失 POD 时间",
  "missing_pick_up": "缺失提货时间",
  "missing_pod_file": "缺失 POD 档案",
  "is_ready": "Is Ready",
  "total_shipments_selected": "总共选取 %{count} 笔货物",
  "is_invoice_ready": "发票就绪",
  "is_po_ready": "PO 就绪",
  "is_invoice_ready_true": "Yes",
  "is_invoice_ready_false": "No",
  "is_po_ready_true": "Yes",
  "is_po_ready_false": "No",
  "invoice_no_ready_count": "发票未就绪",
  "po_no_ready_count": "PO 未就绪",
  "invoice_no_ready_percentage": "发票未就绪 (%)",
  "po_no_ready_percentage": "PO 未就绪 (%)",
  "actual_delivery_date (in nth week)": "POD时间",
  "created_at_utc (in nth week)": "建单时间",
  "number_of_clones": "复制数量",
  "number_of_clones_is_required": "必要填写复制数量",
  "the_maximum_number_of_clone_is_50": "最大复制上限50笔",
  "shipment_query_table_total_revenue": "总销售额\r",
  "shipment_query_table_total_cost": "总成本",
  "shipment_query_table_total_gross_profit": "毛利润",
  "shipment_query_table_total_gross_margin": "毛利率",
  "shipment_query_table_customs_gross_margin": "报关毛利率",
  "shipment_query_table_documentation_gross_margin": "文件服务毛利率",
  "shipment_query_table_domestic_gross_margin": "本地服务毛利率",
  "shipment_query_table_freight_gross_margin": "运费毛利率",
  "shipment_query_table_handling_gross_margin": "操作毛利率",
  "shipment_query_table_trucking_gross_margin": "卡车毛利率",
  "shipment_query_table_warehouse_gross_margin": "仓储毛利率",
  "shipment_query_table_logistic_gross_margin": "物流毛利率",
  "hawb_hbl": "HAWB / HBL",
  "shipment_edit": "Shipment Edit",
  "fd_number": "FD 号码",
  "shipment_count": "提单数量",
  "invoice_amount": "发票金额",
  "po_amount": "PO 金额",
  "gross_margin_in_usd": "毛利",
  "gross_margin_percentage": "毛利率",
  "late_creation_count": "晚建单",
  "late_creation": "晚建单",
  "missing_milestone": "缺失事件",
  "missing_invoice": "缺失发票数量",
  "missing_voucher": "缺失 PO 数量",
  "missing_reference": "缺失 Reference 数量",
  "late_creation_percentage": "晚建单 (%)",
  "missing_pick_up_count": "缺失提货时间",
  "missing_pod_count": "缺失POD时间",
  "missing_pod_file_count": "缺失POD档案",
  "missing_pod_percentage": "缺失POD时间 (%)",
  "missing_pick_up_percentage": "缺失提货时间 (%)",
  "missing_pod_file_percentage": "缺失POD档案 (%)",
  "missing_milestone_percentage": "缺失里程碑 (%)",
  "missing_invoice_percentage": "缺失发票 (%)",
  "missing_voucher_percentage": "缺失PO (%)",
  "please_choose_shipment_template": "请选择货运范本",
  "warning_bpo_percentage_too_low": "请注意这笔 BPO 仅剩下 %{number} 可用",
  "confirm_bpo_percentage_too_low": "请注意这笔 BPO 已经小于 0% 可用",
  "logistic_handling_shgdt": "SHGDT",
  "mode_comprehensive": "Comprehensive",
  "table_header_service": "服务 %{index}",
  "table_header_service_type": "服务类型 %{index}",
  "table_header_service_remark": "服务注记 %{index}",
  "table_header_service_handling_station": "服务责任站点 %{index}",
  "table_header_service_truck_type": "服务卡车类型 %{index}",
  "table_header_service_truck_size": "服务卡车尺寸 %{index}",
  "table_header_service_flight": "服务航班 %{index}",
  "table_header_service_flight_to_port": "服务目的港 %{index}",
  "table_header_service_flight_from_port": "服务始发港 %{index}",
  "table_header_milestone": "事件 %{index}",
  "table_header_milestone_sequence": "事件序列 %{index}",
  "table_header_milestone_db_type": "事件资料库种类 %{index}",
  "table_header_milestone_description": "事件描述 %{index}",
  "table_header_milestone_datetime": "事件日期 %{index}",
  "table_header_milestone_tz": "事件时区 %{index}",
  "table_header_document": "文件 %{index}",
  "table_header_document_type": "文件类型 %{index}",
  "table_header_document_name": "文件名称 %{index}",
  "table_header_document_link": "文件连结 %{index}",
  "are_you_sure_you_want_to_cancel_the_shipment": "确认删除货物?",
  "shipment_number": "货物号码",
  "origin_port": "始发站",
  "destination_port": "目的站",
  "draft": "草稿",
  "in_progress": "处理中",
  "completed": "已完成",
  "cancelled": "已取消",
  "DRAFT": "草稿",
  "IN_PROGRESS": "处理中",
  "COMPLETED": "已完成",
  "CANCELLED": "已取消",
  "is_dangerous": "危险品",
  "created_at": "创建时间",
  "bpo_number": "BPO号码",
  "shipper_name": "发货人",
  "shipper_state": "发货州别",
  "shipper_country": "发货国家/地区",
  "consignee_name": "收货人",
  "consignee_state": "收货州别",
  "consignee_country": "收货国家/地区",
  "pickup_at": "提货时间",
  "pickup_at_tz": "提货时间时区",
  "pod_at": "实际送达时间",
  "pod_at_tz": "实际送达时间时区",
  "sla_at": "SLA时间",
  "sla_at_tz": "SLA时间时区",
  "delivery_number": "Delivery No",
  "are_you_sure_you_want_to_clone_the_shipment": "确认复制货物?",
  "confirm_clone_shipment": "确认复制货物?",
  "milestone_at": "事件时间",
  "status": "状态",
  "PACKING_LIST": "箱单",
  "COMMERCIAL_INVOICE": "商业发票",
  "PROOF_OF_DELIVERY": "交货证明",
  "add_file": "新增档案",
  "view_file": "检视档案",
  "upload_document": "上传文件",
  "empty": "空",
  "gm_percentage": "毛利率 %",
  "reference_number": "参考号码",
  "are_you_sure_you_want_to_delete_the_address_book": "您确定要删除此地址? ID: %{id}",
  "select_address_book": "选择地址",
  "create_address_book": "创建地址",
  "edit_address_book": "编辑地址",
  "address_id": "地址ID",
  "missing_billing": "缺失帐单",
  "table_header_missing_billing": "缺失发票/PO",
  "larger_than_40_percentage": "大于 40%",
  "between_20_40_percentage": "20% ~ 40%",
  "between_0_20_percentage": "0% ~ 20%",
  "between_minus_20_0_percentage": "-20% ~ 0%",
  "between_minus_20_minus_40_percentage": "-40% ~ -20%",
  "less_than_minus_40_percentage": "小于 -40%",
  "master_number": "MAWB号码",
  "uom": "UOM",
  "factor": "Factor",
  "length": "长",
  "width": "宽",
  "height": "高",
  "cartons": "箱数",
  "part_number": "料号",
  "part_description": "料件叙述",
  "quantity": "数量",
  "delivery_po_number": "料件PO号码",
  "only_completed_shipment_can_be_clone": "只能复制已完成货物",
  "shipment_number_display": "货物号码: %{shipmentNumber}",
  "created_by": "创建者",
  "owner": "拥有者",
  "please_enter_correct_shipment_number": "请输入正确的货物号码",
  "delivery_method": "递送方式",
  "invalid_shipment_status": "无效的货物状态: %{status}",
  "pod_signed_by": "POD Signed By",
  "pod_signed_by_copy_from": "从收货联络复制",
  "pickup_date_should_be_earlier_than_pod_date": "实际提货日期应该早于实际递送日期",
  "pod_date_should_be_later_than_pickup_date": "实际递送日期应该晚于实际提货日期",
  "link_shipment": "已关联货物",
  "save_shipment_detail_and_change_milestone_warning": "Your address has been changed. This will also change the routing address and milestone time zone in “Milestone” Page.",
  "foreign_shipment": "外站货物",
  "save_link_shipment_number_alarm_text": "%{shipmentNumber} 将自动同步资料至 %{newLinkShipmentNo} ",
  "update_link_shipment_number_alarm_text": "关联已变更，从%{linkedShipmentNumber} 到 %{newLinkShipmentNo}。 %{shipmentNumber} 将自动同步资料至 %{newLinkShipmentNo}",
  "error_code_bulk_update_cant_save": "批量更新储存失败",
  "error_code_fd_status_update_fail": "FD状态更新失败",
  "total_pallets": "托盘总数",
  "loose_cartons": "散箱",
  "chargeable_weight_unit": "计费重量单位",
  "gross_weight_unit": "毛重单位\r",
  "chargable_weight": "计费重量",
  "description_of_good": "货物描述",
  "fd_status": "FD No Status",
  "reference": "Reference",
  "reference_type": "Reference Type",
  "reference_value": "Reference Value",
  "reference_type_value_di_values": "DI 参考值",
  "reference_type_value_in_values": "IN 参考值",
  "reference_type_value_po_values": "PO 参考值",
  "reference_type_value_sr_values": "SR 参考值",
  "reference_type_value_undefined_values": "未定义参考值",
  "reference_type_value_other_values": "其他参考值",
  "reference_type_value_whse_values": "Warehouse 参考值",
  "reference_type_value_cargo_loc_values": "Cargo Location 参考值",
  "reference_type_value_carrier_values": "Carrier 参考值",
  "reference_type_value_mawb_values": "HAWB / MBL 参考值",
  "reference_type_value_hawb_values": "HAWB / HBL 参考值",
  "reference_type_value_vsl_name_values": "Vessel Name 参考值",
  "reference_type_value_asn_values": "ASN 參考值",
  "reference_type_value_bol_ref_values": "BOL 參考值",
  "reference_type_value_cntr_no_values": "Container No 参考值",
  "reference_type_value_cntr_size_values": "Container Size 参考值",
  "all_data": "所有资料",
  "is_linked": "已关联?\r",
  "are_you_sure_you_want_to_activate_this_fd_no": "请确定是否启用此 FD No?",
  "are_you_sure_you_want_to_deactivate_this_fd_no": "请确定是否撤销此 FD No?",
  "linked_shipmentment_number": "已关联的货物号码",
  "estimated_pickup_date": "预计提货",
  "stop_from_template": "站点来自样板",
  "gross_margin_ready": "GM Ready",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "inactive_fd_no": "注销的FD号码: %{fd_number}",
  "copy_from_estimated_date": "从预计到货日复制",
  "the_shipment_is_invalid": "The shipment is invalid",
  "linked_shipment": "Linked Shipment",
  "late_creation_milestone_days": "Late creation is set to be %{milestone} + %{lateCreationDays} days",
  "you_should_keyin_missing_reference": "You should keyin missing reference, missing reference: [ %{missedReference} ]",
  "shipper_address_code": "发货地址代码",
  "consignee_address_code": "收货地址代码",
  "link_shipment_bulk_edit": "关联货物",
  "link_shipment_no_is_not_existed": "关联货物号码不存在",
  "error_code_shipment_id_is_not_exist": "Shipment No Not Found",
  "error_code_shipment_template_id_is_not_exist": "Template No Not Found",
  "link_shipment_no_checked_is_duplicate": "%{shipment_number} 已重复",
  "milestone_at_cannot_be_cleared": "事件时间不能被清除",
  "excel_template_file_is_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "没有留言，将无法取消货物",
  "cancel_shipment": "取消货物",
  "bol_number_list": "BOL 号码",
  "generate_bol_or_sli": "產生 BOL/SLI",
  "you_can_only_select_less_than_100_shipments_at_once": "一次只能选取最多100笔",
  "bol_per_shipment": "BOL per shipment",
  "create_bol_per_shipment": "Create BOL per Shipment",
  "consolidated_bol": "Consolidated BOL",
  "create_consolidated_bol": "Create Consolidated BOL",
  "sli_per_shipment": "SLI per shipment",
  "actual_appointment_date": "Actual Appointment Date",
  "actual_appointment_date_tz": "Actual Appointment Date Timezone",
  "error_code_bol_number_is_not_exist": "BOL號碼找不到",
  "error_code_cancel_bol_number_failed": "取消BOL號碼失敗",
  "choose_the_reference_to_show_on_the_document": "Choose the reference to show on the document",
  "shipment_udpate": "Shipment Udpate",
  "shipment_update": "Shipment Update",
  "delivery_instruction": "运送指示",
  "pickup_instruction": "提货指示",
  "invalid_service_provider_code": "无效的厂商代码",
  "invalid_escort_value": "Invalid Escort Value",
  "invalid_security_level": "无效的安全等级",
  "invalid_service_level": "无效的服务等级",
  "invalid_delivery_method_value": "Invalid Delivery Method Value",
  "invalid_cod_amount_fee_terms_value": "Invalid COD Amount Fee Terms Value",
  "invalid_customer_check_acceptable_value": "Invalid Customer Check Acceptable Value",
  "invalid_freight_charge_terms_value": "Invalid Freight Charge Terms Value",
  "invalid_bol_type_value": "Invalid BOL Type Value",
  "required_pickup_date": "提货时间",
  "required_pickup_date_start": "提货开始时间",
  "required_pickup_date_end": "提货截止时间",
  "required_pickup_must_be_a_range": "要求提货时间须为区间",
  "required_delivery_date": "送货时间",
  "required_delivery_date_start": "送货开始时间",
  "required_delivery_date_end": "送货截止时间",
  "required_delivery_must_be_a_range": "要求送货时间须为区间",
  "carrier_is_not_approved": "Carrier is not approved",
  "bol_service_provider": "服务提供者",
  "generate_files": "产生档案(%{file_length})",
  "check_and_generate_files": "检查及产生档案(%{file_length})",
  "are_you_sure_you_want_to_cancel_the_bol": "确认删除%{bol_number}?",
  "error_code_bol_generate_failed": "產生BOL失敗. %{bol_number}",
  "error_code_bol_validate_failed": "驗證BOL失敗 : %{bol_number}",
  "error_code_consoildated_bol_validate_consignee_id": "不同的收貨人地址無法整合",
  "error_code_consoildated_bol_validate_shipper_id": "不同的發貨人地址無法整合",
  "carrier_pro_number": "Pro No",
  "error_code_bol_no_truck_serivce_type": "No truck service can be used for generating BOL.",
  "un_no_for_dg": "UN No for DG",
  "shipment_special_instruction": "特殊指示",
  "per_shipment": "BOL per shipment",
  "address_and_city": "地址 & 城市",
  "actual_delivery_date": "Actual Delivery Date",
  "escort": "Escort",
  "tracking_device_number": "Tracking Device No",
  "change_you_make_here_will_also_apply_to_all_the_shipments_in_this_bol_no": "这里你的改动将影响此BOL号码底下所有的货物",
  "missing_project_manager": "缺失专案管理人",
  "missing_pr_number": "缺失PR号码",
  "missing_fd_manager": "缺失FD主管",
  "missing_fd_number": "缺失FD号码",
  "timezone_not_found_please_select_a_timezone": "未找到时区。 请选择时区",
  "missing_ref": "缺失参考号码",
  "missing_inv": "缺失发票数量",
  "dg": "危险品",
  "error_code_bol_shipment_status_already_completed": "下列货物号码已经完成递送，请先将其从当前其BOL号码中移除或取消整笔BOL号码",
  "error_code_bol_shipment_already_binded": "下列货物号码已经有关联BOL号码，请先将其从当前其BOL号码中移除或取消整笔BOL号码",
  "dispatch_number_list": "Dispatch 号码",
  "error_code_create_shipment_invalid_pr": "此PR No.已被专案管理人停用，请选择另一个 PR No.或联络您的专案管理人",
  "cannot_proceed_the_bol_generation_shipment_below_are_still_in_draft_status": "无法执行BOL创建。下方货物号码状态仍然在草稿",
  "error_code_bol_shipment_not_found_or_already_unbinded": "此Shipment 已從此BOL移除，或是已不存在",
  "edit_bol_per_shipment": "Edit BOL per Shipment",
  "edit_consolidated_bol": "Edit Consolidated BOL",
  "add_back": "加回去",
  "shipment_dn_have_not_been_assgined": "",
  "cancel_bol_no": "Cancel Bol No %{bol_number}",
  "error_code_bol_already_unbinded_all_shipment": "此BOL單號已取消。其中無任何貨物關聯，請重整您的頁面。此BOL单号已取消。其中无任何货物关联，请重整您的页面。\n\n",
  "removed_successfully": "Removed Successfully",
  "shipment_dns_have_not_been_assigned": "%{shipment_length} shipment/DNs have not been assigned",
  "you_are_about_to_remove_this_bol_no_do_you_want_to_proceed": "你即将删除这张BOL No, 请确定执行",
  "billing_reference_type": "Billing Ref Type",
  "invalid_required_pickup_date_range": "无效的提货日期区间",
  "invalid_required_delivery_date_range": "无效的送货日期区间",
  "invalid_date_range": "无效的日期区间",
  "package_qty": "包装数",
  "bol_type": "BOL Type",
  "this_bol_no_has_delivered_already_do_you_want_to_proceed_the_change": "此BOL号码已送到，你确定要继续执行至修改页面吗?",
  "bol_shipment_packages": "(%{packages} 包裹数)",
  "bol_ship_from": "Ship From *",
  "bol_ship_to": "Ship To *",
  "handling_pieces": "Package Qty",
  "qty_should_not_be_zero": "货件数需大于0",
  "rate_should_not_be_zero": "费率需大于0",
  "error_code_bol_voucher_not_void": "下方货物号码已存在凭单，请先废除凭单再继续执行",
  "error_duplicate_charge_item": "不允许重复的项目",
  "timezone_invalid": "请更正国家和城市栏位",
  "timezone_invalid_title": "地址不正确",
  "product_line": "Product Line",
  "invalid_product_line": "Invalid Product Line",
  "cainiao_download": "Download",
  "cainiao_download_milestone": "Milestone & CCL.",
  "cainiao_download_epam": "e-Pam Customs",
  "cainiao_download_billing": "Billing Report",
  "cainiao_upload": "Upload",
  "cainiao_upload_milestone": "Milestone & CCL.",
  "please_try_again_later": "Please trry again later",
  "customs_clearance_status": "Customs Clear Status",
  "customs_clearance_status_tooltip": "The update will effect all the courier number of this shipment",
  "cainiao_upload_causes_of_incomplete": "causes of incomplete",
  "cainiao_upload_causes_detail_reason_1": "If there is any error in a single record, this recode will not be processed.",
  "cainiao_upload_causes_detail_reason_2": "MAWB No., Bag ID, CN No. is not existed.",
  "cainiao_upload_causes_detail_reason_3": "Invalid date format, should be YYYY/MM/DD hh:mm",
  "cainiao_upload_causes_detail_reason_4": "Wrong date sequence, early → late: customs clear start, customs cleared, OFD, POD",
  "cainiao_upload_error_column_key": "CN#"
 },
 "bk_tmp": {
  "actual_delivery_date": "已送達",
  "actual_pickup_at": "提货日期",
  "address": "Address",
  "amount": "费用",
  "arrived_cfs_at": "抵达目的地集散站",
  "ata": "抵港",
  "atd": "实际离港",
  "attachment": "附件",
  "billing_info": "Billing Information",
  "booking": "Booking",
  "booking_date": "预定",
  "booking_number": "订舱单号码",
  "booking_number_name": "订舱单号码",
  "booking_request_no": "订舱委託单号码",
  "booking_request_number": "订舱委託单号码",
  "booking_status_canceled": "已取消",
  "booking_status_completed": "已完成",
  "booking_status_draft": "草稿",
  "booking_status_in_progress": "处理中",
  "booking_status_submitted": "已送出",
  "brokerag_and_cargo": "附加服务",
  "calculated_by": "计算方式",
  "calculated_by_total": "按总装运量计算",
  "calculated_by_unit": "个别总结",
  "cancel_at_utc": "取消订舱日期",
  "cancel_by": "取消订舱人",
  "cancel_check": "是否确定要取消?",
  "cancel_notify_check": "Morrison 正在处理您的订单，若取消可能会有部分费用发生，我们将尽速确认费用后与您联繫，在此之前您的订单状态仍会维持在处理状态，是否确定取消?",
  "cancelled_at": "取消",
  "cargo_insurance": "Cargo Insurance",
  "cargo_ready_date": "Cargo Ready Date",
  "charge_weight": "收费重量",
  "charge_weight_unit": "收费重量单位",
  "chargeable_weight": "收费重量(Kg)",
  "city": "City",
  "client_name": "Customer Name",
  "code": "Code",
  "column_setting": "栏位设定",
  "comments": "Comments",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc",
  "commodity": "货品",
  "commodity_code": "Commodity Code",
  "commodity_desc": "Commodity Description",
  "complete_date": "货运完成",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "收货方",
  "consignee_address": "收货方地址",
  "consignee_city": "收货方城市",
  "consignee_code": "Consignee Code",
  "consignee_country": "收货方国家/地区",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "收货方",
  "consolidation_document": "Consolidation",
  "contact_name": "Contact Name",
  "container": "货柜",
  "container_number": "货柜号码",
  "container_type": "货柜类型",
  "count": "统计",
  "country": "Country/Area",
  "create_booking": "创建 Booking",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "cubic_meter": "立方米",
  "currency": "货币",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "客户代码/名称",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customer_name": "Customer Name",
  "customized_setting": "客制讯息栏位",
  "customized_table_header": "客制标题",
  "customs_cleared_at": "清关",
  "customs_released_at": "海关放行",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery": "Delivery",
  "delivery_container_number": "Delivery/ Container No",
  "delivery_port": "Delivery Port",
  "department": "Department",
  "dest": "进口站",
  "destination": "进口站",
  "destination_port": "进口站",
  "destination_port_country": "进口站国家/地区",
  "destination_port_full": "Destination Port",
  "details": "详情",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "diff_dest_area_notify": "你选择的收货人国家与目的港不同 ",
  "diff_origin_area_notify": "你选择的托运人国家与始发港不同 ",
  "dimension": "尺寸",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "Direct Shipment，Tracking Number (提單號碼) 會透過 Booking confirmation 回覆您，在此之前您可用 Booking 號碼追蹤處理進度",
  "discharge": "卸货港",
  "do_number": "Document No",
  "doc_released_at": "放单",
  "document_slash_image": "文件 / 圖片",
  "documents": "文件",
  "download_qr_code": "下载手机扫码",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "email": "邮箱",
  "enter_setting_name": "设定名称",
  "estimated_delivery": "预计送货时间",
  "estimated_pickup_at": "提货日期",
  "eta": "预计到港",
  "etd": "预计离港",
  "event_time_local": "事件時間 (當地)",
  "excepton": "异常",
  "expected_eta": "Target Delivery Date",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_pickup_date": "Expected Pickup Date",
  "export": "汇出",
  "fcl": "整柜 (FCL)",
  "fcr_shipment": "FCR Shipment",
  "fd_number": "FD 号码",
  "flight": "航班",
  "flight_from_port": "航班起站",
  "flight_number": "航班号",
  "flight_to_port": "航班终站",
  "freight_payment_term": "Freight Payment Term",
  "freight_term": "付款条款",
  "from": "起运",
  "general": "General",
  "goods_description": "Goods Description",
  "goods_info": "Goods information",
  "goods_type": "Goods Type",
  "gross_weight": "毛重",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "毛重单位",
  "group": "Group",
  "group_by": "显示方式",
  "handling_pieces": "货件数",
  "hawb_doc": "HAWB Doc",
  "height": "高度",
  "housebill_not_found": "找不到该笔提单",
  "housebill_number": "提单号",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "列表中如果没有申请的客户名称请选'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "发票号码",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_effective": "Is Effective",
  "is_hazardous": "DG",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "發票日期",
  "last_status": "最新状态",
  "lcl": "併柜货(LCL)",
  "length": "长度",
  "load": "装货港",
  "loads": "Load",
  "local": "当地",
  "local_time": "当地时间",
  "logistic_handling": "物流操作",
  "loose_goods": "散货",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc",
  "mec_invoice_doc": "MEC Invoice",
  "method": "装运方式",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "收信仅限Morrison One注册email",
  "name": "名称",
  "need_carge": "是否需要货物运输保险?",
  "need_carge_no": "否 - 我已购买保险/我对任何丢失或损坏的货物承担全部责任",
  "need_carge_yes": "是 - 我希望 Morrison 协助购买货物运输保险",
  "no_download_url": "No DownloadUrl !",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document!",
  "no_shipment_tracking": "No Shipment Tracking !",
  "not_sure_right_now": "我现在还不知道",
  "notification_name": "Notification Name",
  "notification_settings": "通知设定",
  "notification_settings_hint": "設定啟用之後,會套用到每筆新Shipment 都發通知",
  "notify": "Notify",
  "notify_group": "Notify Group",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "通知方",
  "notify_when_shipment": "发通知当货态到达",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "实际到达",
  "option_milstone_booking_date": "预定确认",
  "option_milstone_completed_at": "货运完成",
  "option_milstone_etd": "预计出发",
  "order": "Order",
  "origin": "出口站",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "出口站",
  "origin_port_country": "出口站国家/地区",
  "other_docs": "Other Docs",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "others_term": "Others Payment",
  "out_for_delivery_date": "实际外出递送日期",
  "overwrite_confirm": "確認是否要覆蓋....... 待BA補字",
  "package_quantity": "包裹数",
  "packing_list": "Packing List",
  "pallet": "Pallet",
  "parent_id": "Parent Code",
  "part_description": "Part Description",
  "part_number": "Part No",
  "parts": "Parts",
  "per_height": "高 (CM)",
  "per_length": "长 (CM)",
  "per_weight": "宽 (CM)",
  "pick_up": "提货",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "收货地",
  "po_item_no": "PO Item No",
  "po_no": "PO No",
  "po_number": "PO No",
  "pod": "POD 时间",
  "pod_file": "POD 文件",
  "port_of_delivery": "Port of Delivery",
  "port_of_discharge": "卸货港",
  "port_of_landing": "装货港",
  "port_of_receipt": "Port of Receipt",
  "pre_assign": "Morrison 提供之可预先指派的 HAWB/HBL 号码",
  "pre_assign_desc": "请先向 MEC取得预先分配给您的单号，且不不得重複使用相同号码",
  "pre_assign_fill": "I have Morrison provide.",
  "progress": "进度",
  "project": "专案",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "手机扫码快速查单",
  "qty_ea": "Qty(EA)",
  "qty_of_outers": "Uty of Outers",
  "quantity": "数量",
  "query": "查询",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "quotation_status": "Quotation Status",
  "quotation_type": "Quotation Type",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "参考",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "request_brokerage": "是否需要报关服务?",
  "request_brokerage_no": "否 - 我会自行处裡报关事宜",
  "request_brokerage_yes": "是-我希望 Morrison 协助我进行报关",
  "requester": "请求者",
  "result": "结果",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_consignee": "same as consignee",
  "same_as_consignee_check_label": "Delivery as same as Consignee",
  "same_as_notify": "same as notify",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "same_as_shipper": "same as shipper",
  "same_as_shipper_check_label": "Pickup as same as Shipper",
  "save_to_address": "储存到我的地址簿",
  "secure_sales_no": "Secure Sales No.",
  "secure_station": "Secure Station",
  "select_customer_code": "Select Customer Code",
  "select_quotation_number": "Select Quotation Number",
  "send_mail_notification_to": "送email 通知给谁",
  "send_me_pod_file": "寄发 POD 文件",
  "send_notification_from": "寄送通知",
  "send_sms_notification_to": "送SMS通知给谁",
  "sequence": "顺序",
  "service": "Service",
  "service_info": "服务资讯",
  "service_level": "Service Level",
  "service_remark": "服务备注",
  "service_type": "服务类型",
  "ship": "",
  "ship_to": "收货",
  "ship_type": "Ship Type",
  "shipment_by_to": "这是哪个客户的货?",
  "shipment_delay": "貨物延遲",
  "shipment_details": "货件详情",
  "shipment_mode": "运输",
  "shipment_mode_air": "空运",
  "shipment_mode_sea": "海运",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "参考值",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipper": "发货方",
  "shipper_address": "发货方地址",
  "shipper_city": "发货方城市",
  "shipper_code": "Shipper Code",
  "shipper_country": "发货方国家/地区",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "发货方",
  "shipping_instruction": "货运指示",
  "shipping_marks": "Shipping Marks",
  "shipping_mode": "装运方式",
  "sign_by": "签收人",
  "special_instruction": "装运须知",
  "state": "State",
  "status": "状态",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "submit_question": "是否送出订舱委託单?",
  "submitted_at": "Submit At",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "teu": "Teu",
  "to": "收货",
  "total_of_units": "总包裹数",
  "total_packages": "包裹数",
  "total_pallets": "Pallet Qty",
  "total_pieces": "总件数",
  "total_spend": "总费用",
  "total_weight": "总重量",
  "total_weight_kg": "总重量 (Kg)",
  "trace_other_number": "追踪其他提单号码",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "truck_size": "卡车尺寸",
  "truck_type": "车型",
  "type": "类型",
  "unit_weight": "每件毛重",
  "uom": "单位",
  "upload_by": "上传者",
  "upload_date_time": "上传时间",
  "upload_doc": "装运单据",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_details": "详情",
  "uppercase_documents": "文件",
  "uppercase_in_progress": "进行中",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "追踪",
  "uppercase_tracking": "追踪",
  "value": "值",
  "vessel": "航班",
  "vessel_name": "船名",
  "vip_name": "客户",
  "volume_weight": "体积重量",
  "volume_weight_total": "总体积",
  "volumetric_weight": "体积重量",
  "voyage": "航程",
  "weight_kg": "重量 (Kg)",
  "width": "宽",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "货运配送状况",
  "zip_code": "邮递区号",
  "undefined": "",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Service Level Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port_code": "Destination Port Code",
  "shipment": "货物",
  "company_id_error": "Company ID error"
 },
 "packing_station": {
  "l1_creation": "L1 Creation",
  "create_l1": "Create L1",
  "create_l2": "Create L2",
  "create_l3": "Create L3",
  "create_spt": "Create SPT",
  "create_load": "Create Load",
  "l1": "L1",
  "qualified_order": "Qualified Order",
  "verification": "Verification",
  "csm_no": "CSM No",
  "location": "Location",
  "all_special_instruction": "All Special Instruction",
  "create_success": "Create Success",
  "ship_to": "Ship to",
  "ship_to_add": "Ship to add",
  "required": "Required",
  "something_wrong": "Something Wrong !",
  "site": "Site",
  "client": "Client",
  "pack_level": "Pack Level",
  "status": "Status",
  "reference": "Reference",
  "refresh": "Refresh",
  "new": "New",
  "check_your_rule": "Check Your Rule",
  "please_check_ship_to_code_and_pack_level_and_pack_code_have_not_the_same": "Please Check Ship to code、Pack level and Pack code have not the same",
  "status_have_to_confirmed_or_pack_level_have_to_csm": "Status have to Confirmed or Pack Level have to CSM",
  "enter_job_information": "Enter Job Information",
  "do_you_want_to_delete_the_jobs": "Do you want to delete the jobs ? ",
  "ok": "Ok",
  "cancel": "Cancel",
  "delete_success": "Delete Success",
  "do_you_want_to_confirm_the_jobs": "Do you want to confirm the Jobs ?",
  "confirm_success": "Confirm Success",
  "status_have_completed": "Status have Completed",
  "ship_to_code": "Ship To Code",
  "next_step": "Next Step",
  "print_success": "Print Success",
  "close": "Close",
  "job": "Job",
  "ad_hoc_charges": "Ad HOC Charges",
  "save_success": "Save Success",
  "charge_name_is_not_unique": "Charge Name is not unique",
  "name": "Name",
  "description": "Description",
  "remark": "Remark",
  "quantity": "Quantity",
  "cost": "Cost",
  "quotation": "Quotation",
  "create": "Create",
  "update": "Update",
  "do_you_want_to_delete_this_ad_hoc_charges": "Do you want to delete the Ad HOC Charges ?",
  "address": "Address",
  "carrier": "Carrier",
  "special_instruction": "Special Instruction",
  "attachment_list": "Attachment List",
  "update_success": "Update Success",
  "ship_to_info": "Ship To Info",
  "shipper_info": "Shipper Info",
  "ship_from_info": "Ship From Info",
  "attention": "Attention",
  "phone": "Phone",
  "address1": "Address1",
  "address2": "Address2",
  "address3": "Address3",
  "city": "City",
  "state": "State",
  "postal_code": "Postal Code",
  "country": "Country",
  "carrier_code": "Carrier Code",
  "service_code": "Service Code",
  "user_account": "User Account",
  "line_no": "Line No",
  "pack_no": "Pack No",
  "tracking_no": "Tracking No",
  "order_number": "Order Number",
  "pack_job": "Pack Job",
  "load_job": "Load Job",
  "batching_new": "Batching New",
  "job_number": "Job Number",
  "pack_rule": "Pack Rule",
  "load_number": "Load Number",
  "undefined": "",
  "configuration": "Configuration",
  "no": "No",
  "yes": "Yes",
  "consignment_number": "Consignment Number",
  "item_qty": "Item Qty",
  "default_carrier": "Default carrier",
  "empty": "Empty",
  "postcode": "Postcode",
  "sku": "SKU",
  "picking_sn": "Picking SN",
  "packing_sn": "Packing SN",
  "pick_qty": "Pick Qty",
  "total_qty": "Total Qty"
 },
 "devops": {
  "environment": "Environment",
  "branch": "Branch",
  "version": "Version",
  "commit_hash": "Commit Hash",
  "repository": "Repository",
  "created_at": "Created At",
  "created_by": "Created By",
  "ticket_list": "Ticket List",
  "issue_id": "Issue Id",
  "issue_key": "Issue Key",
  "issue_link": "Issue Link",
  "summary": "Summary"
 },
 "shpt": {
  "actual_appointment_date": "实际预约日期",
  "actual_arrive_first_us_ca_dest": "实际抵达美洲进口港时间",
  "actual_arrive_transshipment_port": "实际抵达转运港时间",
  "actual_delivery_date": "实际送达日",
  "actual_depart_port_at": "实际出口站启程日期",
  "actual_depart_transshipment_port": "实际转运港出发时间",
  "actual_departed_port_of_export": "出口港实际出发日期",
  "actual_pickup_date": "实际提货日期",
  "address": "地址",
  "address_in_local_language": "当地语言地址",
  "address_line_one": "地址行 1",
  "address_line_two": "地址行 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "更新地址到收货地址相同且尚未建立送货单的货件",
  "adjust_column": "调整栏位",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "机场",
  "alert": "警示",
  "amat_edi_alert": "此提单号尚未与一个 AMAT ID %{id} 关联. 请检查是否号码输入有误并修正",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "更新到符合条件的货物",
  "appointment_confirmed_date": "预约确认日期",
  "appointment_confirmed_number": "预约确认号码",
  "appointment_made_by": "预约发起人",
  "appointment_made_date": "预约发起日期",
  "appointment_made_method": "预约发起管道",
  "appointment_made_with": "预约发起方式",
  "appointment_number": "预约号码",
  "arrival_city": "抵达城市",
  "arrival_terminal_at": "抵达出口航站日期",
  "arrived_at_cfs": "抵达目的CFS时间",
  "arrived_ata": "到達 (ATA)",
  "arrived_cfs": "到达目的地CFS",
  "asn_number": "ASN 号码",
  "at_risk": "存在风险",
  "ata": "到达 (ATA)",
  "ata_first_amer": "实际抵达美洲进口站日期",
  "ata_first_amer_port": "美洲进口站",
  "ata_route_1_port": "转运港",
  "ata_to_port_arrival": "实际抵达抵达港时间",
  "atd": "出发 (ATD)",
  "attachment": "附件",
  "aw_gw": "实际重/毛重\r",
  "bol_group_rule": "货件合并到送货单(BOL)规则",
  "bol_no": "送货单号",
  "booked": "预定",
  "booking_date": "预定日期",
  "booking_number": "预定号码",
  "brokerage": "报关行",
  "cancelled_at": "取消",
  "cargo_receipt": "收貨日期",
  "cargo_receipt_date": "收货时间",
  "carrier_released": "承运商放货时间",
  "cartons": "箱数\r",
  "category": "分類",
  "cbm": "立方公尺",
  "chargeable_weight": "收费重量(Kg)",
  "checked_ata_and_eta": "更新 ATA：%{ata} 已超过 ETA：%{eta}",
  "checked_atd_and_etd": "更新 ATD：%{atd} 已超过\n ETD：%{etd}",
  "checked_pod_and_edd": "更新 POD：%{pod} 已超过EDD：%{edd}",
  "city": "城市",
  "client_id": "客戶ID",
  "cod_amount": "COD 金额",
  "code": "客户代号",
  "comment": "评论",
  "comment_here": "留下评论",
  "commercial_Inv": "商业发票号码\r",
  "commercial_invoice_number": "商业发票号码",
  "commercial_invoice_numbers": "商业发票号",
  "commercial_Invoice_value": "商业发票金额\r",
  "completed_date": "完成时间",
  "confirm_destination_documents": "确认目的地文件",
  "confirm_origin_documents": "确认始发地文件",
  "confirmed": "已确认",
  "consignee": "收货方",
  "consignee_address": "收货方地址",
  "consignee_city": "收货方代码",
  "consignee_city_name": "收货方城市",
  "consignee_contact": "收货方联系人",
  "consignee_country": "收货方国家/地区",
  "consignee_email": "收货方电子邮件",
  "consignee_id": "收货方ID",
  "consignee_info": "收货资讯",
  "consignee_name": "收货方",
  "consignee_state": "收货方州别",
  "consignee_tax_id": "收货人的税号\r",
  "consignee_telephone": "收货方电话",
  "consignee_zipcode": "收货方邮编",
  "consol_closed_at": "Console Close",
  "contact": "联络人",
  "container_loading_type": "Container Loading Type",
  "container_mode": "货柜模式",
  "container_seal_number": "货柜号码",
  "container_type": "货柜类型",
  "container_type_description": "货柜类型描述",
  "copy_edd_from_export": "复制出口站EDD时间",
  "country": "国家/地区",
  "country_of_origin": "原产地",
  "create_date_utc": "新增日期(UTC)",
  "created_by": "新增人員",
  "cubic_meter": "立方米",
  "currency": "币别\r",
  "custom_cleared": "出口清关日期",
  "customer": "客戶",
  "customer_code": "客户代码",
  "customer_code_name": "客户代码/名称",
  "customer_special_requirement_sla": "客户特殊需求 / SLA",
  "customs_cleared": "出口清关日期",
  "customs_released": "進口清關日期",
  "customs_value": "海关价值\r",
  "customized_shipment_data": "Customized shipment data",
  "customized_shipment_data_select_message": "Please select the report name.",
  "customized_shipment_data_select_hint": "If you couldn’t see the list, please contact Mec Onehelp %{mail} for further permission",
  "cw": "计费重量\r",
  "dangerous_good": "危险品\r",
  "delete": "删除",
  "delivery": "送货",
  "delivery_chargeable_weight": "Delivery 收费重量",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery 毛重",
  "delivery_loose_cartons": "Delivery 散箱",
  "delivery_method": "递送方式",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery 包裹数",
  "delivery_port": "交货港",
  "delivery_port_city": "交货港城市",
  "delivery_port_country": "交货港国别",
  "delivery_total_cartons": "Delivery 箱数",
  "delivery_total_pallets": "Delivery 栈板数量",
  "delivery_total_pieces": "Delivery 件数",
  "delivery_volumetric_weight": "Delivery 体积重量",
  "dep": "部门",
  "department": "部门",
  "department_number": "部门",
  "departure_city": "出发城市",
  "departure_from_shipper_warehouse": "离仓发运日期",
  "description_of_goods": "货物说明\r",
  "dest_custom_released": "进口清关时间",
  "dest_customs_released": "进口站海关放行",
  "destination": "目的地",
  "destination_city": "目的城市",
  "destination_customs_started_at": "目的站报关开始日期",
  "destination_port": "目的站\r",
  "destination_port_city": "目的站城市",
  "destination_port_country": "目的站国别",
  "destination_region": "进口站地区",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "调度单号",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "创建例外事件通知客户?",
  "doc_received_at": "文件接收日期",
  "doc_released": "放单时间",
  "doc_submitted_at": "文件提交日期",
  "doc_to_broker_at": "文件至报关行",
  "document_released": "放单时间",
  "edi_send_date_time_utc": "EDI 發送時間",
  "edi_status_code": "EDI 狀態碼",
  "estimated_delivery_date": "预计送货日期",
  "estimated_delivery_date_sla": "预计送货日期(SLA)",
  "estimated_pickup_at": "预计提货日",
  "eta": "最后预计到达时间",
  "etd": "首次预计出发时间",
  "event_date_and_time_local": "活动时间 (Local)",
  "event_description": "活动描述",
  "event_location": "活动地点",
  "event_routing": "事件与航线",
  "exception": "例外",
  "exceptions": "例外",
  "export": "汇出",
  "export_milestones": "出口",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "鱼类与野生动物\r",
  "freight_term": "付款条款",
  "from": "自",
  "from_tms": "来自 TMS",
  "gross_weight": "毛重",
  "handed_to_carrier": "交付承运人时间",
  "handling_pieces": "件数",
  "hawb": "提单",
  "hawb_detail": "HAWB 详情",
  "hazardous": "有害物",
  "height": "高",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "查无此ID",
  "import": "進口",
  "import_milestones": "進口",
  "incoterm": "国贸条规",
  "input_more_than_the_next_seven_days_is_not_allowed": "不允许输入超过未来七天",
  "instruction": "指示",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "国际关税和税收",
  "is_customs_clearance": "是否需要清关",
  "item": "項目 #",
  "item_quantity": "项目数量",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "最后併装箱关闭日期",
  "length": "长",
  "line_item_class_number": "Class Number",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "散箱",
  "loose_cartons": "散箱数量\r",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "主单号",
  "max_length_two": "最大长度为2",
  "milestone": "里程碑",
  "milestone_utc": "里程碑 (UTC)",
  "milestone_status": "货态时间查询",
  "morrison_one_customer": "Morrison One Customer",
  "name": "客户名称",
  "need_resubmission": "需要重新上传",
  "no": "否",
  "no_select_shipment_list": "无选择需要更新的HAWB",
  "notify_party_address": "通知方地址",
  "notify_party_city": "通知方代码",
  "notify_party_city_name": "通知方城市",
  "notify_party_country": "通知方国家/地区",
  "notify_party_id": "通知方ID(AMAT)",
  "notify_party_name": "通知方",
  "notify_party_state": "通知方州别",
  "notify_party_zipcode": "通知方邮编",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "外出递送时间",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "辦公室",
  "onboard_date": "上船日期",
  "order": "订单 #\r",
  "order_number": "订单编号",
  "orig_port_of_loading": "装运港",
  "origin": "始发地",
  "origin_customs_broker": "出口报关行",
  "origin_customs_cleared": "出口清关日期",
  "origin_pickup_vechicle_registration": "提货人员牌照",
  "origin_pickup_vehicle_driver": "提货司机",
  "hawb_hbl": "HAWB / HBL",
  "orig": "出口站",
  "dest": "进口站",
  "origin_status": "出口站状态",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
  "dest_mawb_mbl": "进口站 (by MAWB / MBL)",
  "dest_status": "进口站状态",
  "dn_sid": "DN/SID",
  "doc_status": "档案状态",
  "origin_pickup_vehicle_registration_plate": "始发站提货卡车牌号",
  "origin_port": "出口站\r",
  "origin_port_city": "出口站城市\r",
  "origin_port_country": "出口站国别\r",
  "out_for_delivery_date": "外出递送",
  "out_of_gauge": "超出规格",
  "pack": "包装 #",
  "package": "包装",
  "package_detail": "包裹细节",
  "package_quantity": "包裹数",
  "packing_info": "包装资讯",
  "part_description": "物料名称",
  "part_number": "料号",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款给承运人",
  "pick_up_date": "实际提货时间",
  "pickup_address": "提货人地址",
  "pickup_contactor": "提货联络人",
  "pickup_info": "提货资讯",
  "pickup_telephone": "提货人电话",
  "pickup_up": "提货",
  "po_item_number": "PO项目编号\r",
  "po_number": "采购订单号码",
  "pod_delivery_date": "实际送达日期",
  "pod_doc_checked": "POD 文件已检核",
  "pod_doc_checked_messages": "您已检查此文件，我们的客户将可以看得到此文件",
  "pod_doc_unchecked_messages": "您已取消检查此文件，我们的客户将无法查看此文件",
  "pod_name": "签收人",
  "pod_signed_by": "签收人",
  "port_of_receipt": "收货港",
  "port_of_receipt_city": "收货港城市",
  "port_of_receipt_country": "收货港国别",
  "preview": "预览",
  "product_characteristic": "产品特征\r",
  "product_type": "产品类型",
  "project_code": "专案代号\r",
  "prolink_export_created_by": "出口站创建者",
  "prolink_import_created_by": "进口站创建者",
  "proof_of_delivery_location": "签收地",
  "quantity": "数量",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "提货准备完成时间",
  "reason": "理由",
  "received_from_airline": "从航空公司收到时间",
  "reference": "参考",
  "reference_no": "参考单号",
  "reference_number": "AMAT 参考号码",
  "reference_type": "参考类型",
  "reference_value_di": "DI 参考值",
  "reference_value_in": "IN 参考值",
  "reference_value_po": "PO 参考值",
  "reference_value_sr": "SR 参考值",
  "reference_value_undefined": "未定义参考值",
  "release_location": "放单地点",
  "release_to": "放单对象\r",
  "release_type": "放单类型",
  "requested_sailing_date": "要求航行日期",
  "required": "必要的",
  "required_arrival_time": "要求到货时间",
  "required_pick_up_time": "要求提货时间",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} 序號",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "卸货港",
  "security_level": "安全级别",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "选择需要更新的HAWB",
  "select_milestones_to_update": "选择需要更新的事件",
  "sequence": "序号",
  "service_level": "服务等级",
  "service_type": "服务类型\r",
  "ship_date": "起运日期",
  "shipment_details": "货件详情",
  "shipment_mode": "Ship Mode",
  "shipment_number": "货物号码",
  "shipment_status": "货物状态",
  "shipment_type": "Shipment Type",
  "shipper": "发货方",
  "shipper_address": "发货方地址",
  "shipper_city": "发货方代码",
  "shipper_city_name": "发货方城市",
  "shipper_contact": "出货方联系人",
  "shipper_country": "发货方国家/地区",
  "shipper_email": "出货方电子邮件",
  "shipper_id": "发货方ID(AMAT)",
  "shipper_name": "发货方",
  "shipper_state": "发货方州别",
  "shipper_tax_id": "出货人的税号\r",
  "shipper_telephone": "出货方电话",
  "shipper_zipcode": "发货方邮编",
  "shipping_instructions": "运输说明",
  "signed_by": "签收人",
  "sla": "服务水准约定",
  "something_wrong": "出錯了 !",
  "special_delivery_instruction": "特殊递送指示",
  "special_instruction": "特殊指示",
  "split_shipment": "拆HAWB\r",
  "state": "州/省",
  "status": "状态",
  "submitted_to_customs": "提交至海关",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "电话",
  "this_vendor_doesnt_have_amat_id": "此供应商没有AMAT ID",
  "this_vendor_doesnt_have_it": "此供应商没有此项目",
  "tianma_edi_alert": "此供应商没有AMAT ID",
  "tianma_id": "天马ID",
  "to": "至",
  "total_carton": "总箱数",
  "total_cartons": "箱子总数",
  "total_gross_weight_kg": "总毛重(KG)",
  "total_net_weight_kg": "总净重(KG)",
  "total_packages": "包裹数",
  "total_pallet": "总托数",
  "total_pallets": "栈板数量\r",
  "total_pieces": "总件数",
  "total_volume": "总体积",
  "transshipment_station": "站点",
  "transshipment_type": "转运类型",
  "type": "Type",
  "unit": "单位",
  "unit_value": "单位价值\r",
  "uom": "测量单位",
  "update_to_address_book": "更新到地址薄",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "上传文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_in_progress": "进行中",
  "urgent": "急件",
  "urgent_delivery": "急件",
  "vessel_name": "船名",
  "volume": "体积",
  "volumetric_factor": "体积因子",
  "volumetric_unit": "体积单位",
  "volumetric_weight": "体积重量",
  "voyage": "航程",
  "vw": "体积重量\r",
  "weight_unit": "重量单位\r",
  "width": "宽",
  "yes": "是",
  "zipcode": "邮编",
  "required_edi": "需要的EDI",
  "edi_invoice": "EDI 发票",
  "edi_milestone": "EDI 事件",
  "edi_inbound": "客户EDI接收",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "请检查",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "Shipment Container Mode 为空，请检查！",
  "document_no_or_commerce_invoice_no": "Document No / Commerce INV No",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "应该和 %{routing_number} 的 %{port} 相同",
  "samsung_document_no": "文件号码",
  "samsung_invoice_no": "发票号码",
  "samsung_document_no_and_invoice_no": "文件号码 / 发票号码",
  "samsung_document_no_placeholder": "使用逗号 (,) 分隔多笔文件号码",
  "destination_pickup_vehicle_driver": "终点站提货司机",
  "destination_pickup_vehicle_registration_plate": "终点站提货卡车牌号",
  "arrival_origin_at": "抵达出口航站日期",
  "departure_from_discharging_port_at": "卸貨港出發",
  "arrived_cfs_at": "抵達目的CFS時間",
  "departure_from_cfs_at": "OFD (Departure from CFS)",
  "samsung_edi_alert": "此提单号尚未与一个 Samsung 调度单号 %{id} 关联. 请检查是否调度单号码输入有误并修正",
  "edi_message_departure_port": "始发地",
  "edi_message_destination_port": "目的地",
  "edi_message_document_date": "文件日期",
  "edi_message_ship_date": "起运日期",
  "edi_message_transportation_type": "运输类型",
  "edi_message_order_type": "订单类型",
  "edi_message_payment_method": "付款方式",
  "edi_message_inco_term": "国贸条规",
  "edi_message_business_type": "业务类型",
  "edi_message_reference_document_number": "参考文件号码",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "包裹数",
  "edi_message_total_pieces": "总件数",
  "edi_message_total_volume": "总体积",
  "edi_message_total_gross_weight": "总毛重",
  "edi_message_total_net_weight": "总净重",
  "edi_message_invoice_number": "发票号码",
  "edi_message_invoice_amount": "发票金额",
  "edi_message_freight_amount": "运费金额",
  "edi_message_shipper_or_sender": "出货方",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "出货方联系人",
  "edi_message_shipper_phone_or_email": "出货方电话/电邮",
  "edi_message_shipper_address": "出货方地址",
  "edi_message_consignee": "收货方",
  "edi_message_consignee_contact": "收货方联系人",
  "edi_message_consignee_phone_or_email": "收货方电话/电邮",
  "edi_message_consignee_address": "收货方地址",
  "edi_message_buyer": "购买方",
  "edi_message_buyer_contact": "购买方联系人",
  "edi_message_buyer_phone_or_email": "购买方电话/电邮",
  "edi_message_buyer_address": "购买方地址",
  "edi_message_packing_detail": "包装细节",
  "edi_message_part_number": "Part 号码",
  "edi_message_part_description": "品项描述",
  "edi_message_packing_quantity": "包裹数",
  "edi_message_pieces_quantity": "件数",
  "edi_message_gross_weight": "毛重",
  "edi_message_net_weight": "净重",
  "edi_message_volume": "体积",
  "edi_message_unit_price_amount": "单价金额",
  "edi_message_other_charge_amount": "其他收费金额",
  "container": "货柜",
  "add_container": "新增货柜",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "bulk_update": "批量更新",
  "bulk_edit_shipment_reference_no": "批量更新Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "创建时间(UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "提货时间",
  "customs_cleared_at": "出口清关",
  "customs_released_at": "进口清关",
  "shipment_not_existed": "Shipment号码不存在",
  "containe_invalidate_char": "存在无效字元",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "Shipment号码重复",
  "reference_bulk_update_upload_memo": "或于表格中贴上资料",
  "reference_bulk_update_upload_memo_description": "(如果選擇新檔案，所有已輸入的資料將會被覆蓋; 換行符號將被取代成逗號)",
  "master_ata": "MAWB - ATA",
  "doc_released_at": "文件释出",
  "ship_to": "Ship To",
  "arrive_forward_gateway": "Arrived at Gateway",
  "depart_forward_gateway": "OFD (Departed from Gateway)",
  "scac": "SCAC",
  "invalid_scac_code": "SCAC Code is incorrect, please check!",
  "invalid_datetime": "format must be YYYY-MM-DD",
  "Invalid_file": "Invalid file. Please upload a .csv file.",
  "Invalid_file_header_name": "Please upload a file with correct header name(s): %{headers}",
  "Invalid_file_lose_main_field": "You need to upload a file with Container No. and HBL# column.",
  "system_source": "系统来源",
  "receive_booking_req_date": "Rcv. Booking Req. Date",
  "cargo_stuffing_date": "Cargo Stuffing Date",
  "arrived_at_cfs_in_origin": "Arrived at CFS in Origin",
  "full_in_gate_in_origin": "Full in Gate in Origin",
  "document_submitted": "Document Submitted",
  "full_outgate": "Full Outgage",
  "arrive_origin_terminal": "Arrive Origin Terminal",
  "arrival_at_origin_port": "Arrival at Origin Port",
  "depart_from_destination_port": "Depart From Dest Port",
  "cargo_arrived_at_terminal": "Cargo Arrived At Terminal",
  "estimated_pickup": "预计提货日",
  "booking_confirmed": "Booking Confirmation(SO Released)",
  "shipping_doc_received_date": "Shipping Docs Received Date",
  "appointment_made": "Appointment of POD Made",
  "estimated_pod": "Estimated Delivery",
  "appointment_confirmed": "Appointment of POD Confirmed",
  "cause": "Cause",
  "customized_cause": "Customized Cause",
  "ship_to_no_data_warning": "Please update Notify Part Info on Prolink/CW1.",
  "apple_fd_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "apple_gar_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "error_code_search_not_found": "Resource is not found!",
  "orig_customs_inspection_type": "Origin Customs Inspection Type",
  "dest_customs_inspection_type": "Dest Customs Inspection Type",
  "customs": "Customs",
  "customer_required": "Customer Required",
  "customer_reference": "参考",
  "report": "Report",
  "report_shipment_status": "Shipment Status",
  "report_forwarder_name": "Forwarder Name",
  "report_lane_id": "Lane ID",
  "report_cargo_type": "Cargo Type",
  "report_good_description": "Good Description",
  "report_gen_size": "Gen Size",
  "report_product_code": "Product Code",
  "report_product_size": "Product Size",
  "report_change_to_low_bed": "Change to Low-Bed",
  "report_air_ride_for_inland": "Air Ride for Inland",
  "report_gw": "gw",
  "report_sheet_qty": "Sheet Qty",
  "report_container_no": "Container No.",
  "report_carrier": "Carrier",
  "report_ex_plant_day": "Ex-Plant Day",
  "report_etd_org_port": "ETD Org Port",
  "report_cargo_delay_days_1": "Cargo Delay Days 1",
  "report_cargo_delay_reason_1": "Cargo Delay Reason 1",
  "report_atd_org_port": "ATD Org Port",
  "report_eta_transit_port": "ETA Transit Port",
  "report_incorrect_docs_received": "Incorrect Docs Received?",
  "report_incorrect_docs_reason": "Incorrect Docs Reason",
  "report_completed_and_correct_custom_clearance_docs_sent_to_broker": "Completed and correct Custom Clearance Docs Sent to Broker",
  "report_cargo_delay_days_2": "Cargo Delay Days 2",
  "report_cargo_delay_reason_2": "Cargo Delay Reason 2",
  "report_ata_transit_port": "ATA Transit Port",
  "report_etd_transit_port": "ETD Transit Port",
  "report_cargo_delay_days_3": "Cargo Delay Days 3",
  "report_cargo_delay_reason_3": "Cargo Delay Reason 3",
  "report_atd_transit_port": "ATD Transit Port",
  "report_eta_gezhouba": "ETA Gezhouba (到达葛洲坝)",
  "report_ata_gezhouba": "ATA Gezhouba (到达葛洲坝)",
  "report_eta_3gorges": "ETA 3gorges (到达三峡)",
  "report_ata_3gorges": "ATA 3gorges (已到达三峡)",
  "report_etd_3gorges": "ETD 3gorges (已过三峡)",
  "report_atd_3gorges": "ATD 3gorges (已过三峡)",
  "report_eta_dest_port": "ETA Dest. Port",
  "report_cargo_delay_days_4": "Cargo Delay Days 4",
  "report_ata_dest_port": "ATA Dest. Port",
  "report_c_c_mode": "C/C Mode",
  "report_customs_declaration_date": "Customs Declaration Date",
  "report_customs_inspection": "Customs Inspection",
  "report_customs_inspection_reason": "Customs Inspection Reason",
  "report_customs_inspection_released": "Customs Inspection Released",
  "report_customs_declaration_sheet_no": "Customs Declaration Sheet No.",
  "report_import_duty": "Import Duty",
  "report_import_vat": "Import VAT",
  "report_vat_or_deposit_release": "VAT or Deposit Release",
  "report_customs_cleared": "Customs Cleared",
  "report_etd_dest_port": "ETD Dest. Port",
  "report_cargo_delay_days_5": "Cargo Delay Days 5",
  "report_atd_dest_port": "ATD Dest. Port",
  "report_eta_final_destination": "ETA Final Destination",
  "report_cargo_delay_days_6": "Cargo Delay Days 6",
  "report_ata_final_destination": "ATA Final Destination",
  "report_empty_equipment_return": "Empty Equipment Return",
  "report_warehouse": "Warehouse",
  "report_demurrage_cost": "Demurrage Cost",
  "report_demurrage_reason": "Demurrage Reason",
  "report_detention_day": "Detention Day",
  "report_detention_cost": "Detention Cost",
  "report_detention_reason": "Detention Reason",
  "report_other_cost": "Other Cost",
  "report_actual_l_t": "Actual L/T",
  "report_required_l_t": "Required L/T",
  "report_plant_tracking": "Plant Tracking#",
  "report_remark": "Remark",
  "report_shipper": "Shipper",
  "report_consignee": "Consignee",
  "report_shipping_mode": "Shipping Mode",
  "report_incoterms": "Incoterms",
  "report_po_number": "PO#",
  "report_commercial_invoice_number": "Inv No.",
  "report_master_number": "MBL#",
  "report_shipment_number": "HBL#",
  "report_loading_port": "Loading Port",
  "report_container_type": "Container Type",
  "report_packing_type": "Packing Type",
  "report_vessel_name": "Vessel name",
  "report_amount": "Amount",
  "report_currency": "Currency",
  "report_completed_and_correct_custom_clearance_docs_received_to_broker": "Completed and Correct Custom Clearance Docs Received by Broker",
  "report_shipment_create_date": "SHIPMENT CREATE DATE",
  "container_not_existed": "Container No. under HBL# can not be found.",
  "duplicate_container": "Container No. under HBL# is duplicate",
  "shipment_not_key_in": "HBL# can not be blank.",
  "container_not_key_in": "Container No can not be blank.",
  "invalidate_datetime": "Format must be YYYY/MM/DD.",
  "report_no_column": "This report no column data",
  "event_routing_actual_pickup_at": "实际提货时间",
  "report_step1_title": "Step 1: General Upload",
  "report_step2_title": "Step 2: Review Result",
  "report_step2_title_desc": "The listed results including blank field will be uploaded. Please make sure all data is correct.",
  "report_msrnt": "MSRNT",
  "report_via": "VIA",
  "invalidate_string": "Format must be string.",
  "invalidate_integer": "Format must be integer",
  "invalidate_float": "Format must be float",
  "Shipment_Tracking_Report": "Shipment Tracking Report",
  "report_cargo_delay_reason_4": "Cargo Delay Reason 4",
  "report_cargo_delay_reason_5": "Cargo Delay Reason 5",
  "report_cargo_delay_reason_6": "Cargo Delay Reason 6",
  "invalidate_number": "Format must be number.",
  "Invalid_excel_file": "Invalid file. Please upload an Excel file (xlsx, xls).",
  "hbl_not_existed": "HBL# 不存在",
  "report_check_first_memo": "Please rectify all the error in step 1 first then you can click \"Update\".",
  "destination_customs_start_at": "Destination Customs Start At",
  "import_customs_release_at": "Import Customs Release At"
 },
 "forwarder": {
  "actual_appointment_date": "实际预约日期",
  "actual_arrive_first_us_ca_dest": "实际抵达美洲进口港时间",
  "actual_arrive_transshipment_port": "实际抵达转运港时间",
  "actual_delivery_date": "實際運送日",
  "actual_depart_port_at": "实际出口站启程日期",
  "actual_depart_transshipment_port": "实际转运港出发时间",
  "actual_departed_port_of_export": "出口港实际出发日期",
  "actual_pickup_date": "实际提货日期",
  "address": "地址",
  "address_in_local_language": "当地语言地址",
  "address_line_one": "地址行 1",
  "address_line_two": "地址行 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "更新地址到收货地址相同且尚未建立送货单的货件",
  "adjust_column": "調整欄位",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "機場",
  "alert": "警示",
  "amat_edi_alert": "此提单号尚未与一个 AMAT ID %{id} 关联. 请检查是否号码输入有误并修正",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "更新到符合条件的货物",
  "appointment_confirmed_date": "预约确认日期",
  "appointment_confirmed_number": "预约确认号码",
  "appointment_made_by": "预约发起人",
  "appointment_made_date": "预约发起日期",
  "appointment_made_method": "预约发起管道",
  "appointment_made_with": "预约发起方式",
  "appointment_number": "预约号码",
  "arrival_city": "抵达城市",
  "arrival_terminal_at": "抵达出口航站日期",
  "arrived_at_cfs": "抵达目的CFS时间",
  "arrived_ata": "到達 (ATA)",
  "arrived_cfs": "到达目的地CFS",
  "asn_number": "ASN 号码",
  "at_risk": " At Risk",
  "ata": "到达 (ATA)",
  "ata_first_amer": "实际抵达美洲进口站日期",
  "ata_first_amer_port": "美洲进口站",
  "ata_route_1_port": "转运港",
  "ata_to_port_arrival": "实际抵达抵达港时间",
  "atd": "出發 (ATD)",
  "attachment": "附件",
  "aw_gw": "实际重/毛重\r",
  "bol_group_rule": "货件合并到送货单(BOL)规则",
  "bol_no": "送货单号",
  "booked": "预定",
  "booking_date": "预定日期",
  "booking_number": "预定号码",
  "brokerage": "報關行",
  "cancel": "取消",
  "cancelled_at": "取消",
  "cargo_receipt": "收貨日期",
  "cargo_receipt_date": "收货时间",
  "carrier_released": "承运商放货时间",
  "cartons": "箱数\r",
  "category": "分類",
  "cbm": "立方公尺",
  "chargeable_weight": "收费重量(Kg)",
  "checked_ata_and_eta": "更新 ATA：%{ata} 已超过 ETA：%{eta}",
  "checked_atd_and_etd": "更新 ATD：%{atd} 已超过\n ETD：%{etd}",
  "checked_pod_and_edd": "更新 POD：%{pod} 已超过EDD：%{edd}",
  "city": "城市",
  "client_id": "客戶ID",
  "cod_amount": "COD 金额",
  "code": "客户代号",
  "comment": "評論",
  "comment_here": "留下評論",
  "comment_category": "评论类别",
  "commercial_Inv": "商业发票号码\r",
  "commercial_invoice_number": "商业发票号码",
  "commercial_invoice_numbers": "商业发票号",
  "commercial_Invoice_value": "商业发票金额\r",
  "completed_date": "完成时间",
  "confirm_destination_documents": "确认目的地文件",
  "confirm_origin_documents": "确认始发地文件",
  "confirmed": "已确认",
  "consignee": "收货方",
  "consignee_address": "收货方地址",
  "consignee_city": "收货方代码",
  "consignee_city_name": "收货方城市",
  "consignee_contact": "收货方联系人",
  "consignee_country": "收货方国家/地区",
  "consignee_email": "收货方电子邮件",
  "consignee_id": "收货方ID(AMAT)",
  "consignee_info": "收货资讯",
  "consignee_name": "收货方",
  "consignee_state": "收货方州别",
  "consignee_tax_id": "收货人的税号\r",
  "consignee_telephone": "收货方电话",
  "consignee_zipcode": "收货方邮编",
  "consol_closed_at": "Console Close",
  "contact": "联络人",
  "container_loading_type": "Container Loading Type",
  "container_mode": "货柜模式",
  "container_seal_number": "货柜号码",
  "container_type": "货柜类型",
  "container_type_description": "货柜类型描述",
  "copy_edd_from_export": "复制出口站EDD时间",
  "country": "国家/地区",
  "country_of_origin": "原产地",
  "create_date_utc": "新增日期(UTC)",
  "created_by": "新增人員",
  "cubic_meter": "立方米",
  "currency": "币别\r",
  "custom_cleared": "出口清关日期",
  "customer": "客户",
  "customer_code": "客户代码",
  "forwarder_code_name": "客户代码/名称",
  "forwarder_name": "货运承揽商",
  "customer_code_name": "客户代码/名称",
  "customer_special_requirement_sla": "客户特殊需求 / SLA",
  "customs_cleared": "出口清关日期",
  "customs_released": "進口清關日期",
  "customs_value": "海关价值\r",
  "cw": "计费重量\r",
  "dangerous_good": "危险品\r",
  "delete": "删除",
  "delivery": "送货",
  "delivery_chargeable_weight": "Delivery 收费重量",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery 毛重",
  "delivery_loose_cartons": "Delivery 散箱",
  "delivery_method": "递送方式",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery 包裹数",
  "delivery_port": "交货港",
  "delivery_port_city": "交货港城市",
  "delivery_port_country": "交货港国别",
  "delivery_total_cartons": "Delivery 箱数",
  "delivery_total_pallets": "Delivery 栈板数量",
  "delivery_total_pieces": "Delivery 件数",
  "delivery_volumetric_weight": "Delivery 体积重量",
  "dep": "部门",
  "department": "部门",
  "department_number": "部门",
  "departure_city": "出发城市",
  "departure_from_shipper_warehouse": "离仓发运日期",
  "description_of_goods": "货物说明\r",
  "dest_custom_released": "进口清关时间",
  "dest_customs_released": "进口站海关放行",
  "destination": "目的地",
  "destination_city": "目的城市",
  "destination_customs_started_at": "目的站报关开始日期",
  "destination_port": "目的站\r",
  "destination_port_city": "目的站城市",
  "destination_port_country": "目的站国别",
  "destination_region": "进口站地区",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "調度單號",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "创建例外事件通知客户?",
  "doc_received_at": "文件接收日期",
  "doc_released": "放单时间",
  "doc_submitted_at": "文件提交日期",
  "doc_to_broker_at": "文件至报关行",
  "document_released": "放单时间",
  "edi_send_date_time_utc": "EDI 發送時間",
  "edi_status_code": "EDI 狀態碼",
  "estimated_delivery_date": "预计送货日期",
  "estimated_delivery_date_sla": "预计送货日期(SLA)",
  "estimated_pickup_at": "预计提货日",
  "eta": "最后预计到达时间",
  "etd": "首次预计出发时间",
  "event_date_and_time_local": "活動時間 (Local)",
  "event_description": "活動描述",
  "event_location": "活動地點",
  "event_routing": "事件与航线",
  "exception": "例外",
  "exceptions": "例外",
  "export": "汇出",
  "export_milestones": "出口",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "鱼类与野生动物\r",
  "freight_term": "付款条款",
  "from": "自",
  "from_tms": "From TMS",
  "gross_weight": "毛重",
  "handed_to_carrier": "交付承运人时间",
  "handling_pieces": "件数",
  "hawb": "提单",
  "hawb_detail": "HAWB 详情",
  "hazardous": "有害物",
  "height": "高",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "查无此ID",
  "import": "進口",
  "import_milestones": "進口",
  "incoterm": "国贸条规",
  "input_more_than_the_next_seven_days_is_not_allowed": "不允許輸入超過未來七天",
  "instruction": "指示",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "国际关税和税收",
  "is_customs_clearance": "是否需要清关",
  "item": "項目 #",
  "item_quantity": "项目数量",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "最后併装箱关闭日期",
  "length": "长",
  "line_item_class_number": "Class Number",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "散箱",
  "loose_cartons": "散箱数量\r",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "主单号",
  "max_length_two": "最大长度为2",
  "milestone": "里程碑",
  "milestone_utc": "里程碑 (UTC)",
  "milestone_status": "货态时间查询",
  "morrison_one_customer": "Morrison One Customer",
  "name": "客户名称",
  "need_resubmission": "需要重新上传",
  "no": "否",
  "no_select_shipment_list": "無选择需要更新的HAWB",
  "notify_party_address": "通知方地址",
  "notify_party_city": "通知方代码",
  "notify_party_city_name": "通知方城市",
  "notify_party_country": "通知方国家/地区",
  "notify_party_id": "通知方ID(AMAT)",
  "notify_party_name": "通知方",
  "notify_party_state": "通知方州别",
  "notify_party_zipcode": "通知方邮编",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "外出递送时间",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "辦公室",
  "onboard_date": "上船日期",
  "order": "订单 #\r",
  "order_number": "订单编号",
  "orig_port_of_loading": "装运港",
  "origin": "始发地",
  "origin_customs_broker": "出口报关行",
  "origin_customs_cleared": "出口清关日期",
  "origin_pickup_vechicle_registration": "提货人员牌照",
  "origin_pickup_vehicle_driver": "提货司机",
  "hawb_hbl": "HAWB / HBL",
  "orig": "出口站",
  "dest": "进口站",
  "orig_region": "出口地區",
  "dest_region": "進口地區",
  "origin_status": "出口站状态",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
  "dest_mawb_mbl": "进口站 (by MAWB / MBL)",
  "dest_status": "进口站状态",
  "dn_sid": "DN/SID",
  "doc_status": "档案状态",
  "origin_pickup_vehicle_registration_plate": "始发站提货卡车牌号",
  "origin_port": "出口站\r",
  "origin_port_city": "出口站城市\r",
  "origin_port_country": "出口站国别\r",
  "out_for_delivery_date": "外出递送",
  "out_of_gauge": "超出规格",
  "pack": "包装 #",
  "package": "包装",
  "package_detail": "包裹细节",
  "package_quantity": "包裹数",
  "packing_info": "包装资讯",
  "part_description": "物料名称",
  "part_number": "料号",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款给承运人",
  "pick_up_date": "实际提货时间",
  "pickup_address": "提货人地址",
  "pickup_contactor": "提货联络人",
  "pickup_info": "提货资讯",
  "pickup_telephone": "提货人电话",
  "pickup_up": "提货",
  "po_item_number": "PO项目编号\r",
  "po_number": "采购订单号码",
  "pod_delivery_date": "签收时间",
  "pod_doc_checked": "POD 已检核",
  "pod_doc_checked_messages": "您已检查此文件，我们的客户将可以看得到此文件",
  "pod_doc_unchecked_messages": "您已取消检查此文件，我们的客户将无法查看此文件",
  "pod_name": "签收者",
  "pod_signed_by": "POD簽名",
  "port_of_receipt": "收货港",
  "port_of_receipt_city": "收货港城市",
  "port_of_receipt_country": "收货港国别",
  "preview": "预览",
  "product_characteristic": "产品特征\r",
  "product_type": "产品类型",
  "project_code": "专案代号\r",
  "prolink_export_created_by": "出口站创建者",
  "prolink_import_created_by": "进口站创建者",
  "proof_of_delivery_location": "签收地",
  "quantity": "数量",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "提货准备完成时间",
  "reason": "理由",
  "received_from_airline": "从航空公司收到时间",
  "reference": "参考",
  "reference_no": "参考单号",
  "reference_number": "AMAT 参考号码",
  "reference_type": "参考类型",
  "reference_value_di": "DI 参考值",
  "reference_value_in": "IN 参考值",
  "reference_value_po": "PO 参考值",
  "reference_value_sr": "SR 参考值",
  "reference_value_undefined": "未定义参考值",
  "release_location": "放单地点",
  "release_to": "放单对象\r",
  "release_type": "放单类型",
  "requested_sailing_date": "要求航行日期",
  "required": "必要的",
  "required_arrival_time": "要求到货时间",
  "required_pick_up_time": "要求提货时间",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} 序號",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "卸货港",
  "security_level": "安全级别",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "选择需要更新的HAWB",
  "select_milestones_to_update": "选择需要更新的事件",
  "sequence": "序號",
  "service_level": "服务等级",
  "service_type": "服务类型\r",
  "ship_date": "起运日期",
  "shipment_details": "货件详情",
  "shipment_mode": "Ship Mode",
  "shipment_number": "货物号码",
  "shipment_status": "货物状态",
  "shipment_type": "Shipment Type",
  "shipper": "发货方",
  "shipper_address": "发货方地址",
  "shipper_city": "发货方代码",
  "shipper_city_name": "发货方城市",
  "shipper_contact": "出货方联系人",
  "shipper_country": "发货方国家/地区",
  "shipper_email": "出货方电子邮件",
  "shipper_id": "发货方ID(AMAT)",
  "shipper_name": "发货方",
  "shipper_state": "发货方州别",
  "shipper_tax_id": "出货人的税号\r",
  "shipper_telephone": "出货方电话",
  "shipper_zipcode": "发货方邮编",
  "shipping_instructions": "运输说明",
  "signed_by": "签收人",
  "sla": "服务水准约定",
  "something_wrong": "出錯了 !",
  "special_delivery_instruction": "特殊递送指示",
  "special_instruction": "特殊指示",
  "split_shipment": "拆HAWB\r",
  "state": "州/省",
  "status": "状态",
  "submitted_to_customs": "提交至海关",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "电话",
  "this_vendor_doesnt_have_amat_id": "此供应商没有AMAT ID",
  "this_vendor_doesnt_have_it": "此供应商没有此项目",
  "tianma_edi_alert": "此供应商没有AMAT ID",
  "tianma_id": "天马ID",
  "to": "至",
  "total_carton": "总箱数",
  "total_cartons": "箱子总数",
  "total_gross_weight_kg": "总毛重(KG)",
  "total_net_weight_kg": "总净重(KG)",
  "total_packages": "包裹数",
  "total_pallet": "总托数",
  "total_pallets": "栈板数量\r",
  "total_pieces": "总件数",
  "total_volume": "总体积",
  "transshipment_station": "站点",
  "transshipment_type": "转运类型",
  "type": "Type",
  "unit": "单位",
  "unit_value": "单位价值\r",
  "uom": "测量单位",
  "update_to_address_book": "更新到地址薄",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "上传文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_in_progress": "进行中",
  "urgent": "急件",
  "urgent_delivery": "急件",
  "vessel_name": "船名",
  "volume": "体积",
  "volumetric_factor": "Volume Factor",
  "volumetric_unit": "体积单位",
  "volumetric_weight": "体积重量",
  "voyage": "航程",
  "vw": "体积重量\r",
  "weight_unit": "重量单位\r",
  "width": "宽",
  "yes": "是",
  "zipcode": "邮编",
  "required_edi": "需要的EDI",
  "edi_invoice": "EDI 发票",
  "edi_milestone": "EDI 事件",
  "edi_inbound": "客户EDI接收",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "请检查",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "Shipment Container Mode 为空，请检查！",
  "document_no_or_commerce_invoice_no": "Document No / Commerce INV No",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "应该和 %{routing_number} 的 %{port} 相同",
  "samsung_document_no": "文件号码",
  "samsung_invoice_no": "发票号码",
  "samsung_document_no_and_invoice_no": "文件号码 / 发票号码",
  "samsung_document_no_placeholder": "使用逗号 (,) 分隔多笔文件号码",
  "destination_pickup_vehicle_driver": "终点站提货司机",
  "destination_pickup_vehicle_registration_plate": "终点站提货卡车牌号",
  "arrival_origin_at": "抵达出口航站日期",
  "departure_from_discharging_port_at": "卸貨港出發",
  "arrived_cfs_at": "抵達目的CFS時間",
  "departure_from_cfs_at": "OFD (Departure from CFS)",
  "samsung_edi_alert": "此提单号尚未与一个 Samsung 调度单号 %{id} 关联. 请检查是否调度单号码输入有误并修正",
  "edi_message_departure_port": "始发地",
  "edi_message_destination_port": "目的地",
  "edi_message_document_date": "文件日期",
  "edi_message_ship_date": "起运日期",
  "edi_message_transportation_type": "运输类型",
  "edi_message_order_type": "订单类型",
  "edi_message_payment_method": "付款方式",
  "edi_message_inco_term": "国贸条规",
  "edi_message_business_type": "业务类型",
  "edi_message_reference_document_number": "参考文件号码",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "包裹数",
  "edi_message_total_pieces": "总件数",
  "edi_message_total_volume": "总体积",
  "edi_message_total_gross_weight": "总毛重",
  "edi_message_total_net_weight": "总净重",
  "edi_message_invoice_number": "发票号码",
  "edi_message_invoice_amount": "发票金额",
  "edi_message_freight_amount": "运费金额",
  "edi_message_shipper_or_sender": "出货方",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "出货方联系人",
  "edi_message_shipper_phone_or_email": "出货方电话/电邮",
  "edi_message_shipper_address": "出货方地址",
  "edi_message_consignee": "收货方",
  "edi_message_consignee_contact": "收货方联系人",
  "edi_message_consignee_phone_or_email": "收货方电话/电邮",
  "edi_message_consignee_address": "收货方地址",
  "edi_message_buyer": "购买方",
  "edi_message_buyer_contact": "购买方联系人",
  "edi_message_buyer_phone_or_email": "购买方电话/电邮",
  "edi_message_buyer_address": "购买方地址",
  "edi_message_packing_detail": "包装细节",
  "edi_message_part_number": "Part 号码",
  "edi_message_part_description": "品项描述",
  "edi_message_packing_quantity": "包裹数",
  "edi_message_pieces_quantity": "件数",
  "edi_message_gross_weight": "毛重",
  "edi_message_net_weight": "净重",
  "edi_message_volume": "体积",
  "edi_message_unit_price_amount": "单价金额",
  "edi_message_other_charge_amount": "其他收费金额",
  "container": "货柜",
  "add_container": "新增货柜",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "so_no": "SO No",
  "bulk_update": "批量更新",
  "bulk_edit_shipment_reference_no": "批量更新Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "创建时间(UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "提货时间",
  "customs_cleared_at": "出口清关",
  "customs_released_at": "进口清关",
  "shipment_not_existed": "Shipment号码不存在",
  "containe_invalidate_char": "存在无效字元",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "Shipment号码重复",
  "reference_bulk_update_upload_memo": "或于表格中贴上资料",
  "reference_bulk_update_upload_memo_description": "(如果選擇新檔案，所有已輸入的資料將會被覆蓋; 換行符號將被取代成逗號)",
  "master_ata": "MAWB - ATA",
  "error_code_forwarder_id_name_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "没有留言，将无法取消货物",
  "back": "返回",
  "are_you_sure_you_want_to_cancel_the_shipment": "确认删除货物?",
  "operating_number": "单号",
  "level": "层级",
  "no_data": "没有资料",
  "pick_up_address_same_as_shipper_address": "提货与发货地址相同",
  "ship_to_address_same_as_consignee_address": "交货与收货地址相同",
  "pick_up": "Pick Up ",
  "ship_to": "Ship To ",
  "customer_name": "Customer Name",
  "custom_value": "Custom Value",
  "commercial_invoice": "Commercial Inv#",
  "comments": "留言",
  "edit_comments": "编辑留言",
  "is_dangerous": "危险品",
  "new_comments": "新增留言"
 },
 "sys": {
  "role_type": "角色种类",
  "role_name": "角色名称",
  "role_id": "角色识别码",
  "system": "系统",
  "delete": "Delete",
  "role_type_function": "Function",
  "role_type_data": "Data",
  "role_type_privilege": "Privilege",
  "are_you_sure_to_delete_the_role": "您确定删除此角色吗？",
  "customer": "客户",
  "owner": "拥有者",
  "client_id": "Client ID",
  "shipper": "托运人",
  "consignee": "收货人",
  "company_id": "使用者公司代码",
  "shipment_mode": "运输方式",
  "origin_port": "起始地",
  "destination_port": "目的地",
  "station": "站点",
  "carrier": "承运人",
  "agent": "代理",
  "export_prolink_db": "Prolink数据库 - 出口",
  "import_prolink_db": "Prolink数据库 - 进口",
  "site": "地点",
  "user_info": "User Information",
  "user_type": "User Type",
  "api_token": "API Token",
  "employee_id": "Employee Id",
  "forwarder_id": "货运承揽商代码",
  "region": "Region",
  "division": "Division",
  "department": "Department",
  "supervisor": "Supervisor",
  "job_title": "Job Title",
  "individual": "Individual",
  "update_logs": "更新日志",
  "system_current_value": "系统目前值",
  "shipment_number": "Shipment 号码",
  "delivery_number": "Delivery 号码",
  "source_system": "来源系统",
  "target_system": "目标系统",
  "shipment_no": "Shipment No",
  "delivery_no": "Delivery No",
  "milestone_name": "Milestone Name",
  "milestone_value": "Milestone Value",
  "updated_at": "更新于",
  "dataflow_process_at": "Dataflow 处理时间",
  "dataflow_scheduler": "Dataflow 调度器",
  "table": "表格",
  "m1_records": "M1 纪录",
  "dataflow_logs_from_ods": "Dataflow Logs (From ODS)",
  "ods_records": "ODS 纪录",
  "dataflow_logs_from_trck": "Dataflow Logs (From TRCK)",
  "rec_seq_no": "REC_SEQ_NO",
  "prolink": "Prolink",
  "datapool": "Datapool",
  "process_at": "处理于",
  "process_status": "处理状态",
  "error_class": "Error Class",
  "quotation_no": "Quotation No",
  "awb_type": "AWB_TYPE",
  "pkg": "PKG",
  "transshipment_type": "Transshipment Type",
  "wb_status": "WB_STATUS",
  "log_id": "Log Id",
  "trck": "TRCK",
  "updated_table": "Updated Table",
  "module": "Module",
  "epic_ticket_number": "Epic Number",
  "ready_to_publish": "Ready to Publish",
  "create_by": "Create By",
  "last_edit_by": "Last Edit By",
  "title": "Title",
  "content": "Content",
  "release_note_config": "Release Note Config",
  "release_date": "Release Date",
  "language": "Language",
  "create_at": "Create At",
  "last_by": "Last By",
  "there_is_no_more_content": "There is No More Content",
  "scroll_down_to_see_more": "Scroll Down to See More",
  "content_has_been_updated": "%{module} Just Updated, Click to Refresh",
  "release_notes": "发布版本通知",
  "role_black_list_alert_message": "注意，若同时在功能上套用两组Policy，而这两组Policy 在同一个维度上同时存在黑名单与白名单，则黑名单设定效果可能不会生效。",
  "publish": "布版至PROD",
  "mark_all_as_read_and_close": "已全部读取且不再显示",
  "system_current_value_mop": "系统目前值 - MOP",
  "system_current_value_ods": "系统目前值 - Prolink (ODS)",
  "system_current_value_apple_trck": "系统目前值 - APPLE TRCK",
  "system_change_logs_mop": "更新日志 - MOP",
  "system_change_logs_apple_trck": "更新日志 - APPLE TRCK",
  "dataflow_sync_logs_ods": "Dataflow Sync Logs - Prolink (ODS) To MOP",
  "dataflow_sync_logs_apple_trck": "Dataflow Sync Logs - APPLE TRCK To MOP",
  "milestone_value_local_time": "Milestone Value (Local Time)",
  "updated_at_utc": "更新于 (UTC)",
  "prolink_code": "Prolink Code",
  "source_db": "Source DB",
  "create_flow": "Flow",
  "revised_source": "Source",
  "flow": "Flow",
  "search_milestone_and_log": "Search milestone and log",
  "search_shipment_and_log": "Search shipment and log",
  "system_current_value_mop_description": "Schema: tracking, Table: latest_milestone",
  "system_current_value_ods_description": "Schema: odsprd, Table: BLSTATUS",
  "system_current_value_apple_trck_description": "Schema: trckprd, Table: hawd_records, dn_shipments",
  "system_change_logs_mop_description": "Schema: tracking, Table: milestone_history",
  "system_change_logs_apple_trck_description": "Schema: trckprd, Table: edi_milestone_logs",
  "dataflow_sync_logs_ods_description": "Schema: data_flow, Table: log_blstatus",
  "dataflow_sync_logs_apple_trck_description": "Schema: data_flow, Table: trck_milestone_log",
  "mop_log_at_utc": "MOP Log At (UTC)",
  "process_message": "Process Message",
  "datapool_created_at_utc": "Datapool At (UTC)",
  "updated_by": "更新者",
  "user_apple_trck": "Apple Trck Information",
  "shipment_dimension_title": "Shipment Dimension",
  "shipment_dimension_description": "Apply on Shipment, Billing, TMS, CFS, Tracking, Comprehensive",
  "master_dimension_title": "Master Dimension",
  "master_dimension_description": "Apply on Master, Routing",
  "user_dimension_title": "User Dimension",
  "user_dimension_description": "Apply on Job weighting, Dashboard, Task",
  "master_origin_port": "MAWB 起始地",
  "master_destination_port": "MAWB 目的地",
  "bamboo_location": "Bamboo 地点",
  "appletrckrole_superuser": "SUPERUSER",
  "appletrckrole_exporter": "EXPORTER",
  "appletrckrole_export_supervisor": "EXPORT_SUPERVISOR",
  "appletrckrole_importer": "IMPORTER",
  "appletrckrole_import_supervisor": "IMPORT_SUPERVISOR",
  "appletrckrole_client": "CLIENT",
  "appletrckrole_finance_and_accounting": "FINANCE_AND_ACCOUNTING",
  "appletrckrole_control_tower": "CONTROL_TOWER",
  "appletrckrole_fd_user": "FD_USER",
  "appletrckrole_fd_importer_user": "FD_IMPORTER_USER",
  "appletrckrole_fd_exporter_user": "FD_EXPORTER_USER",
  "generate_password": "Generate Password",
  "get_token": "Get Token",
  "privilege_role": "Privilege Role",
  "do_you_want_to_send_email_to_user": "Do you want to send email to user?",
  "send_welcome_email_to_user": "Send Welcome Email To User",
  "simulate_login_user": "Simulate Login User",
  "check_privilege": "Check Privilege",
  "privilege_not_found": "Privilege not found",
  "error_role_policy_submit": "Following roles need to set policy",
  "error_role_name_duplicate": "Duplicate role name"
 },
 "task_mgmt": {
  "all__issue_type__count": "总任务数",
  "all_task": "全部任务",
  "all_tasks": "全部任务",
  "assign_at_utc": "指派日(UTC)",
  "assignee": "被指派人",
  "assignee_reason": "指派原因",
  "category": "分類",
  "client_id": "客戶ID",
  "closed_by": "關單人員",
  "complete_invalid_fd_pr_in_apple_trck": "修正错误的FD/PR号码在Apple TRCK",
  "complete_missing_milestone": "完成缺失事件",
  "complete_missing_milestone_in_apple_trck": "填写缺失事件在Apple TRCK",
  "complete_missing_shipment_data": "填写缺失资料",
  "complete_missing_shipment_data_in_prolink": "填写缺失的货物资料在Prolink/CW1",
  "complete_missing_tms_data": "填写缺失的TMS资料",
  "complete_missing_invoice": "填写缺失发票",
  "complete_missing_voucher": "填写缺失凭单",
  "create_valid_quotation_in_prolink": "在Prolink / CW1中创建有效的报价",
  "correct_shipment_data_in_prolink": "在Prolink/CW1中更正货物资料",
  "created_at": "新增日期 (UTC)",
  "created_at_utc": "创建日期(UTC)",
  "created_by": "创建者",
  "days": "天",
  "days_elapsed": "%{days} 经过天数",
  "department": "部门",
  "description": "描述",
  "do_you_want_to_change_task_status_to_exemption": "您是否想要从KPI计算中免除这些任务?",
  "escalation_at_utc": "上報主管日期(UTC)",
  "escalation_owner": "主管",
  "event_date_and_time_local": "事件发生当地时间",
  "event_location": "事件地点",
  "exempted": "KPI 已豁免",
  "exemption": "KPI 豁免",
  "expected_due_date": "期望完成日期",
  "expected_due_date_utc": "期望完成日期(UTC)",
  "general_guidelines": "一般指南",
  "urgent": "紧急",
  "high": "高",
  "hours": "小時",
  "instruction": "指示",
  "is_qa_team_assigned": "已指派QA團隊",
  "issue_type": "问题类型",
  "issue_type_aged_booking": "过时的预定",
  "issue_type_ar_overdue": "应收逾期",
  "issue_type_booking": "预定",
  "issue_type_consol_data": "併装箱资料",
  "issue_type_document": "文件",
  "issue_type_edi_failure": "EDI 失败",
  "issue_type_invoice_data": "發票資料",
  "issue_type_late_billing": "延迟的帐单",
  "issue_type_milestone": "事件",
  "issue_type_voucher_data": "凭单资料",
  "issue_type_quotation": "报价",
  "issue_type_shipment_data": "貨物资料",
  "issue_type_tms_data": "派车资料",
  "issue_type_unclosed_console": "未完成的併装箱",
  "issue_type_tms_milestone": "派车事件",
  "issue_type_tms_document": "派车文件",
  "issue_type_tms_po": "TMS PO",
  "issue_type_log_milestone": "物流事件",
  "issue_type_log_invoice_data": "物流发票号码",
  "issue_type_log_voucher_data": "物流凭單号码",
  "issue_type_log_shipment_data": "物流货物资料",
  "issue_type_log_document": "物流文件",
  "issue_type_booking_request": "订舱委託",
  "leave_a_comment_in_apple_trck": "留言告知最新进度在 Apple TRCK",
  "leave_an_exception": "留言或提交例外",
  "confirm_booking_request": "确认订舱委托",
  "leave_a_comment": "留言",
  "linked_issues": "关联问题",
  "linked_task_number_list": "關聯的任務清單",
  "low": "低",
  "medium": "中",
  "my_task": "我的任务",
  "my_tasks": "我的任务",
  "my_watching": "我的关注",
  "or_specify_who_or_what_is_stopping_you_from_closing_the_task": "或是留下因某人的事项耽搁而无法处理的原因",
  "or_to_leave_your_reason_and_latest_update_by": "或是留下你的原因以及最新进度",
  "overdue": "逾期 (小時)",
  "overdue_tip": "數值",
  "is_overdue": "Is Overdue?",
  "overdue__issue_type__count": "延迟任务数",
  "overdue__overdue__avg": "平均延迟 (小时)",
  "overdue_percentage": "延迟比例 (%)",
  "pending_a_feedback_from": "等待反馈来自",
  "pending": "等待反馈",
  "pending_reason": "等待原因",
  "priority": "优先级",
  "reason_code": "原因",
  "reference_no": "參考單號",
  "reference_number": "参考号码",
  "reference_type": "参考类型",
  "search_for_issues": "搜寻问题",
  "search_for_tags": "搜尋標籤",
  "search_issue": "查询任务",
  "shipment_mode": "運輸方式",
  "shipment_number": "货物号码",
  "specify_your_reason_for_stopping": "說明您停止此Task的原因",
  "specify_your_reason_for_late_billing": "說明您帐务延迟的原因",
  "task_watched": "已关注",
  "watch_task": "关注",
  "start_watching": "开始关注",
  "station": "站点",
  "status": "状态",
  "stop_watching": "停止关注",
  "tags": "标签",
  "target_due_date": "目標完成日",
  "target_due_date_utc": "目標完成日 (UTC)",
  "task_closed_at_utc": "任務关闭時間 (UTC)",
  "task_closed_at": "任務关闭時間 (UTC)",
  "task_detail_general_quidelines_terms": "一般指南:\n1. 被指定人需负责将任务如期内完成关闭\n2. 被指定人在一些情境下无法如期完成任务，请留下你的原因以及最新进度\n3. 将你预期解决的时间更新至目标完成日期\n4. 如有相关联的其他任务，请主动关联任务",
  "task_id": "任务编号",
  "task_status": "任务状态",
  "task_status_action_taken": "已行动",
  "task_status_assigned": "已指派",
  "task_status_closed": "关闭",
  "task_status_in_progress": "处理中",
  "task_status_open": "开立",
  "task_status_reopen": "再次开立",
  "task_status_unassigned": "未指派",
  "this_will_trigger_edi_to_customer": "这将会触发EDI发送给客户",
  "title": "标题",
  "to_close_task_by": "关闭此任务(任务状态每十分钟更新)",
  "Type": "类别",
  "type_number_of_overdue": "输入逾期天数",
  "typing_title_or_task_number_or_reference_no": "输入任务标题, 号码, 参考号码",
  "updated_at_utc": "更新日期 (UTC)",
  "updated_by": "更新人員",
  "upload_documents": "上传文件",
  "upload_documents_in_apple_trck": "上传文件在 Apple TRCK",
  "upload_the_invoice_document_and_check_the_rate_in_apple_trck": "上传正确发票文件并确认费率在Apple TRCK",
  "vip_name": "客户",
  "watcher_list": "關注人員清單",
  "last_comment": "最后评论",
  "delivery_number": "Delivery No",
  "master_number": "主单号",
  "vessel_name_list": "航班号",
  "voyage_number_list": "航次",
  "exemption_true": "是",
  "exemption_false": "否",
  "stakeholder": "等待反饋",
  "stakeholder_true": "有",
  "stakeholder_false": "無",
  "region": "地区",
  "division": "分支",
  "issue_type_wms_consignment_data": "WMS 托运物资料",
  "issue_type_wms_dn_data": "WMS 送货单资料",
  "issue_type_production_support": "Production Support",
  "week_year": "周数",
  "choose_actions": "选择执行动作",
  "exempt_kpi": "KPI 豁免",
  "add_comment": "新增留言",
  "add_pending_reason": "新增暂停原因",
  "add_late_billing_reason": "新增帐款延迟原因",
  "close_task": "关闭任务",
  "reassign": "重新指派",
  "info_to_update_target_due_date": "请更新您的目标完成日期，如果您预期无法如期完成",
  "warn_to_update_target_due_date": "因此任务已逾时，请更新您的目标完成日期",
  "assignee_reason_prolink_created_by_hawb": "提单带单者",
  "assignee_reason_prolink_created_by_booking": "Booking 创建者",
  "assignee_reason_consol_created_by": "Consol 制单者",
  "assignee_reason_op_manager": "OP 主管",
  "assignee_reason_station_head": "站长",
  "assignee_reason_regional_qa": "地区 QA",
  "assignee_reason_assignee_config": "指定指派人",
  "assignee_reason_agent_compensate_prolink_created_by": "代理站",
  "assignee_reason_trck_created_by": "TRCK 创建者",
  "assignee_reason_hard_assign": "重新指派",
  "assignee_reason_tip_prolink_created_by_hawb": "HAWB/HBL Creator",
  "assignee_reason_tip_prolink_created_by_booking": "Booking Creator",
  "assignee_reason_tip_consol_created_by": "Consol Creator",
  "assignee_reason_tip_op_manager": "在 Prolink/CW1 系统尚无带单者, 故指派给部门经理",
  "assignee_reason_tip_station_head": "在 Prolink/CW1 系统尚无带单者,以及部门经理, 故指派给站长",
  "assignee_reason_tip_regional_qa": "在 Prolink/CW1 系统尚无带单者, 部门经理,以及站长 , 故指派给 Regional QA",
  "assignee_reason_tip_assignee_config": "你是该任务种类的预设指定负责人",
  "assignee_reason_tip_agent_compensate_prolink_created_by": "当为 VIP 客户时, 必须负责agent对站事宜, 而你是该站在 Prolink/CW1 系统内该张提单的带单者",
  "assignee_reason_tip_trck_created_by": "你是在 TRCK 系统内该张提单的建立者",
  "assignee_reason_tip_hard_assign": "人为重新指派",
  "upload_documents_bol": "上传 POD 签收文件到送货单(BOL)",
  "confirm_documents": "检核货件(Shipment)的 POD 签收文件",
  "task_created_at (in nth week)": "创建时间(UTC)",
  "add_comments": "新增留言",
  "add_watchers": "新增關注人員",
  "advised_action": "建議動作",
  "aging": "已过",
  "choose_category": "选择类型",
  "choose_subcategory": "选择子类型",
  "late_reason_category": "帐务延迟类型",
  "late_reason_code": "帐务延迟代码",
  "late_reason_subcategory": "帐务延迟子类型",
  "late_reason_subcode": "帐务延迟子代码",
  "can_not_perform_action": "无相关建议动作，无法继续操作任务号码 %{taskNumbers}",
  "selected_task_count": "总任务数: %{count}",
  "you_cannot_close_the_task_without_a_comment": "没有留言，将无法关闭任务",
  "comment": "留言",
  "comment_here": "在此新增留言",
  "option_milstone_task_closed_at": "任務关闭時間 (UTC)",
  "option_milstone_task_created_at": "创建日期(UTC)",
  "option_milstone_task_expected_due_date": "期望完成日期(UTC)",
  "task_expected_due_date": "期望完成日期(UTC)",
  "system_source": "出口系统来源",
  "import_system_source": "进口系统来源",
  "prolink_export_created_by": "Prolink出口创建者(HAWB/HBL)",
  "prolink_import_created_by": "Prolink 进口创建者(HAWB/HBL)",
  "quotation_number": "报价单号",
  "incoterm": "贸易条款",
  "freight_term": "付款条款",
  "origin_port": "始发站",
  "destination_port": "目的站\r",
  "gross_weight": "毛重",
  "package_quantity": "件数",
  "etd": "预计出发时间(ETD)",
  "eta": "预计到达时间(ETA)",
  "atd": "实际出发时间(ATD)",
  "ata": "实际到达时间(ATA)",
  "service_type": "服务类型",
  "service_level": "服务等级",
  "estimated_pickup_at": "预计提货时间",
  "actual_pickup_at": "提货时间",
  "doc_released_at": "放单",
  "customs_released_at": "进口海关放行",
  "arrived_cfs_at": "抵达目的CFS时间",
  "cargo_receipt_at": "收货日期",
  "shipper_id": "发货方代码",
  "shipper_name_1": "发货方名字",
  "payer": "付款方",
  "payer_name": "付款方名字",
  "master_number_2": "2nd MAWB/MBL",
  "lot_number_1": "1st Lot No ",
  "lot_number_2": "2nd Lot No",
  "voyage_name_1": "1st Voyage  Name",
  "voyage_name_2": "2nd Voyage  Name",
  "vessel_name_1": "1st Vessel Name",
  "vessel_name_2": "2nd Vessel Name",
  "out_for_delivery_date": "外出递送",
  "actual_delivery_date": "实际送达日期",
  "consignee_id": "收货方代码",
  "consignee_name_1": "收货方名字",
  "notify_id": "通知方代码",
  "notify_name_1": "通知方名字",
  "inv_vou_number": "发票/凭单号码",
  "inv_vou_trade_type": "发票/凭单种类",
  "inv_vou_bill_to_credit_to": "Debit To/ Vendor",
  "inv_vou_print_at": "发票/凭单列印日期",
  "inv_vou_print_by": "发票/凭单列印者",
  "can_not_perform_comment_action": "无相关建议动作，无法继续操作任务号码",
  "consignee_state": "收货州别",
  "consignee_city": "收货方城市",
  "origin_port_list": "始发站",
  "origin_country": "Origin Country",
  "origin_region": "Origin Region",
  "destination_country": "Dest Country",
  "destination_region": "Dest Region",
  "config_priority": "Config Priority",
  "first_assignee_type": "1st Assignee Type",
  "first_assignee": "1st Assignee",
  "second_assignee_type": "2nd Assignee Type",
  "second_assignee": "2nd Assignee",
  "handling_station": "责任站点",
  "lock": "Lock",
  "unlock": "Unlock",
  "first_assignee_status": "1st Assignee Status",
  "second_assignee_status": "2nd Assignee Status",
  "inactive_assignee_status": "已离职",
  "watcher": "關注人員",
  "cancel": "取消",
  "clone": "复制",
  "name": "Name",
  "error_assignee_config_is_duplicated": "已存在被指派人config",
  "error_assignee_is_not_exists": "被指派人不存在",
  "error_can_not_find_reference_type": "无法找到该 reference type",
  "error_assignee_config_is_not_exists": "被指派人config不存在",
  "error_task_asignee_create": "创建被指派人config时出现错误",
  "error_task_asignee_update": "更新被指派人config时出现错误",
  "error_task_asignee_delete": "删除被指派人config时出现错误",
  "error_task_asignee_lock": "修改Lock状态时出现错误",
  "error_issue_type_must_have_value": "问题类型必须输入值",
  "error_category_must_have_value": "分类必须输入值",
  "error_mode_list_must_have_value": "mode必须输入值",
  "error_handling_station_list_must_have_value": "责任站点必须输入值",
  "error_client_id_list_must_have_value": "client_id必须输入值",
  "error_vip_name_list_must_have_value": "vip_name必须输入值",
  "error_consignee_state_list_must_have_value": "收货州别必须输入值",
  "error_consignee_city_list_must_have_value": "收货方城市必须输入值",
  "error_origin_port_list_must_have_value": "始发站必须输入值",
  "error_origin_country_list_must_have_value": "Origin Country必须输入值",
  "error_origin_region_list_must_have_value": "Origin Region必须输入值",
  "error_dest_port_list_must_have_value": "目的站必须输入值",
  "error_dest_country_list_must_have_value": "Dest Country必须输入值",
  "error_dest_region_list_must_have_value": "Dest Region必须输入值",
  "error_first_assignee_type_must_have_value": "1st Assignee Type必须输入值",
  "error_first_assignee_must_have_value": "1st Assignee必须输入值",
  "error_second_assignee_type_must_have_value": "2nd Assignee Type必须输入值",
  "error_second_assignee_must_have_value": "2nd Assignee必须输入值",
  "error_config_priority_must_have_value": "任务优先级别必须输入值",
  "input_is_limited_to_max_selections": "输入仅限于 %{max} 个选择",
  "lock_assignee_config_cant_be_delete": "锁定的配置无法删除",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未储存的资料将遗失，您确定要离开吗?",
  "customer_code_name": "客户代码/名称",
  "delete_comment_failed": "留言删除失败",
  "assignee_reason_dispatch_created_by": "Dispatch创建者",
  "assignee_reason_invoice_printed_by": "发票列印者",
  "assignee_reason_invoice_created_by": "发票创建者",
  "assignee_reason_loading_plan_generator": "Loading Plan 创建者",
  "assignee_reason_assignee_supervisor": "原指定assignee已離職,  故指派給該員工原直屬主管",
  "assignee_reason_shipment_created_by": "貨物創建者",
  "assignee_reason_tip_dispatch_created_by": "Dispatch创建者",
  "assignee_reason_tip_invoice_printed_by": "发票列印者",
  "assignee_reason_tip_invoice_created_by": "发票创建者",
  "assignee_reason_tip_loading_plan_generator": "Loading Plan 创建者",
  "assignee_reason_tip_assignee_supervisor": "原指定assignee已離職,  故指派給該員工原直屬主管",
  "assignee_reason_tip_shipment_created_by": "Shipment创建者",
  "complete_missing_invoice_in_prolink": "在Prolink填写缺失发票",
  "complete_missing_charge_in_prolink": "在Prolink填写缺失charge",
  "complete_missing_voucher_in_prolink": "在Prolink填写缺失凭单",
  "complete_unconfirm_cost_in_prolink": "在Prolink还有未确认的cost",
  "complete_unprint_invoice_in_prolink": "在Prolink还有未打印发票",
  "issue_type_sentry": "Sentry",
  "issue_type_non_trade_voucher_data": "Non-trade凭单",
  "issue_type_non_trade_invoice_data": "Non-trade发票",
  "origin_iata_region": "Origin IATA Region",
  "destination_iata_region": "Dest IATA Region",
  "customer_name_code": "客户名称/代码",
  "more_fields": "More Fields",
  "comments": "Comments",
  "due_date": "Due Date",
  "complete_job_duty": "请至Job duty页面完成任务",
  "more_fields_header_children": "KPI Exemption, add tags, Reference No…",
  "upload_photo_on_cfs": "请至CFS页面上传照片",
  "issue_type_jobduty_need_action": "Job Duty Need Action",
  "config_id": "Config ID",
  "task_assignee_config_priority_p1": "第一順位",
  "task_assignee_config_priority_p2": "第二順位",
  "task_assignee_config_priority_p3": "第三順位",
  "task_assignee_config_priority_p4": "第四順位",
  "task_assignee_config_priority_p5": "第五順位",
  "task_assignee_config_priority_p6": "第六順位",
  "task_assignee_config_priority_low": "Low",
  "task_assignee_config_priority_medium": "Medium",
  "task_assignee_config_priority_high": "High",
  "task_assignee_type_job_group": "JOB_GROUP",
  "task_assignee_type_email": "EMAIL",
  "task_assignee_group_prolink_import_created_by": "PROLINK_IMPORT_CREATED_BY",
  "task_assignee_group_prolink_export_created_by": "PROLINK_EXPORT_CREATED_BY",
  "task_assignee_group_invoice_created_by": "INVOICE_CREATED_BY",
  "task_assignee_group_console_created_by": "CONSOLE_CREATED_BY",
  "task_assignee_group_consol_created_by": "CONSOL_CREATED_BY",
  "task_assignee_group_invoice_printed_by": "INVOICE_PRINTED_BY",
  "task_assignee_group_booking_created_by": "BOOKING_CREATED_BY",
  "task_assignee_group_dispatch_created_by": "DISPATCH_CREATED_BY",
  "task_assignee_group_loading_plan_generator": "LOADING_PLAN_GENERATOR",
  "task_assignee_group_shipment_created_by": "SHIPMENT_CREATED_BY",
  "task_assignee_group_op_manager": "OP_MANAGER",
  "task_assignee_group_transshipment_source_created_by": "TRANSSHIPMENT_SOURCE_CREATED_BY",
  "is_overdue_true": "是",
  "is_overdue_false": "否",
  "region_apac": "APAC",
  "region_emea": "EMEA",
  "region_amer": "AMER",
  "delete_this_config": "删除此设定：%{id}?",
  "sec_sales": "接单业务员",
  "chargeable_weight": "计费重量",
  "delivery_teu": "Delivery TEU",
  "shipment_teu": "Shipment TEU",
  "reassign_by": "重新指派人",
  "reassign_at_utc": "重新指派日期",
  "any_inactive": "任一人离职",
  "both_active": "1st, 2nd 皆在职",
  "assignee_status": "指派人状态",
  "assignee_status_active": "在职",
  "assignee_status_inactive": "离职",
  "error_late_billing_not_allowed": "This Task is not related to Late Billing, this category is not allowed",
  "search_issue_tooltip_text": "模糊搜寻货物号码, 任务号码, 或参考号码",
  "complete_missing_milestone_ofd_dispatch": "输入派车单(Dispatch)的外出递送时间(OFD Time)",
  "complete_missing_milestone_pod_bol": "输入送货单(BOL)的POD签收时间(POD Time)",
  "complete_missing_tms_data_assign_to_carrier": "指派货件给承运人",
  "complete_missing_tms_data_create_dispatch": "建立派车单(Dispatch)",
  "complete_missing_tms_data_create_po": "建立派车单(Dispatch)的 PO Request",
  "leave_shipment_exception": "至Shipment提交例外",
  "undefined": ""
 },
 "user_registration_mgmt": {
  "active_description": "啟用的客戶",
  "all": "全部",
  "carrier_code": "承運人代碼",
  "client_id": "客戶ID",
  "clients_account_appliction_records": "客戶申請紀錄",
  "company_name": "公司名稱",
  "destination_port": "目的站",
  "disabled_desciption": "使用者已建立，尚未寄送邀請信",
  "invitation": "邀請",
  "origin_port": "出口站",
  "password_rest_description": "重設密碼",
  "request_date": "申請日期",
  "search_key": "搜尋關鍵字",
  "shipment_mode": "運輸方式",
  "site": "地點",
  "station": "站點",
  "submitted_description": "送出申請表",
  "system": "系統",
  "tracking_slash_shipment": "Tracking / Shipment",
  "tracking_slash_spend_report": "Tracking / Spend Report",
  "vip_name": "重要客戶名稱",
  "wait_verified_description": "送出邀請信",
  "your_data_authority": "你的資料權限"
 },
 "wms": {
  "alert_check_customer_pickup_shipments": "This selection including %{shipmentCounts} shipments with “Self pick-up” service type.(WHSP) \nIncluding Consignment: %{shipmentNumbers}.\nPlease change service type to “To Door” type (WHTD) before entering dispatch creation.",
  "alert_check_customer_pickup_before_check_in_counter": "This selection including %{shipmentCounts} shipment(s) with “To port”  service type ( ATA or DTA or PTP or DTP or ATP or PTA) .\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD or PTD) before Assign Carrier.",
  "warning_has_dispatch_shipments": "Warning:\n\nThis selection including %{shipmentCounts} shipments have already had OFD/POD.\nSystem will update OFD & POD timestamps and POD files on these shipments based on TMS .\nIncluding Consignment: %{shipmentNumbers}.\n\nDo you want to proceed DN & Dispatch creation?",
  "bol_details": "送货单(DN)详情",
  "bol_grouping_rule": "货物合并到送货单(DN)规则",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "送货单(DN) %{bol_number} 若已接受报价，删除送货单(DN)将同时取消报价，确认要删除送货单(DN)吗？",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "派车单(Dispatch)%{dispatch_number} 若已接受报价，删除派车单(Dispatch)将同时取消报价，确认要删除送货单（BOL）吗？",
  "dispatch_station": "站点",
  "tracking_device": "追踪器号码",
  "actual_driver_arrival_time": "实际司机到达时间",
  "actual_out_for_delivery_date": "外出递送日期",
  "actual_out_for_delivery_time": "外出递送时间",
  "actual_ship_method": "实际运送方式",
  "advanced_search": "进阶搜寻",
  "arrival_date": "到达日期",
  "arrival_time": "到达时间",
  "arrival_type": "抵达类别",
  "asn": "ASN#",
  "asn_number": "ASN Number",
  "asn_qty": "ASN 数量",
  "assistant_driver_1_name": "第一跟车司机",
  "assistant_driver_2_name": "第二跟车司机",
  "available": "可进行",
  "available_qty": "可用数量",
  "bid_required": "比价",
  "billing_period": "Billing Period",
  "bol_dn_no": "DN No",
  "bol_dn_type": "送货类型",
  "bol_no": "DN No",
  "bol_status": "送货单(DN)状态",
  "bol_status_update": "送货单(DN)状态更新",
  "booking_status": "报价状态",
  "cancelled": "已取消",
  "carrier": "承运人",
  "carrier_service_level": "承运人服务",
  "close_date": "Close Date",
  "commodity": "货品",
  "completed_date": "完成时间",
  "complete_time": "完成日期",
  "confirm_complete": "确认完成",
  "confirm_report": "成果报告",
  "consignment": "Consignment",
  "consignment_detail": "托运物明细",
  "consignment_no": "Consignment No",
  "consignment_pcs": "货物件数",
  "consignment_progress": "货物状态",
  "country": "国家/地区",
  "creation_date": "创建日期",
  "custom_ref": "Cust Ref#",
  "customer": "Customer",
  "customer_name": "客户名称",
  "customer_ref": "客户单号",
  "damage_qty": "不良品数量",
  "date_from": "起始日",
  "date_to": "截止日",
  "day_0_30": "< 30 天",
  "day_121_180": "121 - 180 天",
  "day_181_270": "181 - 270 天",
  "day_271_360": "271 - 360 天",
  "day_31_60": "31 - 60 天",
  "day_360_plus": "> 360 天",
  "day_61_90": "61 - 90 天",
  "day_91_120": "91 - 120 天",
  "delivery_count": "SO数",
  "delivery_date": "送货日期",
  "delivery_from": "要求送达时间（起）",
  "delivery_method": "递送方式",
  "delivery_no": "所以",
  "delivery_to": "（讫）",
  "detail": "Detail",
  "dimensions": "尺寸",
  "dispatch_creation_date": "派车单建立时间",
  "dispatch_delivery_method": "派车单递送方式",
  "dispatch_no": "Dispatch No",
  "dispatch_planned_ofd_date": "计划外出递送时间",
  "dispatch_status": "派车单(Dispatch)状态",
  "dispatch_type": "派车单(Dispatch)类型",
  "dispatchDeliveryMethod": "交付方式",
  "dispatchShipmentMode": "运输方式",
  "dn_carrier": "DN Carrier",
  "dn_carrier_service_level": "实际承运人服务",
  "dn_details": "DN Details",
  "dn_group_rule": "货物合并到送货单(DN) 规则",
  "dn_no": "DN No",
  "dn_status": "DN 状态",
  "document_date": "Document Date",
  "edi_date": "EDI日期",
  "edi_ship_method": "EDI运送方式",
  "edi_time": "EDI时间",
  "error_client_not_provided": "未提供仓库客户",
  "europe_destination_region": "欧盟目的国家/地区",
  "event": "事件",
  "exception_message": "例外信息",
  "fast_delete_booking_status_tips_accepted": "已接受承运人报价，货件从送货单移除将同时取消报价。",
  "fast_delete_booking_status_tips_submitted": "已收到承运人报价，货件从送货单移除将同时取消报价。",
  "fragile": "易碎",
  "gross_weight": "毛重",
  "gw": "总毛重",
  "hawb_bl": "HAWB / BL",
  "hawb_count": "Consignment数",
  "hawb_no": "Consignment No",
  "hazardous": "有害物品",
  "height": "高",
  "inbound_detail": "入库详细信息",
  "inbound_header": "入库",
  "inbound_ref": "进货号码",
  "inbound_scheduled_date": "预计收货日期",
  "inbound_scheduled_time": "预计收货时间",
  "inbound_start_date": "收货起始日",
  "inbound_start_time": "收货起始时间",
  "instruction": "特殊指示",
  "inv_damage": "不良品",
  "inv_expired": "过期",
  "inv_good": "良品",
  "inv_hold": "搁置",
  "inv_quarantine": "检疫",
  "inv_rma": "换货",
  "inv_special": "特殊",
  "length": "长",
  "line_number": "行号",
  "location": "储位",
  "loose_ctn_qty": "散箱数量",
  "notification": "提示",
  "number": "No",
  "ofd_departed_from_warehouse": "OFD(离开仓库)",
  "ofd_time": "外出递送时间",
  "onhold_qty": "搁置数",
  "order": "Order#",
  "order_date": "Order Date",
  "order_info": "订单信息",
  "order_number": "订单编号",
  "order_picked_date": "捡货时间",
  "order_picked_time": "捡货日期",
  "order_qty": "Order QTY",
  "order_start_date": "发货起始日期",
  "order_start_time": "发货起始时间",
  "order_time": "Order Time",
  "order_type": "订单类型",
  "ordered_qty": "预计收货量",
  "orig_line_number": "原始行号",
  "outbound_attachment": "发货附件",
  "outbound_detail": "发货详细",
  "outbound_header": "发货",
  "outbound_package": "发货包装",
  "outbound_sn": "发货序号",
  "package_id": "包装识别号",
  "package_info": "包装信息",
  "package_unit": "包装类型",
  "packed_date": "包装日期",
  "packed_qty": "已包装数量",
  "packed_time": "包装时间",
  "payer_account": "付款人帐号",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款给承运人",
  "pick_qty": "Pick QTY",
  "picked_date": "Picked Date",
  "picked_qty": "已捡数量",
  "picked_time": "Picked Time",
  "pid": "PID",
  "planned_driver_arrival_date": "司机计划到达日期",
  "planned_driver_arrival_time": "司机计划到达时间",
  "planned_out_for_delivery_time": "计划外出递送时间",
  "plt_qty": "栈板数量",
  "po_received_date": "订购单日期",
  "po_received_time": "订购单时间",
  "pod": "POD 时间",
  "pod_date": "POD 日期",
  "pod_file": "POD 文件",
  "preassigned_carrier": "预指派承运人",
  "preassigned_carrier_service_level": "预指派承运人服务",
  "primary_driver_license_no": "驾驶证号",
  "primary_driver_name": "司机",
  "primary_driver_phone_no": "司机电话",
  "print": "打印",
  "print_label": "列印标籤",
  "priority": "优先权",
  "product_desc": "产品名称",
  "product_sku": "产品料号",
  "product_sn": "产品序号",
  "quantity": "数量",
  "query": "查询",
  "query_date": "日期查询",
  "query_ref": "参考",
  "rcv_date": "收货日期",
  "rcv_qty": "RCV QTY",
  "received_date": "收货完成日",
  "received_qty": "实际收货量",
  "received_time": "收货完成时间",
  "reference": "参考讯息",
  "tms": "参考文件",
  "reference_files": "参考文件",
  "reference_no": "参考单号",
  "remark_general": "仓库指示",
  "remark_label": "客户指示",
  "remark_receive": "入库指示",
  "remark_shipping": "发货指示",
  "remark_topic": "备注",
  "require_sn": "需要序号",
  "result": "结果",
  "rma_qty": "换货数",
  "sequence": "顺序",
  "sharp_bols": "DN数",
  "sharp_consignments": "Consignment数",
  "sharp_deliveries": "SO数",
  "sharp_dns": "DN数",
  "sharp_gross_weight": "毛重",
  "sharp_gw": "总重",
  "sharp_packages": "包裹数",
  "sharp_pkgs": "包裹数",
  "sharp_shipments": "Consignment数",
  "ship_from": "起运",
  "ship_mode": "运输方式",
  "ship_to": "收货",
  "ship_to_city": "收货城市",
  "ship_to_country": "Ship To Country",
  "sn": "序号",
  "to_name": "收货人",
  "ship_to_state": "收货州/省",
  "ship_type": "出货类型",
  "shipment_customer": "客戶",
  "shipment_mode": "运输方式",
  "shipment_no": "Consignment No",
  "shipped": "出仓",
  "shipped_date": "出仓日期",
  "shipped_qty": "出仓数",
  "shipped_time": "出仓时间",
  "shipper_account": "托运人帐号",
  "site": "地点",
  "sku": "SKU",
  "so": "SO",
  "so_and_package": "SO & Package",
  "so_no": "发货单号",
  "sorting_completed_date": "分拣完成日",
  "sorting_completed_time": "分拣完成时间",
  "special_delivery_instruction": "特殊递送指示",
  "special_instruction": "特殊指示",
  "station": "站点",
  "status": "状态",
  "summary": "概要",
  "svc_type": "服务类型",
  "tms_shipment_leg": "TMS 送货类型",
  "to_address": "收货地址",
  "to_city": "收貨城市",
  "to_country": "收货国家/地区",
  "to_pack_id": "装箱号",
  "to_state": "收货州/省",
  "total_gross_weight": "总重",
  "total_gw": "毛重合计",
  "total_lines": "总行数",
  "total_packages": "PKG数",
  "total_qty": "总数",
  "tracking_device_number": "追踪器号码",
  "tracking_no": "追踪号码",
  "trailer_no": "拖车号",
  "truck_no": "车牌",
  "truck_type": "车型",
  "type": "Type",
  "uom": "尺寸单位",
  "urgent_delivery": "急件",
  "valuable": "高单价",
  "vip_name": "客户",
  "weight": "重量",
  "weight_unit": "重量单位",
  "width": "宽",
  "will_also_save_fields_confirm": "以下變更欄位也會被存入: %{change_fields}",
  "wms": "两用",
  "wms_clients": "仓库客户",
  "pick_area": "Pick Area",
  "sku_description": "SKU Description",
  "new": "New",
  "verification_code": "验证码",
  "lot_no": "Lot No",
  "line": "Line #",
  "ship_to_name": "Ship-To Name",
  "post_code": "Postcode",
  "customer_line": "Customer Line #",
  "packed": "Packed",
  "aged_stock": "Aged Stock",
  "bonded": "Bonded",
  "damage": "Damage",
  "expired": "Expired",
  "hold": "Hold",
  "inspection": "Inspection",
  "quarantine": "Quarantine",
  "returns": "Returns",
  "write_off": "Write Off",
  "demo_stock": "Demo Stock",
  "good": "Good",
  "manufacture_date": "Manufacture Date",
  "po_no": "Po No",
  "inbound_sn": "Inbound SN",
  "special_remark": "Special Remark",
  "special": "Special",
  "ship_info": "Ship Info",
  "tracking_number": "Tracking Number",
  "custom_po_number": "Customer PO Number",
  "po_number": "PO Number",
  "reference_number": "Reference Number",
  "remarks": "Remarks",
  "edi_acked": "EDI Acked",
  "ibound_tally_sheet": "Ibound Tally Sheet",
  "picking_list": "Picking List",
  "packing_list": "Packing List",
  "customer_code": "Customer Code",
  "client": "客户",
  "portal_query": "Query",
  "portal_result": "Result",
  "portal_type": "Type...",
  "portal_multiple_keyword": "Multiple Keyword",
  "portal_customer": "Customer",
  "portal_wms_client": "WMS Client",
  "portal_status": "Status",
  "portal_available": "Available",
  "portal_in_progress": "In Progress",
  "portal_completed": "Completed",
  "portal_cancelled": "Cancelled",
  "portal_date": "Date",
  "option_milstone_edi_date": "EDI Date",
  "option_milstone_received_date": "Received Date",
  "portal_keyword_search": "Keyword Search",
  "portal_print_its": "Print ITS",
  "portal_please_tick_asn_with_status_in_progress": "Please tick ASN with Status \"In Progress\"",
  "portal_please_tick_order_with_status_in_progress": "Please tick Order with Status \"In Progress\"",
  "portal_edit_remark": "Edit Remark",
  "portal_please_check_asn_to_edit": "Please tick ASN to edit",
  "portal_confirmation_report": "Confirmation Report",
  "portal_please_specify_received_date_in_date_query_fields": "Please specify Received Date in Date Query Fields",
  "portal_cust_ref": "Cust Ref#",
  "portal_arrival_type": "Arrival Type",
  "portal_hawb_bl": "HAWB / BL",
  "portal_edi_date": "订购单日期",
  "portal_arrival_date": "到达日期",
  "portal_received_date": "收货完成日",
  "portal_asn": "ASN#",
  "portal_remark": "备注",
  "portal_so": "SO#",
  "portal_customer_po": "Customer PO#",
  "portal_order_type": "Order Type",
  "portal_ship_to_name": "Ship To Name",
  "portal_country": "Country",
  "portal_total_qty": "Total QTY",
  "portal_order_start_date": "Order Start Date",
  "portal_completed_date": "Completed Date",
  "portal_shipped_date": "Shipped Date",
  "portal_shipped": "Shipped",
  "portal_edi_acked": "EDI Acked",
  "portal_order_number": "Order Number",
  "portal_customer_ref": "Customer Reference",
  "portal_sku": "SKU",
  "portal_serial_number": "Serial Number",
  "portal_tracking_number": "Tracking Number",
  "portal_print_manifest": "Print Manifest",
  "portal_please_tick_order_to_edit": "Please tick order to edit",
  "portal_please_specify_shipped_date_in_date_query_fields": "Please specify shipped date in date query fields",
  "portal_please_input_load_number": "Please Enter Load Number",
  "portal_do_you_want_to_print_this": "Do you want to print this ?",
  "portal_ok": "ok",
  "portal_cancel": "Cancel",
  "portal_print_picking_list": "Print Picking List",
  "portal_no_data": "No Data",
  "portal_invalid_load_number": "Invalid Load Number",
  "portal_miss_options": "Miss Options",
  "portal_fetch_error": "Fetch Error",
  "portal_something_wrong": "Something Wrong",
  "portal_download_error": "Download Error",
  "portal_inbound_detail": "Inbound Detail",
  "portal_site": "Site",
  "portal_reference_no": "Reference No",
  "portal_customer_reference": "Customer Reference",
  "portal_required": "Required",
  "portal_save": "Save",
  "portal_success": "Success",
  "portal_print_success": "Print Success",
  "portal_print_error": "Print Error",
  "portal_inbound_sn": "Inbound SN",
  "portal_po_no": "PO No",
  "portal_receiving_note": "Receiving Note",
  "portal_shipping_instruction": "发货指示",
  "portal_warehouse_instruction": "仓库指示",
  "portal_note_to_customer": "客户指示",
  "portal_line": "Line #",
  "portal_lot_no": "Lot No",
  "portal_asn_qty": "ASN Qty",
  "portal_received_qty": "Received Qty",
  "portal_manufacture_date": "Manufacture Date",
  "portal_require_sn": "Require S/N",
  "portal_pack_id": "Pack ID",
  "portal_serial_no": "Serial No",
  "portal_sku_desc": "SKU Desc",
  "portal_inbound": "Inbound",
  "portal_remark_too_long_and_limit_to_variable_characters": "Remark too long and limit to %{max} characters",
  "portal_task": "Task",
  "portal_pick_order": "Pick Order",
  "portal_total_lines": "总行数",
  "portal_please_enter_picking_list_number": "Please Enter Picking List Number",
  "portal_print_orders": "Print Orders",
  "portal_available_qty": "Available QTY",
  "portal_picked_qty": "Picked QTY",
  "portal_packed_qty": "Packed QTY",
  "portal_marketing_qty": "Marketing QTY",
  "portal_damage_qty": "Damage QTY",
  "portal_order_details": "Order Details",
  "portal_order_info": "Order Info",
  "portal_morrison_asn": "Morrison ASN #",
  "portal_morrison_ord": "Morrison ORD #",
  "portal_morrison_customer_code": "Morrison Customer Code",
  "portal_open_date": "Open Date",
  "portal_ship_date": "Ship Date",
  "portal_ship_to": "Ship To",
  "portal_ship_to_code": "Ship To Code",
  "portal_address": "Address",
  "portal_city": "City",
  "portal_zip": "Zip",
  "portal_tel": "Tel",
  "portal_shipping_info": "Shipping Info",
  "portal_expect_ship_method": "Expect Ship Method",
  "portal_actual_ship_method": "Actual Ship Method",
  "portal_courier_tracking_number": "Courier Tracking Number",
  "portal_shipping_cost": "Shipping Cost",
  "portal_package_count": "Package Count",
  "portal_sold_to": "Sold To",
  "portal_sold_to_code": "Sold To Code",
  "portal_sold_to_name": "Sold To Name",
  "portal_incoterm_one": "Incoterm1",
  "portal_incoterm_two": "Incoterm2",
  "portal_special_instructions": "Special Instructions",
  "portal_priority": "Priority",
  "portal_special_note_message_of_consignee": "Special Note (Message of Consignee)",
  "portal_processing_instructions_message_to_warehouse_operators": "Processing Instructions (Message to Warehouse Operators)",
  "portal_documents": "Documents",
  "portal_order_items": "Order Items",
  "portal_packing_details": "Packing Details",
  "portal_customer_line": "Customer Line #",
  "portal_customer_sku": "Customer SKU",
  "portal_description": "Description",
  "portal_plant_delta": "Plant (Delta)",
  "portal_location_delta": "Location (Delta)",
  "portal_order_qty": "Order Qty",
  "portal_shipped_qty": "Shipped Qty",
  "portal_pack_id_box": "Pack ID (Box #)",
  "portal_courier": "Courier",
  "portal_length_cm": "Length (cm)",
  "portal_width_cm": "Width (cm)",
  "portal_hight_cm": "Hight (cm)",
  "portal_weight_kg": "Weight (kg)",
  "portal_desc": "Desc",
  "portal_qty": "Qty",
  "portal_OFD": "OFD",
  "portal_po": "Po",
  "portal_order_line": "Order Line",
  "portal_order_quantity": "Order Quantity",
  "portal_edi_ship_method": "EDI Ship Method",
  "portal_edi_time": "EDI Time",
  "portal_order_date": "Order Date",
  "portal_order_time": "Order Time",
  "portal_picked_date": "Picked Date",
  "portal_picked_time": "Picked Time",
  "portal_packed_date": "Packed Date",
  "portal_packed_time": "Packed Time",
  "portal_customer_name": "Customer Name",
  "portal_customer_order_no": "Customer Order No",
  "portal_wms_order_no": "WMS Order No",
  "portal_order_comparison": "Order Comparison",
  "portal_update_comparison": "Update Comparison",
  "portal_difference_export": "Difference Export",
  "portal_cust_line": "Cust Line",
  "portal_product": "Product",
  "portal_cust_qty": "Cust QTY",
  "portal_wms_qty": "WMS QTY",
  "portal_category": "Category",
  "portal_wms_line": "WMS Line",
  "portal_please_select_upload_template": "Please select upload template",
  "portal_wrong_template": "Wrong template",
  "portal_no_difference": "No difference",
  "portal_comparing_result": "Comparing Result",
  "portal_upload": "Upload",
  "portal_client": "Client",
  "portal_state": "State",
  "portal_post_code": "Postcode",
  "portal_phone": "Phone",
  "portal_outbound_detail": "Outbound Detail",
  "portal_outbound_package": "Outbound Package",
  "portal_outbound_sn": "Outbound SN",
  "portal_product_sku": "Product SKU",
  "portal_commodity": "Commodity",
  "portal_product_desc": "Product Desc",
  "portal_ordered_qty": "Ordered Qty",
  "portal_package_info": "Package Info",
  "portal_package_id": "Package ID",
  "portal_dimensions": "Dimensions",
  "portal_weight": "Weight",
  "portal_quantity": "Quantity",
  "portal_outbound_attachment": "Outbound Attachment",
  "portal_print_put_back_list": "Print Put Back List",
  "portal_please_enter_customer_order_number": "Please Enter Customer Order Number",
  "portal_wrong_customer_order_number": "Wrong customer order number",
  "portal_wms": "WMS",
  "portal_photo_mgmt_direct_upload": "直接上传",
  "portal_photo_mgmt_save": "Save",
  "portal_photo_mgmt_check": "確認",
  "portal_photo_mgmt_confirm": "確認",
  "portal_photo_mgmt_order_no": "订单号码",
  "portal_photo_mgmt_main_page": "主页面",
  "portal_photo_mgmt_photo_upload": "前往拍照",
  "portal_photo_mgmt_photo_management": "图片管理",
  "portal_photo_mgmt_order": "订单",
  "portal_photo_mgmt_select_site": "选择站点",
  "portal_photo_mgmt_scan_qrcode_or_enter_order_no": "扫描 QR Code 或是输入订单号码",
  "portal_photo_mgmt_select_process": "选择Process",
  "portal_photo_mgmt_please_select_at_least_one_photo": "你至少要选择一张照片才行",
  "portal_photo_mgmt_select_the_photos_you_surely_want_to_upload": "請選擇您確定要上傳的圖片",
  "portal_photo_mgmt_select_all": "全部选择",
  "portal_photo_mgmt_cancel": "取消",
  "portal_photo_mgmt_cancel_all": "全部取消",
  "portal_photo_mgmt_please_input_valid_order_number": "請输入有效的订单号码",
  "portal_photo_mgmt_data_uploaded_failed": "注意！档案资料中发现错误，请修正资料并重新上传",
  "portal_photo_mgmt_take_photo_now": "開始拍照",
  "portal_photo_mgmt_select_source_of_photo": "选择上传照片方式",
  "portal_photo_mgmt_compress_img_before_upload": "上传前压缩相片",
  "portal_photo_mgmt_photo_source_camera": "前往拍照",
  "portal_photo_mgmt_photo_source_device": "从装置选取",
  "portal_photo_mgmt_photo_selection_info": "相片: %{number} / %{total}",
  "portal_photo_mgmt_please_grant_the_permission_for_accessing_webcam": "请授予存取照相机权限",
  "portal_photo_mgmt_check_all_photo": "確認照片",
  "portal_photo_mgmt_enter_order_no": "输入订单号码",
  "portal_photo_mgmt_please_enter_valid_order_no": "請输入有效的订单号码",
  "portal_photo_mgmt_select_the_photos_to_upload": "請選擇要上傳的圖片",
  "portal_photo_mgmt_please_take_a_photo": "你至少要拍一张照片才行",
  "portal_photo_mgmt_enter": "Enter",
  "portal_photo_mgmt_take_photo": "開始拍照",
  "portal_photo_mgmt_check_photos": "確認照片",
  "portal_photo_mgmt_the_maximum_of_photos_taken": "拍照上限为 %{number} 张",
  "portal_photos": "Photos",
  "portal_seq": "Seq",
  "portal_original_received_date": "Original Received Date",
  "portal_inbound_ref_hashtag": "Inbound Ref #",
  "portal_area": "Area",
  "portal_location": "Location",
  "portal_sku_description": "SKU Description",
  "portal_onhold_qty": "Onhold QTY",
  "portal_outbound": "Outbound",
  "portal_remark_receive": "入库指示",
  "portal_photo": "Photo",
  "portal_download_all": "下載全部",
  "portal_upload_max_%{max_length}_ohotos": "Upload (max %{max_length} photos)",
  "portal_ship_type": "Ship Type",
  "portal_asn_received_date": "ASN Received Date",
  "portal_inbound_scheduled_date": "Inbound Scheduled Date",
  "portal_unloading_in_progress_date": "Unloading in Progress Date",
  "portal_sorting_completed_date": "Sorting Completed Date",
  "portal_close_date": "Close Date",
  "portal_reference": "Reference",
  "portal_order_not_exist": "Order not exist",
  "portal_customer_order_number_is_different": "Customer order number is different",
  "portal_order_upload_failed": "Order upload failed",
  "portal_does_not_support_order_comparison": "Does not support order comparison",
  "portal_no_export_data": "No export data",
  "portal_tsmc_po": "TSMC PO",
  "portal_tsmc_asn": "TSMC ASN",
  "portal_tsmc_crate": "TSMC Crate",
  "portal_asn_hashtag": "ASN #",
  "portal_po_hashtag": "PO #",
  "portal_crate_hashtag": "Crate #",
  "portal_wms_po_hashtag": "WMS PO #",
  "portal_receiver_date": "Receiver Date",
  "portal_pid": "PID",
  "portal_remarks": "Remarks",
  "portal_so_hashtag": "SO #",
  "portal_inbound_ref": "Inbound Ref",
  "portal_print_batch_picking_list": "Print Batch Picking List",
  "portal_please_enter_batch_order_number": "Please Enter Batch Order Number",
  "portal_print": "Print",
  "transport_control_invalid_date": "Invalid Date",
  "transport_control_iless_than_other_date": "Less than other date",
  "transport_control_carrier_code": "Carrier Code",
  "transport_control_client": "Client",
  "transport_control_description": "Description",
  "transport_control_printer": "Printer",
  "transport_control_service_level": "Service Level",
  "transport_control_rules": "Transport Control Rules",
  "transport_control_printer_mapping": "Printer Mapping",
  "transport_control_printer_setup": "Printer Setup",
  "transport_control_mail_list": "Mail List",
  "transport_control_query": "Query",
  "transport_control_create_success": "Create Success",
  "transport_control_add": "Add",
  "transport_control_destroy_success": "Destroy Success",
  "transport_control_delete": "Delete",
  "transport_control_add_notification": "Add Notification",
  "transport_control_mail": "Mail",
  "transport_control_add_printer_mapping": "Add Printer Mapping",
  "transport_control_add_printer_setup": "Add Printer Setup",
  "transport_control_printer_name": "Printer Name",
  "transport_control_agent_host_key": "Agent Host Key",
  "transport_control_ip_address": "IP Adress",
  "transport_control_port": "Port",
  "transport_control_add_rule": "Add Rule",
  "transport_control_customer_account": "Customer Account",
  "transport_control_configuration": "Configuration",
  "transport_control_less_than_pickup_datetime": "Less than Pickup Datetime",
  "transport_control_less_than_booking_datetime": "Less than Booking Datetime",
  "transport_control_less_than_now": "Less than Now",
  "transport_control_ofd_datetime": "OFD",
  "transport_control_print_order": "Print Order",
  "transport_control_clients": "Clients",
  "transport_control_transport_types": "Transport Types",
  "transport_control_categories": "Categories",
  "transport_control_statuses": "Statuses",
  "transport_control_order_numbers": "Order Numbers",
  "transport_control_ofd_was_filled": "OFD was filled",
  "transport_control_pickup_datetime": "Pickup Datetime",
  "transport_control_booking_datetime": "Booking Datetime",
  "transport_control_no_selected_orders": "No selected orders",
  "transport_control_send_to_booking": "Send to booking",
  "transport_control_send_to_klairy": "Send to Klairy",
  "transport_control_close": "Close",
  "transport_control_update_success": "Update success",
  "transport_control_order_number": "Order Number",
  "transport_control_rollback": "Rollback",
  "transport_control_transport_type": "Transport Type",
  "transport_control_save": "Save",
  "transport_control_trucking_company": "Trucking Company",
  "transport_control_contact_name": "Contact Name",
  "transport_control_address": "Address",
  "transport_control_post_code": "Post Code",
  "transport_control_country": "Country",
  "transport_control_change_to_self_pickup": "Change to Self-pickup",
  "transport_control_courier": "Courier",
  "transport_control_service_type": "Service Type",
  "transport_control_attachment": "Attchment",
  "transport_control_commercial_invoice": "Commercial Invoice",
  "transport_control_export_document": "Export Document",
  "transport_control_total_parcels": "Total Parcels",
  "transport_control_pk_value": "PK Value",
  "transport_control_incoterms": "Incoterms",
  "transport_control_coo": "COO",
  "transport_control_change_to_parcel": "Change to Parcel",
  "transport_control_change_to_pallet": "Change to Pallet",
  "transport_control_host_key": "Host Key",
  "transport_control_status": "Status",
  "transport_control_remark": "Remark",
  "transport_control_pkg_qty": "PKG Qty",
  "transport_control_pack_type": "Pack Type",
  "transport_control_total_gross_weight": "Total Gross Weight",
  "transport_control_has_attachments": "Has Attachments",
  "transport_control_total_pk_count": "Total PK Count",
  "transport_control_total_pk_value": "Total PK Value",
  "transport_control_category": "Category",
  "transport_control_delete_success": "Delete Success",
  "transport_control_printer_document_type": "Printer Document Type",
  "transport_control_printer_location": "Printer Location",
  "transport_control_city": "City",
  "transport_control_tracking_no": "Tracking No.",
  "transport_control_tracking_label": "Tracking Label",
  "undefined": ""
 },
 "kpi": {
  "kpi_owner": "KPI Owner",
  "kpi_owner_department": "KPI Owner Department",
  "kpi_owner_division": "KPI Owner Division",
  "kpi_owner_station": "KPI Owner Station",
  "kpi_owner_region": "KPI Owner Region",
  "kpi_owner_reason": "KPI Owner Reason",
  "kpi_name": "KPI Name",
  "kpi_detail": "KPI Detail",
  "kpi_result": "KPI Result",
  "kpi_review_period": "KPI review Period",
  "kpi_date": "KPI Date",
  "kpi_ref_number": "KPI Ref Number",
  "vip_name": "VIP Name",
  "client_id": "Customer ID",
  "kpi_ref_department": "KPI Ref Department",
  "kpi_ref_station": "KPI Ref Station",
  "kpi_ref_region": "KPI Ref Region",
  "kpi_date_review_period": "Review Period",
  "kpi_date_datetime": "Date Time",
  "kpi_group": "KPI Group",
  "kpi_exemption_flag": "Exemption Flag",
  "kpi_exemption_reason": "Exemption Reason",
  "kpi_number": "KPI Amount USD",
  "kpi_reference_type": "KPI Reference Type",
  "kpi_reference_number": "KPI Reference Number",
  "kpi_reference_station": "KPI Reference Station",
  "kpi_reference_department": "KPI Reference Department",
  "kpi_reference_region": "KPI Reference Region",
  "kpi_reference_vip_name": "KPI Reference VIP Name",
  "kpi_reference_client_id": "KPI Reference Client ID",
  "LATE_INVOICE": "Late Invoice",
  "LATE_INVOICE_OVER_60D": "Late Invoice Over 60 Days",
  "LATE_VOUCHER": "Late Voucher",
  "LATE_VOUCHER_OVER_60D": "Late Voucher Over 60 Days",
  "LATE_MILESTONE": "Late Milestone",
  "MISSING_MILESTONE": "MIssing Milestone",
  "TASK_OVERDUE": "Task Overdue",
  "INCOMPLETE_TASK": "Incomplete Task",
  "invalid_format": "Invalid Format",
  "undefined": "",
  "region": "Region",
  "department": "Department",
  "division": "Division",
  "owner": "Owner",
  "station": "Station",
  "year_week": "Year Week",
  "pass_count": "Pass Count",
  "failed_count": "Failed Count",
  "total_count": "Total Count",
  "failed_percentage": "Failed (%)",
  "target_percentage": "Target (%)",
  "amount": "Amount",
  "overdue_percentage": "Overdue (%)",
  "overdue_count": "Overdue Count",
  "assignee": "Asignee (email)",
  "kpi_date_review_period_2022h1": "2022 H1",
  "kpi_date_review_period_2022h2": "2022 H2",
  "kpi_date_review_period_2021h1": "2021 H1",
  "kpi_date_review_period_2021h2": "2021 H2",
  "kpi_date_review_period_2020h1": "2020 H1",
  "kpi_date_review_period_2020h2": "2020 H2",
  "pass_percentage": "Pass (%)",
  "customer_code": "客户代码",
  "bill_to_id": "Bill-To Code",
  "origin_port": "Orig",
  "origin_port_country": "Orig Country",
  "destination_port": "Dest",
  "destination_port_country": "Dest Country",
  "created_by": "Created By",
  "requested_by": "Requested By",
  "kpi_exception_type": "KPI Exception Type",
  "effective_date": "Effective Date",
  "exception_service_type": "Exception Service Type",
  "shipment_number": "HAWB / HBL",
  "extension_days": "Day(s)",
  "extension_days_required": "Days*",
  "effective_start": "Effective From (Local Time)",
  "effective_end": "Effective To (Local Time)",
  "kpi_exception_rule_exemption": "Exemption",
  "kpi_exception_rule_extension": "Extension",
  "deactivate": "撤销",
  "activate": "啟用",
  "error_data_is_duplicated": "",
  "error_input_value_in_enum_attribute": "",
  "exception_type": "Exception Type",
  "mass_upload": "Mass Upload",
  "exception_service_type_required": "Exception Service Type*",
  "exemption_type": "Exemption Type*",
  "master_number": "MAWB / MBL",
  "quotation_number": "Quotation No",
  "charge_code": "Charge Code",
  "exception_invoice_number": "Exception Invoice Number",
  "remark": "Remark*",
  "requester": "Requester*",
  "invalid_service_type": "无效的服务类型",
  "invalid_exception_service_type": "Invalid Exception Service Type",
  "invalid_exemption_type": "Invalid Exemption Type",
  "invalid_department": "Invalid Department",
  "invalid_extension_type": "Invalid Extension Type",
  "invalid_incoterm": "Invalid Incoterm",
  "invalid_requester": "Invalid Requester",
  "extension_type": "Extension Type*",
  "extension_type_first_bill": "FIrst Bill",
  "extension_type_second_bill": "Second Bill",
  "extension_type_all_bill": "All Billing",
  "extension_type_holiday": "Holiday"
 },
 "hrs": {
  "undefined": "",
  "division": "部门",
  "need_action": "新进人员 / 职务调动",
  "name": "姓名",
  "station": "站点",
  "status": "状态",
  "region": "区域",
  "download_template": "档案下载",
  "check": "check",
  "employee_id": "员工编号",
  "update_detail_description": "你有填写Other main task 的百分比，请再填入相对应的detail描述才能正确储存资料",
  "update_detail_total_percentage": "储存失败，每一位员工的 total (%) 必须要是 100%，请确认%{id}员工的资料",
  "update_error_no_employee_id": "Missing employee ID, please check your excel",
  "update_error_no_review_start_date": "Missing review period, please check your excel",
  "update_error_employee_id_and_review_start_date_not_created_yet": "The employee ID or review period is incorrect",
  "update_error_illegal_percentage": "不正确的百分比数字，请确认  ID %{id} 员工的资料",
  "unknown_error_on_row": "发生未知错误，请联络MOP help desk",
  "is_confirmed": "确认状态",
  "review_period": "资料区间",
  "bulk_confirm": "批次确认",
  "please_select_at_least_one_profile": "请勾选至少一笔资料",
  "import": "汇入",
  "import_failed": "注意！档案资料中发现错误，请修正资料并重新上传",
  "import_sheet_with_illegal_header": "你所上传的Excel有变更到header栏位 : 栏位%{cellname} 应该是 \"%{expect}\" 但檔案中是 \"%{actual}\"   ",
  "update_data_completed_message": "保存完成。您已更新 %{number} 个员工的数据。 ID：${update_id_list}",
  "bulk_confirm_count": "目前已勾选 %{number} 个项目",
  "before_bulk_confirm_title": "是否变更 Confirm 状态为 Y",
  "before_bulk_confirm_message": "目前已勾选 %{number} 个项目，是否将 Confirm 状态变更为 Y ?",
  "NEW_HIRED": "新进员工",
  "JOB_CHANGE": "职位异动 / 部门异动",
  "before_save_confirm_title": "储存已编辑的资料",
  "before_save_confirm_message": "你编辑了 %{number} 位职员的 Job weighting，是否进行资料储存？",
  "before_save_confirm_sync_bulk_confirm_field": "同步变更 confirm 状态为 Y",
  "after_save_confirm_title": "储存成功",
  "after_save_confirm_message": "成功储存资料",
  "after_save_confirm_and_sync_confirm_field_message": "成功储存资料且更新 Confirm 状态",
  "nothing_changed": "无资料变更, 不用存档",
  "you_have_select_number_of_data": "您已选取 %{number} 笔资料",
  "error_no_data_selected": "没有选取任何资料",
  "error_data_no_review_period": "上传的员工资料不存在目前的的 Review period 中，请确认。 ID: %{id} 以及 review period",
  "are_you_sure_to_confirm_these_employee_data": "Confirmed 状态变更后就无法更改，是否将 Confirmed 状态变更为Ｙ，%{employee_list}",
  "location": "地点",
  "is_abnormal": "异常",
  "detail_total_percentage_warning": "Biggest Job Weighting is in \"%{topDuty}\", but this employee is in \"%{division}\"",
  "all_record_will_not_save": "所有输入皆未储存，请修改错误资料后再次储存。",
  "review_period_hint": "\"资料区间\"有修改, 请记得重新\"搜寻\"",
  "handover_submit_error_ofd_earlier_than_adat": "OFD Time is earlier than Actual Driver Arrival Time, please double check and input",
  "handover_submit_error_ofd_later_than_current": "OFD Time exceed the current time, please double check and input",
  "handover_submit_error_ofd_later_than_pod": "POD Time is earlier than the OFD time, please double check and input",
  "handover_submit_error_pod_later_than_current": "POD Time exceed the current time, please double check and input"
 },
 "sop_mgmt": {
  "sop_category": "SOP类别",
  "vip_name": "VIP名称",
  "sop_name": "SOP名称",
  "ship_mode": "运输模式",
  "sop_station": "站点",
  "sop_station_type_billing_station": "Billing Station",
  "sop_station_type_handling_station": "Handling Station",
  "sop_station_type_export_station": "Export Station",
  "sop_station_type_import_station": "Import Station",
  "sop_station_type_control_tower": "Control Tower​",
  "sop_station_type_trans_ship_station": "Trans-ship Station​",
  "step_one": "步骤 1",
  "step_two": "步骤 2",
  "step_three": "步骤 3",
  "process_category": "流程类别",
  "version": "版本",
  "valid_from": "生效日",
  "valid_to": "到期日",
  "customer_type": "客户",
  "instruction": "说明",
  "url": "URL",
  "version_tooltip": "不可小于原有版号",
  "sop_step_one_header_description": "设定SOP类别信息",
  "sop_step_two_header_description": "决定适用站点",
  "sop_step_three_header_description": "选择一或多个档案",
  "sop_publish": "发布",
  "sop_number": "SOP编号",
  "sop_status": "文件状态",
  "validity": "文件效力",
  "validity_valid": "生效",
  "validity_invalid": "失效",
  "sop_status_draft": "Draft",
  "sop_status_approved": "已核准",
  "sop_status_canceled": "已取消",
  "attachment_list": "档案",
  "invalid_version": "无效版本",
  "fuzzy_search": "模糊搜寻",
  "export_station_list": "Export",
  "import_station_list": "Import",
  "handling_station_list": "Handling",
  "billing_station_list": "Billing",
  "trans_ship_station_list": "Trans-Ship",
  "control_tower_list": "Control Tower",
  "file_duplicate": "档名重复",
  "are_you_sure_want_to_delete": "确认删除这份SOP?",
  "sop_category_placeholder": "单选",
  "sop_name_placeholder": "请输入SOP名称",
  "process_category_placeholder": "多选",
  "version_placeholder": "ex. 1.0",
  "instruction_placeholder": "其他说明或操作指南",
  "url_placeholder": "请填入影片网址",
  "sop_number_placeholder": "ex. SP0001",
  "sop_category_customer": "客户SOP",
  "sop_category_standard": "标准SOP",
  "sop_category_policy": "政策",
  "sop_category_guideline": "准則",
  "ship_mode_placeholder": "多选",
  "stations_placeholder": "搜寻...",
  "vip_name_placeholder": "搜寻...",
  "customer_type_placeholder": "搜寻...",
  "edit_attachment_list": "档案",
  "standard_process_category_standard_quotation_mgmt": "Standard Quotation Mgmt",
  "standard_process_category_export_operation_mgmt": "Export Operation Mgmt",
  "standard_process_category_export_booking_handling": "Export Booking Handling",
  "standard_process_category_export_consol_processing": "Export Consol Processing",
  "standard_process_category_export_awb_or_bl_processing": "Export AWB/ BL Processing",
  "standard_process_category_export_others": "Export Others",
  "standard_process_category_import_operation_mgmt": "Import Operation Mgmt",
  "standard_process_category_import_consol_handling": "Import Consol Handling",
  "standard_process_category_import_awb_or_bl_processing": "Import AWB/ BL Processing",
  "standard_process_category_import_dispatch_and_pod": "Import Dispatch & POD",
  "standard_process_category_import_other_main_task": "Import Others",
  "standard_process_category_logistics_operation_mgmt": "Logistics Operation Mgmt",
  "standard_process_category_logistics_customer_service_mgmt": "Logistics Customer Service Mgmt",
  "standard_process_category_logistics_warehouse_mgmt": "Logistics Warehouse Mgmt",
  "standard_process_category_logistics_distribution_mgmt": "Logistics Distribution Mgmt",
  "standard_process_category_logistics_billing": "Logistics Billing",
  "standard_process_category_logistics_others": "Logistics Others",
  "customer_process_category_customer_quotation_mgmt": "Quotation Mgmt",
  "customer_process_category_shipment_data_and_milestone": "Shipment Data & Milestone",
  "customer_process_category_warehouse_operation": "Warehouse Operation",
  "customer_process_category_billing": "Billing",
  "customer_process_category_control_tower_and_kpi": "Control Tower & KPI",
  "customer_process_category_agent_mgmt": "Agent Mgmt",
  "invalid_url": "无效的连结",
  "invalid_date_range": "到期日不可早于生效日",
  "error_resource_not_found": "找不到该笔数据",
  "error_version_not_match": "版号不符",
  "error_version_lower": "不可小于现有版号",
  "error_attachment_duplicate": "档名重复新增",
  "error_valid_interval_not_match": "到期日不可早于生效日",
  "error_sop_name_duplicate": "该SOP名称已存在，请使用新名称。",
  "error_item_list_count": "需要至少一笔数据",
  "create_successfully": "发布成功",
  "update_successfully": "储存成功",
  "please_add_least_min_stations": "请新增至少一个站点",
  "published_at": "发布时间(UTC)",
  "updated_at_utc": "更新时间(UTC)",
  "published_by": "发布者",
  "error_invalid_station": "以下站点不存在，请移除:",
  "invalid_level_value": "无效的Level: %{level}",
  "error_invalid_station_value": "Station第 %{row_index} 列 - %{station}",
  "error_process_category_not_match": "",
  "is_reviewed": "Reviewed by HQ",
  "undefined": ""
 },
 "3rd_party": "第三方",
 "abbr_no": "No",
 "accept_all_cookies": "同意所有Cookies",
 "accept_all_cookies_v20201223": "如果您继续浏览且并未改变您的设定，或是您点击了\"同意所有Cookies\"，我们将认为您同意接收所有来自MEC网站的 Cookies。当然您也可以随时改变您的Cookies设定。",
 "accept_terms_and_conditions_is_required": "必须同意条款和条件",
 "account": "帐号",
 "action": "操作",
 "actions": "动作",
 "actual_arrive_transshipment_port": "实际抵达中转站",
 "actual_delivery_date": "实际递送时间",
 "actual_delivery_date_should_be_greater_actual_pickup_date": "实际递送时间应在实际提货时间之后",
 "actual_delivery_dt_utc": "实际递送时间",
 "actual_delivery_time": "实际递送时间",
 "actual_depart_transshipment_port": "实际离开中转站",
 "actual_driver_arrival_time": "实际司机抵达仓库",
 "actual_ofd_time": "外出递送时间",
 "actual_out_for_delivery_date": "外出递送日期",
 "actual_out_for_delivery_time": "外出递送时间",
 "actual_pickup_at": "提货时间",
 "actual_pickup_time": "实际提货时间",
 "acutal_arrive_final_usca_destination": "实际抵达美国/加拿大最终站",
 "acutal_arrive_first_usca_destination": "实际抵达美国/加拿大首站",
 "acutal_pickup_time": "实际提货",
 "add": "新增",
 "add_at_least_one_shipment_to_bol": "至少新增一个Shipment至送货单",
 "add_bol": "加入送货单(BOL)",
 "add_comment": "新增备注",
 "add_dimension": "新增尺寸",
 "add_field_select": "Add Field Select",
 "add_hawb": "加入HAWB",
 "add_minus_n": "± Num",
 "add_part": "新增料件细节",
 "add_type": "新增类型",
 "address": "地址",
 "adjust_column": "栏位调整",
 "advanced_search": "进阶搜寻",
 "ae": "AE",
 "agent": "Agent",
 "agree": "同意",
 "air": "空运",
 "alert": "告警",
 "alert_station": "站务告警",
 "all": "全部",
 "all_docs_received": "全部文件接收",
 "all_station": "All Station",
 "all_the_unsaved_work_will_be_lost": "全部未储存的内容将会遗失",
 "allow_to_view_function": "允许使用的功能",
 "amat": "AMAT",
 "amount": "金额",
 "ap_ar": "应付&应收帐款",
 "ap_processing": "应付帐款处理中",
 "api": "API",
 "application_data_will_process_to_review": "申请资料将进行审核",
 "appointment_no": "Appointment No",
 "appointment_number": "Appt/C#",
 "approved_amount": "核准金额",
 "apptc_no": "送货预约号码",
 "are_you_sure_to_delete_the_mawb": "您确定要删除此 MAWB 吗?",
 "are_you_sure_to_delete_this_view": "Are you sure to delete this view?",
 "are_you_sure_you_want_to_cancel_this_invoice_number": "您确定要删除此发票吗?",
 "are_you_sure_you_want_to_confirm_the_dispatch": "您要确认此派车单(Dispatch)吗?",
 "are_you_sure_you_want_to_delete_the_subscription": "您确定要删除此订阅吗？",
 "are_you_sure_you_want_to_edit_it": "您确定要编辑吗?",
 "are_you_sure_you_want_to_edit_the_dispatch": "您确定要编辑此派车单(Dispatch)吗?",
 "are_you_sure_you_want_to_exit": "您确定要离开吗?",
 "are_you_sure_you_want_to_save_the_dispatch": "您确定要储存此派车单(Dispatch)吗?",
 "arrival_notice_sent_date": "发送抵达通知",
 "arrive_cfs_date": "抵达出口 CFS 仓库日期",
 "arrived": "抵达",
 "arrived_at_dest_cfs": "抵达进口 CFS 仓库",
 "arrived_ata": "抵港(ATA)",
 "arrived_cfs": "到达目的地CFS",
 "arrived_dest_cfs": "抵达进口 CFS 仓库",
 "asn_details": "ASN 详情",
 "asn_info": "ASN 资讯",
 "asn_items": "ASN 项目",
 "asn_qty": "ASN 数量",
 "asn_received_date": "ASN 接收时间",
 "assign_carrier": "指派承运人",
 "assign_to": "指派给",
 "assigned": "已指派",
 "assigned_carrier": "已指派承运人",
 "assigned_to_bol": "已指派给送货单(BOL)?",
 "assigned_to_carrier": "已指派承运人",
 "assigned_to_dispatch": "已创建派车单(Dispatch)",
 "assigned_to_driver": "已指派司机",
 "assistant_driver_1_name": "随车人员",
 "assistant_driver_2_name": "随车人员",
 "at_risk": "处于风险",
 "ata": "实际到港(ATA)",
 "ata_date": "实际到港(ATA)",
 "atd": "实际离港(ATD)",
 "attachment_list": "附件",
 "attributes": "属性",
 "audit_actions": "审计动作",
 "aw": "实际重量",
 "awb_doc": "提单文件",
 "awgw": "实重/毛重",
 "back": "返回",
 "bid_required": "比价",
 "bigger_than_end_number": "大于结束值",
 "bigger_than_end_on": "大于开始值",
 "bigger_then_endon": "大于结束条件",
 "bill_to_code": "Bill-To code",
 "billto_party": "付款方 (Bill-to)",
 "bol_details": "送货单(BOL)详情",
 "bol_dispatch_creation_successfully": "派车单(Dispatch)创建成功。",
 "bol_grouping_rule": "货件合并到送货单(BOL)规则",
 "bol_no": "送货单(BOL)号码",
 "bol_pod": "签收地点",
 "bol_shipment_mode": "运输方式",
 "bol_shipto_city": "收货城市",
 "bol_shipto_country": "收货国家/地区",
 "bol_shipto_name": "收货方",
 "bol_special_instruction": "送货指示(BOL)",
 "bol_station": "站点",
 "bol_status": "送货单(BOL)状态",
 "bol_status_update": "送货单(BOL)状态更新",
 "bol_type": "送货单(BOL)类型",
 "bol_warning": "送货单(BOL)警告",
 "bols": "送货单(BOL)",
 "bols_added": "已加入的送货单(BOL)",
 "booked_on": "Booked On",
 "booking": "预订：",
 "booking_id": "Booking ID",
 "booking_mgmt": "预订管理",
 "booking_no": "Booking No",
 "box": "箱",
 "broker_date": "Broker Date",
 "by": "By",
 "by_clicking_alert_your_unsaved_data_will_also_be_saved": "点击告警，会储存尚未储存的资料。",
 "by_selecting_this_you_confirm_the_value_and_detail_of_your_uploaded_invoice_and_linked_reference_numbers_are_correct": "您确认上传的发票内容、金额以及关联的参考号码皆正确。",
 "c": "C",
 "call_printer": "呼叫打印机",
 "can_not_be_0": "不可为0",
 "can_not_contain_special_characters": "不可包含特殊字元",
 "cancel": "取消",
 "cancel_bol": "取消送货单 (BOL)",
 "cancel_booking": "取消预订",
 "cancel_edit": "取消编辑",
 "canceled": "已取消",
 "cancelled": "已取消",
 "cannot_be_empty": "不可为空",
 "cannot_be_empty_or_zero": "不可为空或是0",
 "cannot_update_to_null": "无法更新为 null",
 "cant_get_notreceived_count_error": "无法取得未收货的数量",
 "cargo_receipt": "货物接收",
 "cargosolution": "CARGO SOLUTION",
 "carrier": "承运人",
 "carrier_released": "承运人放行",
 "carrier_service_level": "承运人服务",
 "carton": "箱",
 "cartons_in_pallet": "栈板箱数",
 "cartons_in_ref": "Cartons in Ref#",
 "cash_on_delivery": "COD 金额",
 "cash_on_delivery_amount": "COD 金额",
 "category": "类别",
 "category_section": "类别",
 "cbm": "立方米",
 "cbf": "立方英尺",
 "changed_by_name_on_time": "%{name} 修改在 %{time}",
 "charge_code": "Charge Code",
 "charge_item": "收费项目",
 "charge_weight": "收费重量",
 "check_first_memo": "请先执行\"检查\"",
 "check_result": "检核结果",
 "checked_in_counter": "已指派承运人",
 "checked_to_confirmed": "确认",
 "children_node_will_have_the_same_privilege_as_parent": "子节点将拥有和父节点相同的权限",
 "choose_one": "选择一个",
 "choose_policy": "选择权限",
 "choose_the_put_away_location": "选择入库地点",
 "city": "城市",
 "clear_options": "全部清除",
 "cleared_customs": "清关",
 "click_here_or_drop_a_file_to_upload": "点击或是拖拉文件以上传",
 "client_code": "客户代码",
 "close": "关闭",
 "close_date": "关闭日期",
 "closed": "已关闭",
 "cod_amount_fee_terms": "货到付款条款",
 "column_with_error": "发生错误的栏位",
 "comment": "注释",
 "comment_category": "注释类别",
 "comment_category_close_task": "关闭任务",
 "cm": "公分",
 "cm_and_inch_are_available": "可用公分和吋",
 "cod": "货到付款",
 "collect": "Collect",
 "column_name": "栏位名称",
 "comment_category_exception": "例外",
 "comment_category_external_normal": "External Normal",
 "comment_category_internal_normal": "Internal Normal",
 "comment_category_late_billing_reason": "帐务延迟原因",
 "comment_category_message_code": "Message Code",
 "comment_category_normal_update": "一般更新",
 "comment_category_pending_reason": "等待原因",
 "comment_category_action_required": "需要採取的行動",
 "comment_category_tms_exception": "TMS 送货异常",
 "comment_category_tms_exception_message_code": "送貨異常代码",
 "comment_category_tms_exception_message_code_A2": "A2-地址错误",
 "comment_category_tms_exception_message_code_AG": "AG-其他收货人相关",
 "comment_category_tms_exception_message_code_AJ": "AJ-其他承运人相关",
 "comment_category_tms_exception_message_code_AN": "AN-放假关门",
 "comment_category_tms_exception_message_code_B1": "B1-收货人关门",
 "comment_category_tms_exception_message_code_BG": "BG-其他",
 "comment_category_tms_exception_message_code_S1": "S1-货物短少",
 "comment_here": "在此新增注释",
 "comment_category_void_shipment": "取消货物",
 "commercial_inv": "商业发票",
 "commercial_invoice": "商业发票",
 "commercial_invoice_doc": "商业发票",
 "company_name": "公司名称",
 "company_phone_number": "公司电话号码",
 "complete": "完成",
 "completed": "已完成",
 "completed_date": "完成时间",
 "comprehensive_routing_service_type_cross_border_truck": "跨边境卡车运输",
 "comprehensive_routing_service_type_customs": "关务服务",
 "comprehensive_routing_service_type_domestic_flight": "国内航班运输",
 "comprehensive_routing_service_type_domestic_truck": "国内卡车运输",
 "comprehensive_routing_service_type_integrated": "整合服务",
 "comprehensive_routing_service_type_international_flight": "国际航班",
 "comprehensive_routing_service_type_loading_and_unloading": "上货与卸货",
 "comprehensive_routing_service_type_packing": "包装",
 "comprehensive_routing_service_type_warehousing": "仓储",
 "comprehensive_routing_truck_type_air_ride_truck": "气垫卡车",
 "comprehensive_routing_truck_type_platform_truck": "平板卡车",
 "comprehensive_routing_truck_type_refrigerated_van": "冷藏货车",
 "comprehensive_routing_truck_type_refrigerated_van_for_dangerous_goods": "危险品冷藏货车",
 "comprehensive_routing_truck_type_temperature_controlled_air_ride_truck": "温控气垫卡车",
 "comprehensive_routing_truck_type_van": "普通厢式货车",
 "comprehensive_routing_truck_type_vehicle_for_dangerous_goods": "危险品货车",
 "comprehensive_routing_truck_type_sprinter": "轻型货车",
 "confirm": "确认",
 "confirm_bol": "确认送货单(BOL)",
 "confirm_password": "确认密码",
 "confirmed": "已确认",
 "consignee": "收货方",
 "consignee_address": "收货方地址",
 "consignee_address_local_language": "收货方中文地址",
 "consignee_address_localized": "收货地址(中文)",
 "consignee_address1": "收货方地址",
 "consignee_address2": "收货方地址",
 "consignee_city": "收货方城市",
 "consignee_code": "收货方代码",
 "consignee_country": "收货方国家/地区",
 "consignee_ctry": "收货方国家/地区",
 "consignee_idamat": "收货方ID(AMAT)",
 "consignee_name": "收货方",
 "consignee_name1": "收货方名称1",
 "consignee_name2": "收货方名称2",
 "consignee_state": "收货方州别",
 "consignee_zip": "收货方邮编",
 "consol_closed": "Consol Close",
 "contact": "联系人",
 "contact_phone": "联系人电话",
 "contact_email": "联系人电子邮件",
 "container_no": "货柜号码",
 "content": "内容",
 "continue": "继续",
 "continue_to_cancel_this_bill_of_lading": "要取消该送货单(BOL)吗？",
 "continue_to_save": "要继续储存吗?",
 "continue_uploading": "继续上传",
 "contractual_ctn_qty": "约定箱数",
 "contractual_loose_ctn_qty": "约定散箱数",
 "contractual_pallet_qty": "约定栈板数",
 "contractual_pcs_qty": "约定件数",
 "contractual_wgt": "约定重量",
 "contry_region": "Country Region",
 "conversation_id": "Conversation Id",
 "convert_to_ap": "转换至应付",
 "copy_from_export": "从出口复制",
 "cost": "成本",
 "country": "国家/地区",
 "country_of_origin": "原产地",
 "create": "创建",
 "create_bol_failed": "创建送货单(BOL)失败",
 "create_by": "创建人",
 "create_csv_failed": "Create CSV failed ",
 "create_date": "创建时间",
 "create_date_end": "新增时间结束",
 "create_date_start": "新增时间开始",
 "create_date_utc": "创建时间(UTC)",
 "create_master": "创建主单",
 "create_password": "创建密码",
 "create_subscription": "建立订阅",
 "create_success": "创建成功",
 "created_at": "建立时间",
 "created_at_utc": "创建时间(UTC)",
 "created_by": "创建者",
 "created_date": "创建日期",
 "created_utc_time": "创建时间(UTC)",
 "creation_date": "建立日期",
 "creator": "创建者",
 "cron_no_data_input": "Cron No Data Input",
 "cron_schedule": "排程",
 "csv_upload": "CSV档上传",
 "ctn_qty": "箱数",
 "currency": "币别",
 "custom_clearance_date": "清关",
 "custom_value": "报关价值",
 "customer": "客户",
 "customer_check_acceptable": "接受客户检查",
 "customer_code": "客户代码",
 "customer_id": "客户ID",
 "customer_name": "客户名称",
 "customer_reference_number": "客户参考号码",
 "customer_selfpickup": "客户自提",
 "customer_tracking": "追踪货件",
 "customers": "客户",
 "customized_setting": "个人化设置",
 "customized_table_header": "个人化表头",
 "customs_cleared": "清关",
 "customs_released": "海关放行",
 "cw": "收费重量",
 "dangerous_good": "危险品",
 "data": "资料",
 "data_export": "汇出",
 "data_uploaded_failed": "注意！档案资料中发现错误，请修正资料并重新上传",
 "data_uploaded_successfully": "数据上传成功",
 "data_validator_error_0001": "你目前权限无法看到此一分单信息",
 "data_validator_error_0002": "尚未完成Loading，HAWB与MAWB的关联未建立",
 "data_validator_error_0003": "尚未收到EDI945",
 "data_validator_error_0004": "此一HAWB不存在，请确认是否输入的内容正确",
 "data_validator_error_0005": "尚未在Prolink建报价(系统未纪录客户代码)",
 "data_validator_error_0006": "来自其他原因，请与IT人员进一步确认",
 "data_validator_error_0007": "信息存在，请检查搜寻条件",
 "data_validator_error_0008": "标签已经透过紧急输入生成 (情境：其他)",
 "data_validator_error_0009": "标签已经透过紧急输入生成 (情境：未收到EDI)",
 "data_validator_error_0010": "配舱信息(shipment_to_master)不存在",
 "data_validator_error_0011": "權限不足",
 "data_validator_error_no_authorization": "你没被授权接触此笔资料",
 "data_validator_error_empty_vip_name": "客户代码为空值",
 "data_validator_error_empty_handling_pieces": "没有设定HAWB件数",
 "data_validator_error_no_cfs_delivery_package_data": "Package ID (delivery_package)信息没有生成",
 "data_validator_error_no_shipment_data": "MorrisonOne不存在HAWB信息",
 "data_validator_error_no_master_data": "舱位(MAWB)信息不存在",
 "data_validator_error_console_info_is_not_complete": "舱位信息(包含loading plan)没有完整设定",
 "data_validator_error_not_assign_master_yet": "配舱信息(shipment_to_master)不存在",
 "data_validator_error_empty_customer_code_in_source_db": "客户代码为空值",
 "data_validator_error_not_sync_from_source_db_yet": "数据尚未从Prolink/CW1同步过来",
 "data_validator_error_not_received_edi945_yet": "还没收到EDI945",
 "data_validator_error_not_assign_master_yet_in_source_db": "要确认loading plan是否设定完整",
 "data_validator_error_cannot_find_in_both_source_db_and_mop": "HAWB信息不存在，请确认是否完成booking",
 "data_validator_error_cannot_find_master_in_mop": "MAWB信息不存在，请确认是否完成booking",
 "data_validator_error_shipment_created_time_out_of_range": "这笔HAWB在搜寻的时间范围之外(是在一个月前建立的)",
 "data_validator_error_empty_etd": "ETD是空值",
 "data_validator_error_wrong_port_in_search_conditions": "资料确实存在，请确认搜寻条件是否正确",
 "wrong_sscc_qty_in_edi945": "EDI945内容错误(SSCC数量)",
 "data_validator_error_wrong_sscc_qty_in_edi945": "EDI945内容错误(SSCC数量)",
 "data_validator_error_failed_to_invoking_validation_api": "验证 API 发生错误",
 "data_validator_error_other": "由于其他因素，请与IT同仁进一步确认",
 "date_range": "日期区间",
 "date_range_between": "之间",
 "date_range_between_relative_days": "Between Relative Days",
 "date_range_gte": "之后",
 "date_range_is_empty": "为空值",
 "date_range_last_month": "上个月",
 "date_range_last_week": "上周",
 "date_range_last_year": "去年",
 "date_range_lte": "之前",
 "date_range_month_today": "本月到今天",
 "date_range_next_month": "下个月",
 "date_range_next_week": "下周",
 "date_range_not_empty": "不为空值",
 "date_range_relative_date_gte": "自",
 "date_range_relative_date_lte": "之前",
 "date_range_this_month": "本月",
 "date_range_this_week": "这周",
 "date_range_this_year": "今年",
 "date_range_today": "今天",
 "date_range_tomorrow": "明天",
 "date_range_week_number_range": "周数",
 "date_range_week_today": "本周到今天",
 "date_range_within_days": "几天之内",
 "date_range_year_today": "今年到今天",
 "date_range_yesterday": "昨天",
 "string_eq": "Equal",
 "string_ne": "Not Equal",
 "string_in": "Contain",
 "string_not_in": "Not Contain",
 "string_like": "Like",
 "string_like_start_with": "Start With",
 "string_like_end_with": "End With",
 "string_is_empty": "Is Empty",
 "string_not_empty": "Not Empty",
 "days": "Days",
 "days_after": "Days After",
 "days_ago": "Days Ago",
 "default": "预设",
 "delay": "延误",
 "delayed": "已延误",
 "delete": "删除",
 "delete_dispatch": "要删除派车单(Dispatch)吗?",
 "delete_dispatch_dispatchnumber": "删除派车单 : \"%{dispatchNumber}\"",
 "delete_query_save_view_success": "删除工作表 %{name} 成功!",
 "delete_view": "删除工作表单",
 "delivered": "已送达",
 "delivered_time": "送达时间",
 "delivery_container": "Delivery No/ 货柜号码",
 "delivery_count": "Delivery数量",
 "delivery_date": "要求递送时间",
 "delivery_from": "要求递送时间(起)",
 "delivery_in_progress": "在途中",
 "delivery_method": "递送方式",
 "delivery_method_customer_self_pickup": "客户自提",
 "delivery_method_express": "快递",
 "delivery_method_ftl": "整车",
 "delivery_method_ltl": "拼车",
 "delivery_method_ltl_ftl": "拼车/整车",
 "delivery_no": "Delivery No",
 "delivery_on_progress": "在途中",
 "delivery_status_update": "货态更新",
 "delivery_time": "递送时间",
 "delivery_to": "要求递送时间(讫)",
 "delivery_to_customer": "递送至客户",
 "deny": "拒绝",
 "dep": "部门",
 "departed_to_terminal": "离开去航站",
 "department": "部门",
 "departure": "出发",
 "departured_atd": "实际离港(ATD)",
 "desc": "描述",
 "description": "描述",
 "dest": "进口站",
 "dest_country": "进口国家",
 "dest_custom_cleared": "进口站海关清关",
 "dest_custom_released": "进口站海关放行",
 "dest_hawb_hbl": "进口站",
 "dest_mawb_mbl": "进口站 (by MAWB / MBL)",
 "dest_port_city": "进口站城市",
 "dest_port_country": "进口站国家/地区",
 "dest_port_ctry": "进口站国家/地区",
 "dest_port_of_discharge": "进口站(卸货港)",
 "dest_region": "进口站地区",
 "dest_status": "进口站状态",
 "destination": "进口站",
 "destination_custom_released": "进口站海关放行",
 "destination_doc_status": "目的站文件状态",
 "destroy_success": "删除完成",
 "detail": "详情",
 "details": "详情",
 "dimension": "尺寸",
 "dimension_unit": "尺寸单位",
 "dimension_uom": "尺寸单位",
 "disabled_input_select": "Select...",
 "disabled_input_text": "Type...",
 "dismiss_cookies_statements": "取消",
 "dispatch": "派车",
 "dispatch_actual_ofd_date": "外出递送日期",
 "dispatch_carrier": "承运人",
 "dispatch_creation_date": "派车单(Dispatch)创建时间",
 "dispatch_customer_selfpu": "客户自提",
 "dispatch_delivery_method": "货件递送方式",
 "dispatch_details": "派车单(Dispatch)详情",
 "dispatch_edit": "编辑派车单(Dispatch)",
 "dispatch_form": "派车单(Dispatch)表单",
 "dispatch_id": "派车单ID",
 "dispatch_indicator": "派车指示器",
 "dispatch_mgmt": "派车管理",
 "dispatch_no_or_verification_code_is_wrong": "派车单(Dispatch)号或验证码错误",
 "dispatch_planned_ofd_date": "计划外出递送日期",
 "dispatch_shipment_mode": "运输方式",
 "dispatch_special_instruction": "特殊指示",
 "dispatch_status": "派车单(Dispatch)状态",
 "dispatch_status_update": "派车单(Dispatch)状态更新",
 "dispatch_summary": "派车单(Dispatch)总览",
 "dispatch_truck_pickup_date": "实际抵达时间",
 "dispatch_truck_pu_date": "卡车提货时间",
 "dispatch_type": "派车单(Dispatch)类型",
 "dn_sid": "DN/SID",
 "dn_sid_id": "DN/SID",
 "dn_timeline": "DN Timeline",
 "undefined": "",
 "do_you_want_to_delete_this_shipment": "您想要删除此票货件吗?",
 "do_you_want_to_print_bol_manifest": "您要列印以下文件吗？",
 "do_you_want_to_reprocess_it": "您想要再次执行吗?",
 "do_you_want_to_resubmit_it": "您想要再次上传吗?",
 "do_you_want_to_send_email_to_user": "請問您是否要寄信給使用者？",
 "doc_avail_date": "文件可用",
 "doc_release": "放单时间",
 "doc_released": "放单",
 "doc_status": "文件状态",
 "doc_to_broker": "文件至报关行",
 "document": "文件",
 "document_check": "文件检核",
 "document_id": "文件 ID",
 "document_ready": "文件就绪",
 "document_section": "文件",
 "documents": "文件",
 "documents_attached": "已附的文件",
 "download": "下载",
 "download_auth_failed": "Download Auth Failed",
 "download_file": "Download File",
 "download_file_expires": "Download File Expires",
 "download_request_data_empty": "Download Data Empty",
 "download_template": "模板下载",
 "draft": "草稿",
 "driver": "司机",
 "driver_info_entered": "司机资料已录入",
 "driver_name": "司机",
 "driver_phone_no": "司机电话",
 "drop_the_files_here": "拖拉文件到此处",
 "each": "每个",
 "eccn": "ECCN",
 "edi_event": "EDI事件",
 "edi_log_id": "EDI纪录ID",
 "edi_method": "EDI 方法",
 "edi_monitor": "EDI监控",
 "edi_monitor_edit": "EDI监控编辑",
 "edi_platform_id": "EDI平台ID",
 "edi_platform_sent_to_customer": "EDI Platform Sent To Customer",
 "edi_req_date": "EDI请求时间",
 "edi_req_date_utc": "EDI请求时间(UTC)",
 "edi_sent_date": "EDI发送时间",
 "edi_sent_date_timeutc0": "EDI发送时间(UTC+0)",
 "edi_sent_date_utc": "EDI发送时间(UTC)",
 "edi_sent_datetimeutc0": "EDI发送时间(UTC+0)",
 "edi_sent_utc_time": "EDI发送时间(UTC+0)",
 "edit": "编辑",
 "edit_subscription": "编辑订阅",
 "editcancel": "编辑/取消",
 "effective_end": "Effective End",
 "effective_start": "Effective From",
 "eic_code": "EIC号码",
 "email": "Email",
 "email_id": "邮件编号",
 "email_sent": "邮件已发送",
 "email_status": "邮件状态",
 "email_subject": "邮件主旨",
 "email_to": "Email收件人",
 "email_type": "邮件类型",
 "employeeid": "员工编号",
 "end_on": "结束",
 "english": "英文",
 "env_notice": "DEVELOP Environment",
 "env_notice_demo": "Demo Environment",
 "env_notice_develop": "DEVELOP Environment Anna test",
 "env_notice_epic": "EPIC Environment",
 "env_notice_feature": "FEATURE Environment❤❤❤",
 "env_notice_hint": "",
 "env_notice_hint_demo": "All The Data Is Not Real",
 "env_notice_local": "LOCAL Environment",
 "env_notice_prod": "PROD Environment",
 "env_notice_uat": "UAT Environment",
 "error": "错误",
 "error_code": "错误代码",
 "error_code_400": "",
 "error_code_401": "登入逾时！请重新登入",
 "error_code_403": "请尝试重试或者刷新页面。若仍然无法访问，请联络 mec_onehelp@morrisonexpress.com。",
 "error_code_404": "请尝试重试或者刷新页面。若仍然无法访问，请联络 mec_onehelp@morrisonexpress.com。",
 "error_code_406": "请求内容类型不正确。",
 "error_code_409": "請求衝突，請重新整理後再試",
 "error_code_500": "服务器错误，请复制错误追踪码並 email 到 mec_onehelp@morrisonexpress.com",
 "error_code_504": "连线逾时",
 "error_code_address_book_already_exist": "Address Book Already Exist",
 "error_code_asn_number_correspond_replicated_400": "asn_number已經與其他shipment關聯\n%{error_string} ",
 "error_code_booking_accept_bol_not_found": "无法以报价 ID: %{car_booking_id} 找到送货单(BOL/ DN)。",
 "error_code_booking_accept_bol_status_not_allowed": "送货单 %{bol_id} 已被取消，状态为 %{bol_status}，无法送出请求。",
 "error_code_booking_accept_booking_status_not_allowed": "当报价状态是 %{booking_status}，无法接受报价。",
 "error_code_booking_accept_car_booking_not_found": "无法以报价 ID: %{car_booking_id} 找到报价。",
 "error_code_booking_accept_carrier_name_not_defined": "该承运人没有 API。",
 "error_code_booking_accept_exception_bol_or_dispatch_not_found": "无法以报价ID：%{car_booking_id}找到所属的%{quotation_level}。",
 "error_code_booking_accept_exception_booking_status_not_allowed": "无法以报价 ID: %{car_booking_id} 找到报价。",
 "error_code_booking_accept_exception_car_booking_not_found": "无法以报价 ID: %{car_booking_id} 找到报价。",
 "error_code_booking_accept_exception_exist_another_accepted_booking": "因当前已存在一个报价，故无法接受报价。",
 "error_code_booking_accept_exist_another_accepted_booking": "因当前已存在一个报价，故无法接受报价。",
 "error_code_booking_accept_no_submit_response_s3_key": "因没有 quotation_res_s3_key，而无法找到报价 %{car_booking_id} 资讯。",
 "error_code_booking_accept_offline_exist_another_accept_booking": "已经存在一个线下的报价。",
 "error_code_booking_accept_partner_info_not_found": "无法找到承运人API %{partner_name} 的连接讯息。",
 "error_code_booking_accept_response": "回传错误讯息： %{error_message}\nCarrier quotation log id: %{carrier_booking_log_id}, Car quotation id: %{car_booking_id}, BOL/ DN id: %{bol_id}",
 "error_code_booking_accept_ups_api_validate_error": "UPS 资料检核错误： %{result}。报价 ID: %{car_booking_id}",
 "error_code_booking_cancel_bol_not_found": "Can not find bol by car booking id: %{car_booking_id}.",
 "error_code_booking_cancel_bol_status_not_allowed": "送货单 %{bol_id} 已被取消，状态为 %{bol_status}，无法送出请求。",
 "error_code_booking_cancel_booking_status_not_allowed": "当报价状态是 %{booking_status}，无法取消报价。",
 "error_code_booking_cancel_car_booking_not_found": "无法以报价 ID: %{car_booking_id} 找到报价。",
 "error_code_booking_cancel_carrier_name_not_defined": "承运人\"%{carrier_code}\" \"%{carrier_name}\"不存在。",
 "error_code_booking_cancel_partner_info_not_found": "无法找到承运人API %{partner_name} 的连接讯息。",
 "error_code_booking_cancel_response": "回传错误讯息： %{error_message}",
 "error_code_booking_cancel_ups_api_validate_error": "UPS 资料检核错误： %{result}。报价 ID: %{car_booking_id}",
 "error_code_booking_mop_shipping_key_not_found": "MOP shipping key 不存在. 请确认承运人\"%{carrier_code} \", 发货帐号\"%{shipper_account}\".",
 "error_code_booking_submit_bol_not_found": "无法找到送货单(BOL/ DN) : %{bol_id}。",
 "error_code_booking_submit_bol_status_not_allowed": "送货单(BOL/ DN) : %{bol_id} 已被取消，状态为 %{bol_status}，无法送出请求。",
 "error_code_booking_submit_carrier_name_not_defined": "承运人\"%{carrier_code}\" “%{carrier_name}”不存在。",
 "error_code_booking_submit_partner_info_not_found": "无法找到承运人API %{partner_name} 的连接讯息。",
 "error_code_booking_submit_redis_carrier_mapping_error": "无法用承运人 %{carrier_code} 匹配到承运人服务。",
 "error_code_booking_submit_response": "回传错误讯息： %{error_message}。",
 "error_code_booking_submit_ups_api_validate_error": "UPS 资料检核错误： %{result}。",
 "error_code_can_not_find_bol": "无法找到送货单: %{bol_number}.",
 "error_code_can_not_find_item": "找不到 %{item_name}: %{item_key}: ",
 "error_code_can_not_find_shipment": "无法找到货物: %{shipment_number}.",
 "error_code_cant_save_with_processed_voucher": "There are processed vouchers, please void them first. %{error_detail}",
 "error_code_create_shipment_invalid_pr": "This is an invalid PR, which cannot be used to create shipment anymore",
 "error_code_delivery_status_update_error_PDF_file_broken": "PDF 文件损坏，请更换文件后重试。",
 "error_code_failed_to_create_invoice_request": "创建发票请求失败",
 "error_code_failed_to_create_voucher_request": "创建 PO 请求失败",
 "error_code_failed_to_delete_voucher_request": "删除 PO 请求失败",
 "error_code_failed_to_post_all_to_prolink": "Post All to Prolink Failed",
 "error_code_failed_to_retrieve_voucher_request": "检索 PO 请求失败",
 "error_code_failed_to_update_voucher_request": "更新 PO 请求失败",
 "error_code_failed_to_void": "请勿重複 Void，已有 Void 请求正在处理中...",
 "error_code_failed_to_void_invoice": "作废发票失败",
 "error_code_failed_to_void_voucher": "作废 PO 失败",
 "error_code_forbidden": "You don't have the privilege id %{privilege_id}.",
 "error_code_label_data_not_ready": "货件 %{shipment} 在出运前需要列印收货人托盘标签，目前 “收货人托盘标签资料” 为 “尚未就绪” 而无法列印。请联络客户经理 %{account_manager}. 确认。",
 "error_code_permission_401": "无访问权限",
 "error_code_policy_not_set": "Policy scope is not correct. reason: %{reason}",
 "error_code_print_pallet_label": "缺少收货人标签必要资料 %{missing_required_data}。如已跟客户经理 %{account_manager} 确认，请按下”确认“继续，否则请按”取消”。",
 "error_code_s3_client": "平台档案操作错误",
 "error_code_tms_bol_grouping_different_bol_delivery_method": "您选择了不同递送方式的送货单(BOL/ DN) ，请重新选择相同递送方式的送货单(BOL/ DN)加入派车单(Dispatch)。",
 "error_code_unable_to_find_the_invoice_id": "Can't find the invoice ID",
 "error_code_unable_to_find_the_shipment": "找不到货件",
 "error_code_unable_to_find_the_voucher_id": "Can't find the voucher ID",
 "error_code_unauthorized": "登入逾时！请重新登入",
 "error_code_xxx": "与服务器连接失败. 请尝试重试或者刷新页面。若仍然无法访问，请联络 mec_onehelp@morrisonexpress.com。",
 "error_confirm_not_checked": "请勾选选项",
 "error_confirmed": "我已了解",
 "error_detail": "详细错误资讯",
 "error_invalid_cron": "Invalid Schedule",
 "error_row": "Error Row",
 "error_tracking_id": "错误追踪码",
 "escort": "护送",
 "estimated_delivery": "计划送货时间",
 "estimated_delivery_date": "计划送货日期",
 "estimated_pickup_at": "预定拣货",
 "eta": "预计到港(ETA)",
 "etd": "预计离港",
 "etd_utc": "预计离港(UTC)",
 "event": "事件",
 "event_category": "事件类别",
 "event_cateogry": "事件类别",
 "event_date_local_time": "事件时间(当地时间)",
 "event_date_time_local": "Event Datetime (Local)",
 "event_datetime_local": "Event Datetime (Local)",
 "event_datetimelocal": "事件时间(当地时间)",
 "event_location": "事件发生地",
 "exception": "例外",
 "exception_count": "例外数量",
 "exception_history": "例外纪录",
 "exception_id": "例外编号",
 "exception_invoice_number": "Exception Invoice Number",
 "exception_message": "例外讯息",
 "exemption_type": "豁免类型",
 "expensive": "高价",
 "export": "汇出",
 "export_cfs_to_terminal": "出口集散站至航站",
 "export_depart_to_terminal_time_utc": "出口至航站时间(UTC)",
 "export_errors": "出口错误!",
 "export_picking": "出口捡货",
 "export_put_away": "出口入库",
 "export_receive": "出口收货",
 "export_receive_edit": "编辑出口收货",
 "export_received_time_utc": "出口收件时间(UTC)",
 "export_status": "出口状态",
 "express": "快递",
 "ext": "Ext",
 "extension_type": "Extension Type",
 "fail": "失敗",
 "fast_dispatch_create": "快速建派车单",
 "feet": "英呎",
 "field_select": "选择栏位",
 "file": "档案路径",
 "file_name": "档案名称",
 "file_over_quantity": "档案数量超过上限",
 "files_submitted": "档案已上传",
 "filter": "筛选",
 "final_ata": "实际抵港(ATA)",
 "finish": "已完成",
 "first_atd": "首个 ATD",
 "first_bill": "First Bill",
 "first_etd": "首个 ETD",
 "fish_wildlife": "鱼类与野生动物",
 "flight": "航班",
 "flight_no": "航班号",
 "forget_password": "忘記密碼",
 "form": "表格",
 "freight_charge_terms": "运费条款",
 "freight_charges_collect": "到付",
 "freight_charges_prepaid": "预付",
 "freight_charges_third_party": "第三方",
 "freight_counted_by": "货物计数",
 "freight_counted_driver_pallets": "Driver/pallets said to contain",
 "freight_counted_driver_pieces": "Driver/Pieces",
 "freight_counted_shipper": "Shipper",
 "freight_term": "付款条款",
 "from": "起运",
 "ftl": "FTL",
 "function": "功能",
 "general": "一般",
 "general_menu": "一般选单",
 "get_bolnumber_data_failed": "获取\"%{bolNumber}\" 资料失败!",
 "get_edi": "获取EDI资料",
 "get_picking_failed": "捡货失败!",
 "get_presigned_url_failed": "获取网址失败！",
 "get_putaway_failed": "获取入库失败",
 "get_putaway_location_error": "获取入库地点失败",
 "get_summary_data_failed": "获取摘要失败",
 "goods_description": "货物描述",
 "gross_weight": "毛重",
 "gross_weight_must_be_greater_than_tare_weight": "毛重必须大于皮重",
 "gross_wgt": "毛重",
 "group_id": "群组 ID",
 "had_the_different_auto_print": "货件存在不同的自动列印注记",
 "had_the_different_carrier": "货件存在不同的预指派承运人",
 "had_the_different_consignee_address": "货件存在不同的收货地址",
 "had_the_different_consignee_id": "货件存在不同的收货人ID",
 "had_the_different_delivery_method": "货件存在不同的递送方式",
 "had_the_different_document_ready": "货件存在不同的文件状态",
 "had_the_different_payer": "货件存在不同付款方",
 "had_the_different_payer_account": "货件存在不同付款帐号",
 "had_the_different_payer_name": "货件存在不同付款人",
 "had_the_different_quotation_level": "货件存在不同的计价方式",
 "handed_to_air_carrier": "移交至空运方",
 "handed_to_mec": "移交至鸿霖",
 "handling_agent": "操作代理",
 "handover": "交接货物",
 "handover_to_driver": "交接货物至司机",
 "has_duplicate_query_save_view_name": "警告! 此工作表单名称已被使用",
 "hawb": "HAWB#",
 "hawb_cartons": "HAWB 箱数",
 "hawb_count": "Shipment数",
 "hawb_dest": "HAWB 目的港",
 "hawb_doc": "HAWB 文件",
 "hawb_edit": "编辑 HAWB",
 "hawb_first_atd_local_time": "HAWB 首个实际离港(当地时间)",
 "hawb_hbl": "HAWB / HBL",
 "hawb_id": "HAWB ID",
 "hawb_label_print": "HAWB 印制标籤",
 "hawb_no": "HAWB / HBL",
 "hawb_orig": "HAWB 始发地",
 "hawb_pallets": "HAWB 栈板数",
 "hawb_pcs": "HAWB 件数",
 "hawb_pkg_detail": "HAWB 包裹详情",
 "hawb_total_cartons": "HAWB 总箱数",
 "hawb_upload": "上传 HAWB",
 "hawbbl": "HAWB / HBL",
 "hawbhbl": "HAWB / HBL",
 "height": "高度",
 "hello_name": "您好, %{name}!",
 "hide": "隐藏",
 "high": "高",
 "history": "历史资讯…",
 "hkg_milestone": "HKG Milestone",
 "hts": "HTS",
 "i_accept": "我接受",
 "id": "编号",
 "import": "进口",
 "in_process": "处理中",
 "in_progress": "进行中",
 "inbound_file_path": "档案路径",
 "incomplete": "未完成",
 "incoterm": "Incoterm",
 "index_properties": "Index Properties",
 "indicator": "Indicator",
 "inland_trucking": "内陆卡车",
 "inner_cartons": "Inner Cartons",
 "input_more_than_the_next_n_days_is_not_allowed": "不允许输入超过未来 %{n} 天",
 "instruction": "指示",
 "internal_id": "内部 ID",
 "inv_amount": "发票金额",
 "inv_canceled": "发票已被取消",
 "inv_currency": "发票币别",
 "inv_id": "发票编号",
 "inv_no": "发票号码",
 "inv_rejected": "发票已被拒绝",
 "inv_submitted": "发票已上传",
 "invalid_date": "无效的日期",
 "invalid_date_time_please_enter_yyyy_mm_dd_hh24_mm": "Invalid Date time, please enter yyyy/MM/dd HH:mm",
 "invalid_datetime": "无效的日期时间",
 "invalid_email": "无效的Email",
 "invalid_future_time": "不可输入未来时间",
 "invalid_input": "无效的输入",
 "invalid_password": "无效的密码",
 "invalid_phone_number": "无效的电话号码",
 "invoice": "发票",
 "invoice_amount": "发票金额",
 "invoice_date": "发票日期",
 "invoice_edit": "编辑发票",
 "invoice_file": "发票档案",
 "invoice_no": "发票号码",
 "invoice_no_number": "发票号码: %{number}",
 "invoice_number": "发票号码",
 "invoice_query": "发票搜寻",
 "invoice_status": "票票状态:",
 "invoice_upload": "发票上传",
 "isa_no": "ISA编号",
 "issue_date": "开据日期",
 "itn": "ITN#",
 "key": "键值",
 "kg": "公斤",
 "last_4_digits_of_your_phone_number": "手机后四码",
 "last_eta": "预计到港(ETA)",
 "lb": "磅",
 "lefted_atd": "No use",
 "length": "长度",
 "level": "Level",
 "license_code": "证照代码",
 "lifted_atd": "No use",
 "lifted_departed_atd": "启航(ATD)",
 "line": "Line",
 "link": "关联",
 "link_reference_no_confirm": "关联参考号码并确认",
 "linked_po_no_ref_no": "关联PO号码/参考号码",
 "list": "清单",
 "load_dispatch": "添加派车单(Dispatch)",
 "loading": "加载中",
 "loading_meter": "装载量",
 "loading_type": "装载类型",
 "logistic_handling": "物流操作",
 "logout": "登出",
 "loose_cartons": "散箱",
 "loose_ctn_qty": "散箱",
 "ltl": "LTL / FTL",
 "m": "尺",
 "mawb": "MAWB",
 "mawb_awb": "MAWB AWB",
 "mawb_dest": "MAWB 目的港",
 "mawb_doc": "MAWB 文件",
 "mawb_mbl": "MAWB / MBL",
 "mawb_no": "MAWB / MBL",
 "mawb_orig": "MAWB 始发港",
 "mawb_pcs": "MAWB 件数",
 "mawb_upload": "上传MAWB",
 "max_length_colon": "Max Length: %{max}",
 "max_length_must_be_variable_characters_or_less": "超出长度: 最多只能 %{max} 个字元",
 "mec_booking_no": "MEC 预订号码",
 "medium": "中等",
 "menu": "选单",
 "message": "讯息",
 "message_id": "讯息编号",
 "milestone": "里程碑",
 "milestone_options_air_delivery_actual_pod": "实际送达",
 "milestone_options_air_delivery_estimated_pod": "预计送达",
 "milestone_options_air_delivery_estimated_pod_sla": "SLA",
 "milestone_options_air_delivery_ofd": "外出递送",
 "milestone_options_air_shipment_actual_pickup": "实际提货",
 "milestone_options_air_shipment_arrive_cfs": "抵达CFS",
 "milestone_options_air_shipment_ata": "实际抵达",
 "milestone_options_air_shipment_atd": "实际出发",
 "milestone_options_air_shipment_booking_date": "预定日期",
 "milestone_options_air_shipment_customs_clear": "始发地清关",
 "milestone_options_air_shipment_dest_customs_start": "目的地报关开始",
 "milestone_options_air_shipment_doc_release": "放单时间",
 "milestone_options_air_shipment_estimated_pickup": "预计提货",
 "milestone_options_air_shipment_eta": "预计抵达",
 "milestone_options_air_shipment_etd": "预计出发",
 "milestone_options_air_shipment_packing": "包装",
 "milestone_options_cargo_returned": "货物退回",
 "milestone_options_destination_customs_start": "Import Station Customs Started",
 "milestone_options_import_customs_release": "Import Station Customs Released",
 "milestone_status": "货态时间查询",
 "milestone_status_arrived": "抵达",
 "milestone_status_arrived_cfs": "抵达目的地CFS",
 "milestone_status_booked": "预定",
 "milestone_status_cancelled": "取消",
 "milestone_status_customs_cleared": "出口清关",
 "milestone_status_customs_released": "进口清关",
 "milestone_status_delivered": "送达",
 "milestone_status_departured": "出发",
 "milestone_status_document_released": "文件释出",
 "milestone_status_estimated_arrival": "预计抵达",
 "milestone_status_estimated_departure": "预计出发",
 "milestone_status_out_for_delivery": "外出递送",
 "milestone_status_packing": "装货",
 "milestone_status_picked_up": "提货",
 "milestones": "里程碑",
 "milestones_with_blue_font_will_be_sent_via_edi_214_to_customer": "蓝色字体之事件会发送EDI214至客户端",
 "misc_docs": "其他文件",
 "mobile_number": "退回货物",
 "mode": "运输方式",
 "mop_application_form": "MOP 申请表格",
 "mop_status_code": "MOP 状态代码",
 "more": "更多…",
 "morrison": "Morrison",
 "morrison_asn": "Morrison ASN#",
 "morrison_customer_id": "Morrison Customer ID",
 "morrison_rcv": "Morrison RCV#",
 "multiple_key": "Multiple Key",
 "must_a_number": "必须是一个数字",
 "must_be_only_y_n": "必须输入 Y / N",
 "must_in_options": "does not match, accept only %{options}",
 "must_integer": "必须是一个整数",
 "my_colleague": "我的同事",
 "my_station": "我的站点",
 "my_subscription": "我的订阅",
 "my_team": "我的团队",
 "only_allow_decimal": "只允许输入小数%{num}位",
 "n": "N",
 "name": "名称",
 "nav_more": "更多",
 "necessary_to_input_only_if_scenario_is_urgent_use": "只有在情境是\"未收到EDI\"，才是必填的",
 "need_resubmission": "需要重新上传",
 "net_wgt": "淨重",
 "new": "新增",
 "new_content": "新内容",
 "new_dispatch": "新增派车单(Dispatch)",
 "new_exception": "新增例外",
 "new_hawb": "新增 HAWB",
 "new_shipment": "新增货件",
 "new_update": "更新",
 "next": "下一步",
 "no": "否",
 "no_data": "没有资料",
 "no_dispatch": "没有派车单(Dispatch)!",
 "no_dispath_bols": "没有派车单(Dispatch)!",
 "no_dnsid_selected": "没有选取DN/SID!",
 "no_document": "没有文件!",
 "no_documents": "没有文件!",
 "no_downloadurl": "没有下载网址",
 "no_future_datetime_is_allowed": "不允许输入未来时间",
 "no_invoice": "没有发票!",
 "no_invoices": "没有发票!",
 "no_milestones": "没有Milestone！",
 "no_pre_signed_list": "没有预先签署的名单",
 "no_records": "没有资料！",
 "no_references": "没有参考值！",
 "no_reports": "没有报表！",
 "no_roles": "没有角色！",
 "no_shipment": "没有货件!",
 "no_shipment_tracking_data": "没有货物追踪资料！",
 "no_shipments": "没有货件！",
 "no_users": "没有使用者!",
 "noDocument": "No Document!",
 "noDownloadUrl": "No Download Url!",
 "none": "None",
 "nontrade": "Non-Trade",
 "normal": "一般",
 "not_assigned": "未指派",
 "not_ready": "尚未就绪",
 "not_received": "尚未收货",
 "not_special_characters": "不可包含特殊字元",
 "note": "注记",
 "notice_status": "Notice Status",
 "notification": "提醒",
 "notify_party": "通知方",
 "npi": "NPI",
 "num": "Num",
 "number": "Number",
 "number_of_line_items": "项目数量",
 "numbering_assigned_to_carrier": "10-已指派承运人",
 "numbering_assigned_to_driver": "15-已指派司机",
 "numbering_canceled": "90-已取消",
 "numbering_cancelled": "90-已取消",
 "numbering_delivered": "30-已送达",
 "numbering_delivery_in_progress": "25-在途中",
 "numbering_not_assigned": "00-未派车",
 "numbering_out_for_delivery": "20-外出递送",
 "numbering_planning": "05-计划中",
 "numbering_shipment_canceled": "99-已取消",
 "ocean": "海运",
 "ocr": "OCR",
 "ofd": "外出递送日期",
 "ofd_time": "外出递送日期",
 "ofddeparted_from_cfs": "从集散站出发递送",
 "off_for_delivery_date": "交付派送时间",
 "off_load": "Offload",
 "ok": "OK",
 "onboard": "登船",
 "onground": "On-Ground",
 "open": "打开",
 "operator": "作业员",
 "option_contactrole_agent": "Agent",
 "option_contactrole_airliner_carrier": "",
 "option_contactrole_branch": "Branch",
 "option_contactrole_broker": "",
 "option_contactrole_co_loader": "",
 "option_contactrole_general_cargo": "",
 "option_contactrole_ocean_liner": "",
 "option_contactrole_other_vendor": "",
 "option_contactrole_trucker": "",
 "option_contactrole_warehouse": "",
 "option_milestone_arrive_at_cfs": "",
 "option_milestone_arrived_at_cfs": "抵达目的CFS",
 "option_milestone_custom_cleared": "出口清关",
 "option_milestone_customs_cleared_at": "Customs Cleared",
 "option_milestone_customs_released_at": "Customs Released",
 "option_milestone_dest_custom_released": "进口清关",
 "option_milestone_ofd": "外出递送",
 "option_milestone_packing": "包装日期",
 "option_milestone_pickup_at": "实际提货",
 "option_milestone_pod": "实际送达",
 "option_milstone_actual_delivery_date": "实际送货",
 "option_milstone_actual_pickup_at": "提货",
 "option_milstone_arrived_at_cfs": "抵达目的地CFS",
 "option_milstone_arrived_cfs": "抵达目的地CFS",
 "option_milstone_arrived_cfs_at": "抵达目的地CFS",
 "option_milstone_ata": "最后实际到达",
 "option_milstone_atd": "首次实际出发",
 "option_milstone_billing_issued_at": "Invoice / Voucher Date",
 "option_milstone_booking_date": "预定",
 "option_milstone_created_at_utc": "生成日期",
 "option_milstone_customs_cleared": "清关",
 "option_milstone_customs_cleared_at": "始发地清关",
 "option_milstone_customs_released": "海关放行",
 "option_milstone_customs_released_at": "目的地关务放行",
 "option_milstone_doc_released": "文件释出",
 "option_milstone_doc_released_at": "文件释出",
 "option_milstone_estimated_delivery_date": "预计送货",
 "option_milstone_estimated_delivery_date_sla": "SLA 到期日",
 "option_milstone_estimated_pickup_at": "预定拣货",
 "option_milstone_eta": "最后预计到达",
 "option_milstone_etd": "首次预计出发",
 "option_milstone_first_etd": "首次预计出发",
 "option_milstone_out_for_delivery_date": "外出递送",
 "option_milstone_print_at": "Print Date",
 "option_milstone_void_at": "Void Date",
 "option_milstone_actual_pickup": "Actual Pickup Date",
 "option_milstone_estimated_pickup": "Estimated Pickup Date",
 "order": "订单",
 "order_class": "Order Class",
 "order_id": "订单ID",
 "orig": "出口站",
 "orig_country": "Orig Country",
 "orig_hawb_hbl": "出口站",
 "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
 "orig_port_city": "出口站城市",
 "orig_port_country": "出口站国家/地区",
 "orig_port_ctry": "出口站国家/地区",
 "orig_port_of_loading": "出口站(装货港)",
 "orig_status": "出口站状态",
 "origin": "出口站",
 "origin_custom_cleared": "出口站清关",
 "osd_status": "OSD状态",
 "other_docs": "其他文件",
 "other_instruction": "其他指令",
 "others": "其他",
 "out_for_delivery": "外出递送",
 "out_of_allow_date_range": "超出允许时间日期",
 "out_of_delivery": "实际外出递送日期",
 "out_of_gauge": "超标(货物)",
 "output": "输出",
 "pack_unit": "包装单位",
 "package_details": "包裹细节",
 "package_id": "包裹ID",
 "package_qty": "包裹数",
 "package_quantity": "包裹数",
 "package_type": "包装类型",
 "package_unit": "包装类型",
 "packing_list": "箱单",
 "packs": "包装",
 "pallet": "栈板",
 "pallet_id": "栈板ID",
 "pallet_wgt": "栈板重量",
 "parser": "Parser",
 "part": "料件",
 "part_desc": "料件描述",
 "part_no": "料件号码",
 "partial_received": "部分收货",
 "partilly_receive": "部分收货",
 "password": "密碼",
 "password_must_match": "密碼必須相同",
 "payer": "付款方",
 "payer_name": "付款方名字",
 "payment_completed": "付款已完成",
 "payment_to_carrier_by": "付款方(给承运人)",
 "pcs": "件数",
 "pcs_in_location": "在库数量",
 "pcs_is_not_a_positive_number": "件数非正数",
 "pcs_is_over_received": "件数超过收货数量",
 "pcs_qty": "件数",
 "period_end": "结束",
 "period_start": "开始",
 "permission_denied_warning": "与服务器连接失败. 请尝试重试或者刷新页面。若仍然无法访问，请联络 mec_onehelp@morrisonexpress.com。",
 "photo_list": "照片列表",
 "photo_management_milestone_export_handover": "Export - Handover",
 "photo_management_milestone_export_receiving": "Export - Receiving",
 "photo_management_milestone_import_after_sorting": "Import - After Sorting",
 "photo_management_milestone_import_reweigh": "Import - Reweigh",
 "photo_management_milestone_import_delivery": "Import - Delivery",
 "photo_management_milestone_import_pickup": "Import - Pickup",
 "photo_management_milestone_import_receiving": "Import - Receiving",
 "photo_management_milestone_export_received": "Export - Received",
 "photo_management_milestone_import_received": "Import - Received",
 "inventory_transfer_pickup": "Inventory - Pickup",
 "inventory_transfer_receiving": "Inventory - Receiving",
 "photos": "照片",
 "picked": "已拣货",
 "picked_pcs": "已拣货件数",
 "picked_up": "提貨",
 "picking_pcs": "拣货件数",
 "picking_status": "拣货状态",
 "pickup_complete": "取货完成",
 "pickup_date": "提货日期",
 "pickup_from": "提貨日期(起)",
 "pickup_from_shipper": "从发货人处提货",
 "pickup_to": "取货日期(讫)",
 "planned_delivery_date": "计划递送时间",
 "planned_delivery_dt_utc": "计划送货时间 (UTC)",
 "planned_delivery_time": "计划送货时间",
 "planned_driver_arrival_date": "计划司机抵达仓库日期",
 "planned_driver_arrival_time": "计划司机抵达仓库",
 "planned_driver_arrival_time_and_ofd_time": "计划司机抵达仓库和外出递送时间",
 "planned_ofd_time": "计划外出递送时间",
 "planned_out_for_delivery_date": "计划外出递送日期",
 "planned_out_for_delivery_time": "计划外出递送时间",
 "planned_pickup_time": "计划司机抵达仓库",
 "planning": "计划中",
 "please_click_link_below_to_download_report_if_automatic_download_do_not_initialize": "如没有自动下载，请点连接下载报告。",
 "please_enter_a_valid_datetime": "请输入一个有效的时间。",
 "please_enter_a_valid_date_and_time": "请输入正确格式。",
 "please_enter_a_valid_number": "Please enter a valid number",
 "please_enter_invoice_no": "请输入发票号码。",
 "please_select_at_least_one_record": "请至少选一笔资料。",
 "please_select_delivery_nubmer_for_document_upload": "请选择送货号码来上传文件。",
 "please_select_station": "请选择站点",
 "please_type_in_your_printed_invoice_value": "Please type in your printed invoice value",
 "please_upload_the_supporting_doc_eg_proof_of_delivery": "请上传证明文件(如POD签收单)",
 "please_upload_your_invoice": "请上传发票。",
 "please_use_google_chrome_to_access": "请使用 Google Chrome",
 "plt_qty": "栈板数",
 "plts": "栈板数",
 "po": "PO#",
 "po_created_date": "PO 建立日期",
 "po_issue_date": "PO 发行日期",
 "po_request_status": "PO Request 状态",
 "pod": "POD",
 "pod_date": "签收日期",
 "pod_file": "签收单(POD File)",
 "pod_location": "签收地点",
 "pod_name": "签收人",
 "pod_reference_file": "签收参考文件",
 "pod_signed_by": "签收人",
 "pod_time": "签收时间",
 "poddelivery_date": "签收日期",
 "policy_attribute": "策略属性",
 "policy_form": "权限表单",
 "policy_name": "策略名称",
 "policy_setting": "策略设定",
 "do_you_want_to_delete_policy?": "您确定要删除权限吗?",
 "port_of_delivery": "交货港",
 "port_of_delivery_city": "交货港城市",
 "port_of_delivery_country": "交货港国家/地区",
 "port_of_receipt": "收货港",
 "port_of_receipt_city": "收货港城市",
 "port_of_receipt_country": "收货港国家/地区",
 "potential_delay": "递送可能延误",
 "preassigned_carrier": "承运人",
 "preassigned_carrier_different_bol_carrier": "预指派承运人和实际承运人不同",
 "prepaid": "预付",
 "preview": "预览",
 "preview_file": "预览文件",
 "previous": "上一页",
 "primary_driver_name": "司机",
 "print": "打印",
 "print_bol": "打印送货单(BOL)",
 "print_manifest": "打印派车单(Dispatch)",
 "print_status": "列印状态",
 "privacy_policy": "隱私政策",
 "private": "Private",
 "privilege": "权限",
 "process": "处理",
 "process_message": "处理讯息",
 "product_type": "产品类型",
 "progress": "运送进度",
 "project_code": "專案代码",
 "proof_of_delivery": "签收单(POD)",
 "protocol": "协议",
 "public": "公共",
 "put_away": "入库",
 "put_away_location": "入库库位",
 "put_away_pieces_is_less_than_hawb_pieces_continue_to_save": "入库数量少于提单所列件数，请确认是否继续并储存?",
 "putaway_location": "入库库位",
 "qty": "数量*",
 "query": "查询",
 "query_documents_success": "查询档案成功！",
 "query_fail_no_bill_of_lading_data": "失败! 没有送货单(BOL)资料",
 "query_failed": "失败!",
 "query_failed_due_to_error": "失败! %{error}",
 "query_invoices_success": "Query invoices success",
 "query_report_download_success": "Query Report Download Success.",
 "query_save_view_info": "您可以使用此功能储存搜寻的条件和栏位以供下次使用。",
 "query_save_view_title": "建立新工作表单",
 "query_user_success": "Query user success",
 "quit": "放弃",
 "quotation_level": "报价层级",
 "quotation_number": "报价号码",
 "quotation_price": "报价",
 "ready": "就绪",
 "reason": "原因",
 "receipt_no": "收据号码",
 "received": "已收货",
 "received_serial_numbers": "已收序号",
 "receiver": "接收者",
 "receiving": "Receiving",
 "receiving_no": "Receiving No.",
 "recipient_email": "接收者信箱",
 "recipient_email_content": "訊息(選填)",
 "recipient_email_list": "接收者信箱清單",
 "records_not_found": "没有资料或权限不足的%{type}",
 "ref": "参考号码",
 "ref_no": "参考号码",
 "reference": "参考值",
 "reference_type": "参考值类别",
 "reference_value": "参考值",
 "reference_files": "参考文件",
 "reference2": "Reference#2",
 "refresh_every_15_seconds": "每15秒重新整理",
 "refresh_every_5_minutes": "每5分钟重新整理",
 "register": "注册",
 "register_email": "注册人电子邮箱",
 "reject": "拒绝",
 "rejected": "已拒绝",
 "related": "相关的",
 "remark": "备注",
 "remove_all_error": "移除所有錯誤",
 "report": "报表",
 "report_download": "报表下载",
 "report_name": "报表名称",
 "reprocess": "重跑流程",
 "requester": "申請人",
 "required": "必要的资料",
 "resend_sms": "重发短信",
 "reset": "重设",
 "resubmit": "重新提交",
 "result": "结果",
 "revise": "修改",
 "risk": "风险",
 "role": "角色",
 "role_id": "角色ID",
 "role_name": "角色名称",
 "role_type": "角色类别",
 "roll": "Roll",
 "row_no": "行数",
 "s3_key": "S3 Key",
 "sales_mgmt": "业务管理",
 "save": "保存",
 "save_view_is_this_view_default": "预设报表模板",
 "save_view_is_this_view_default_when_no_default": "无预设报表模板时，则设定此为预设",
 "scac": "SCAC",
 "scan_qr_code": "扫描二维码",
 "sea": "海运",
 "seal_no": "封条号码",
 "search": "搜寻",
 "search_fail": "查找失败",
 "search_key": "搜寻关键字",
 "second_bill": "第二張帳單",
 "security_level": "安全级别",
 "select_at_least_one_bol": "至少选择一个送货单(BOL)",
 "select_or_type_in": "选择或输入",
 "selected_bols": "已选中的送货单(BOL)",
 "selected_shipments": "已选中的Shipment",
 "selling_rate": "卖价",
 "semi": "Semi",
 "send_email": "发送Email",
 "sender": "发送方",
 "sent_to_edi_platform": "发送至EDI平台",
 "seq": "序号",
 "sequence": "顺序",
 "service_level": "服务等级",
 "service_type": "服务类型",
 "set_default_query_view_success": "设置预设报表模板成功!",
 "sharp_bols": "送货单(BOL)总数",
 "sharp_deliveries": "Delivery数",
 "sharp_gross_weight": "总重",
 "sharp_gw": "总重",
 "sharp_packages": "包裹数",
 "sharp_pkgs": "包裹数",
 "sharp_shipments": "Shipment数",
 "sharp_shpts": "Shipment数",
 "ship_dateutc0": "起运日期",
 "ship_from": "起运",
 "ship_from_address1": "起运地址一",
 "ship_from_address2": "起运地址二",
 "ship_from_city": "起运城市",
 "ship_from_contact2": "起运联络人",
 "ship_from_ctry": "起运国家/地区",
 "ship_from_name": "起运方",
 "ship_from_state": "起运州/省",
 "ship_from_zip": "起运地邮编",
 "ship_mode": "运输方式",
 "ship_to": "收货",
 "ship_to_address": "收货地址",
 "ship_to_address_localized": "收货地址(中文)",
 "ship_to_address_or_city": "收货地址/城市",
 "ship_to_city": "收货城市",
 "ship_to_city_localized": "收货地址(中文)",
 "ship_to_country": "收货国家/地区",
 "ship_to_name": "收货方",
 "ship_to_state": "收货州/省",
 "shipment": "货件",
 "shipment_check": "指派承运人",
 "shipment_complete": "货件已完成?",
 "shipment_creation_date": "建立时间",
 "shipment_customer": "客户",
 "shipment_details": "货件详情",
 "shipment_dispatch_status": "派车状态",
 "shipment_docs_ready": "文件就绪",
 "shipment_has_bol_please_go_to_tms_mgmt": "该货件存在于送货单 BOL %{bolNumbers} 。",
 "shipment_leg": "送货类型",
 "shipment_mgmt": "货件管理",
 "shipment_mode": "运输方式",
 "shipment_mode_all": "全部",
 "shipment_mode_air": "空运",
 "shipment_mode_comprehensive": "综合",
 "shipment_mode_logistics": "物流",
 "shipment_mode_rail": "铁路",
 "shipment_mode_sea": "海运",
 "shipment_mode_truck": "卡车",
 "shipment_mode_warehouse_truck": "仓储",
 "shipment_no": "HAWB / HBL",
 "shipment_number_is_not_unique": "Shipment号码重复",
 "shipment_number_not_unique": "Shipment号码重复",
 "shipment_progress": "货件状态",
 "shipment_progress_cancel": "已取消",
 "shipment_reference_list_option_apple_dri": "APPLE DRI",
 "shipment_reference_list_option_apple_fdno": "APPLE FD",
 "shipment_reference_list_option_apple_fd": "APPLE FD",
 "shipment_reference_list_option_apple_prno": "APPLE PR",
 "shipment_reference_list_option_box_number": "BOX",
 "shipment_reference_list_option_box": "BOX",
 "shipment_reference_list_option_cost_cnt": "COST CENTER",
 "shipment_reference_list_option_cost_center": "COST CENTER",
 "shipment_reference_list_option_cra": "CRA",
 "shipment_reference_list_option_dc": "DC",
 "shipment_reference_list_option_di": "DI",
 "shipment_reference_list_option_gl_accunt": "GL ACCOUNT",
 "shipment_reference_list_option_gl_account": "GL ACCOUNT",
 "shipment_reference_list_option_in": "IN",
 "shipment_reference_list_option_it": "IT",
 "shipment_reference_list_option_lu": "LU",
 "shipment_reference_list_option_or": "OR",
 "shipment_reference_list_option_ot": "OTHER",
 "shipment_reference_list_option_other": "OTHER",
 "shipment_reference_list_option_pj": "PJ",
 "shipment_reference_list_option_pl": "PL",
 "shipment_reference_list_option_pn": "PN",
 "shipment_reference_list_option_po": "PO",
 "shipment_reference_list_option_rma": "RMA",
 "shipment_reference_list_option_sd": "SD",
 "shipment_reference_list_option_si": "SI",
 "shipment_reference_list_option_sn": "SN",
 "shipment_reference_list_option_s_n": "SN",
 "shipment_reference_list_option_so": "SO",
 "shipment_reference_list_option_s_o": "SO",
 "shipment_reference_list_option_sr": "SR",
 "shipment_reference_list_option_st": "ST",
 "shipment_reference_list_option_umc": "UMC",
 "shipment_reference_list_option_undefined": "未定義",
 "shipment_reference_list_option_whse": "Warehouse",
 "shipment_reference_list_option_cargo_loc": "Cargo Location",
 "shipment_reference_list_option_carrier": "Carrier",
 "shipment_reference_list_option_mawb": "MAWB / MBL",
 "shipment_reference_list_option_hawb": "HAWB / HBL",
 "shipment_reference_list_option_asn": "ASN",
 "shipment_reference_list_option_bol_ref": "BOL ref",
 "shipment_reference_list_option_vsl_name": "Vessel name",
 "shipment_reference_list_option_cntr_no": "Container No",
 "shipment_reference_list_option_cntr_size": "Container Size",
 "shipment_reference_list_tooltip_apple_dri": "APPLE DRI",
 "shipment_reference_list_tooltip_apple_fdno": "Apple FD No.",
 "shipment_reference_list_tooltip_apple_prno": "Apple PR No.",
 "shipment_reference_list_tooltip_box_number": "箱号",
 "shipment_reference_list_tooltip_cost_cnt": "Cost Center No.",
 "shipment_reference_list_tooltip_cra": "罐号",
 "shipment_reference_list_tooltip_dc": "进口及出口申报编号",
 "shipment_reference_list_tooltip_di": "Delivery ID",
 "shipment_reference_list_tooltip_from_prolink": "来自Prolink/CW1",
 "shipment_reference_list_tooltip_gl_accunt": "GL Account No.",
 "shipment_reference_list_tooltip_in": "商业发票号码",
 "shipment_reference_list_tooltip_it": "项目编号",
 "shipment_reference_list_tooltip_lu": "LU编号",
 "shipment_reference_list_tooltip_or": "订单编号",
 "shipment_reference_list_tooltip_ot": "其他",
 "shipment_reference_list_tooltip_pj": "专案名称",
 "shipment_reference_list_tooltip_pl": "箱单号码",
 "shipment_reference_list_tooltip_pn": "料件编号",
 "shipment_reference_list_tooltip_po": "采购订单",
 "shipment_reference_list_tooltip_rma": "退料授权号码",
 "shipment_reference_list_tooltip_sd": "SDAF号码",
 "shipment_reference_list_tooltip_si": "SID编号",
 "shipment_reference_list_tooltip_sn": "序号",
 "shipment_reference_list_tooltip_so": "S/O No.",
 "shipment_reference_list_tooltip_sr": "Shipment Reference No. / Shipment Request No.",
 "shipment_reference_list_tooltip_st": "样式编号",
 "shipment_reference_list_tooltip_umc": "UMC参考清单",
 "shipment_truck_status": "卡车货态",
 "shipment_type": "Shipment Type",
 "shipments": "货件",
 "shipments_added": "已加入的货件",
 "shipments_already_had_preassigned_carrier_make_sure_to_change": "%{shipments} 已指派承运人，需要重新指派吗？",
 "shipper": "发货方",
 "shipper_code": "发货方代码",
 "shipper_name": "发货方",
 "shipping_address": "送货地址",
 "shipping_key": "Shipping Key",
 "shipping_qty": "出货数量",
 "shp_mode": "运输方式",
 "shpr": "发货方",
 "sign_by": "签收人",
 "sign_by_name": "签收人名",
 "signed_by": "签收人",
 "sku": "SKU",
 "sla": "SLA",
 "smaller_than_start_number": "小于起始值",
 "smaller_than_start_on": "小于起始值",
 "smaller_then_starton": "小于开始条件",
 "sn": "SN",
 "something_went_wrong": "发生错误，请重试或联络mec_onehelp@morrisonexpress.com",
 "something_wrong": "发生错误，请重试或联络mec_onehelp@morrisonexpress.com",
 "something_wrong_error": "发生错误! %{error}",
 "special_delivery_instruction": "特殊指示",
 "special_instruction": "特殊指示",
 "split": "拆分",
 "split_hawb": "拆分提单",
 "split_package_quantity_is_invalid": "The split package quantity is invalid.",
 "split_package_unit_is_invalid": "The split package unit is invalid.",
 "sprinter": "Sprinter",
 "standard": "标准",
 "standard_carrier_alpha_code": "SCAC",
 "start_on": "开始于",
 "state": "州/省",
 "station": "站点",
 "station_approval": "站上批准",
 "status": "状态",
 "step": "步骤",
 "step_step": "步骤%{step}",
 "step1_edit_data": "Step 1: Edit Data",
 "step2_check_result": "Step 2: Check Result",
 "submit": "送出",
 "submitted_to_customs": "提交至海关",
 "subscription": "訂閱",
 "subscription_name": "訂閱名稱",
 "success": "成功",
 "summary": "摘要",
 "support_doc": "证明文件",
 "svc_lv": "服务等级",
 "svc_lvl": "服务等级",
 "svc_type": "服务类型",
 "system": "系统",
 "target_delivery": "要求送达",
 "target_delivery_date": "要求送达日期",
 "target_delivery_date_utc": "要求送达时间 (UTC)",
 "target_delivery_from": "要求送达时间(起)",
 "target_delivery_to": "要求送达时间(讫)",
 "tea": "TEA #",
 "tel": "电话",
 "tel_no": "电话号码",
 "terminal_to_import_cfs": "航站至进口仓",
 "terms_and_conditions": "条款与条件",
 "thank_you": "谢谢!",
 "the_account_already_exists": "帐号已存在",
 "the_file_is_being_uploaded": "文件正在上传...",
 "the_next_number_of_days": "下一个天数",
 "the_past_number_of_days": "上一个天数",
 "the_text_will_be_shown_on_all_of_the_labels": "这段文字会在所有的标签上显示",
 "third_party_bill": "第三方帐单",
 "this_hawb_has_not_yet_linked_to_an_amat_id_please_click_get_edi_to_link_one": "此HAWB尚未与一个AMAT ID关联",
 "this_is_a_comment": "这是注释...",
 "timezone": "時區",
 "title": "标题",
 "tms_fast_dispatch_create_step_1": "建立送货单(BOL)",
 "tms_fast_dispatch_create_step_2": "建立派车单(Dispatch)",
 "tms_mgmt": "卡车管理",
 "to": "收货",
 "to_address": "收货地址",
 "to_address_localized": "收货地址(中文)",
 "to_be_completed": "待完成",
 "to_city": "收货城市",
 "to_city_localized": "收货城市(中文)",
 "to_country": "收货国家/地区",
 "to_country_localized": "收货国家/地区",
 "to_name": "收货方",
 "to_state": "收货州/省",
 "to_zipcode": "收货邮递区号",
 "total_amount_selected": "总选取金额:",
 "total_bols": "送货单(BOL)数",
 "total_cartons": "箱子总数",
 "total_charge_weight": "总收费重",
 "total_cw": "收费总重",
 "total_deliveries": "交货数",
 "total_deliverys": "Delivery数",
 "total_gross_weight": "总毛重",
 "total_gw": "总毛重",
 "total_hawbs": "HAWB数",
 "total_loose_cartons": "散装箱总数",
 "total_packages": "包裹数",
 "total_pallets": "栈板总数",
 "total_pieces": "总件数",
 "total_records": "所有记录",
 "total_shipments": "Shipment总数",
 "total_volume": "总体积",
 "total_weight": "总重量",
 "tracking": "追踪",
 "tracking_daily_report": "Tracking Daily Report",
 "tracking_device_number": "追踪器号码",
 "tracking_no": "追踪号码",
 "tracking_result": "追踪结果",
 "trade": "Trade",
 "trade_type": "Trade Type",
 "transfer_direction": "Transfer Direction",
 "truck_no": "车牌号码",
 "truck_size_10_tons": "10 Tons",
 "truck_size_point_five_tons": "0.5 Tons",
 "truck_size_1_tons": "1 Tons",
 "truck_size_2_tons": "2 Tons",
 "truck_size_3_tons": "3 Tons",
 "truck_size_40_hq": "40 HQ",
 "truck_size_45_hq": "45 HQ",
 "truck_size_5_tons": "5 Tons",
 "truck_size_53_hq": "53 HQ",
 "truck_size_8_tons": "8 Tons",
 "truck_size_24_foot": "24 Foot",
 "truck_size_26_foot": "26 Foot",
 "truck_size_53_foot": "53 Foot",
 "truck_type": "车型",
 "truck_type_semi": "半卦式卡车",
 "truck_type_sprinter": "Sprinter厢式货车",
 "truck_type_van": "普通厢式货车",
 "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未储存的资料将遗失，您确定要离开吗?",
 "un_number": "UN Number",
 "unit": "单位*",
 "unit_value": "单位价值",
 "unknown_message_code": "未知讯息代码",
 "unloading_in_progress_date": "Unloading in Progress Date",
 "unreceived": "未收货",
 "uom": "单位",
 "update": "更新",
 "update_hawb_pkg_data": "更新提单的包裹资料",
 "update_mawb": "更新主单",
 "update_pod": "更新 POD",
 "update_success": "更新成功",
 "updated_by": "更新者",
 "updated_date": "更新时间",
 "updated_date_utc": "更新时间 (UTC)",
 "updated_utc_time": "更新时间",
 "upload": "上传",
 "upload_by": "上传者",
 "upload_date_time": "上传时间",
 "upload_file": "上传文件",
 "upload_file_section": "上传档案区块",
 "upload_file_to_s3_failure": "上传档案至伺服器失败",
 "upload_invoice": "上传发票",
 "upload_license": "上传驾照",
 "upload_license_failed": "上传驾照失败",
 "upload_photo": "\b上传图片",
 "uploadS3Failure": "无法将档案上传到云端(S3)",
 "uppercase_privacy_policy": "隱私政策",
 "uppercase_temrs_and_condition": "条款和条件",
 "uppercase_terms_and_conditions": "條款和條件",
 "urgent": "急件",
 "urgent_delivery": "急件",
 "urgent_use": "未收到EDI",
 "user": "用户",
 "user_account_request_form": "使用者申請表",
 "user_information": "用户资讯",
 "user_mgmt": "使用者管理",
 "user_not_found": "找不到使用者: %{email}",
 "user_registration_form": "使用者注册表单",
 "user_registration_query": "使用者申请查询",
 "user_type": "用户类型",
 "value": "值",
 "van": "箱型车",
 "variance": "差异金额",
 "variance_approved_by": "差异金额批准者",
 "vendor": "供应商",
 "vendor_billing_portal": "供应商帐单管理平台",
 "vessel_name": "船名",
 "view_name": "报表模板名称",
 "view_no_data": "报表模板无资料",
 "view_pod": "查看 POD",
 "view_type": "报表模板类型",
 "vip": "VIP",
 "vip_name": "客戶",
 "vol": "体积",
 "volume": "体积",
 "volume_uom": "体积单位",
 "volume_weight": "体积重量",
 "voyage": "航程",
 "vw": "体积重量",
 "wait_for_approval": "等待批准",
 "waiting_for_approval": "等待批准",
 "warehouse_truck": "仓储",
 "warehouse_trucking": "仓储",
 "warning": "警告",
 "warning_row": "Warning Row",
 "was_existed_in_the_other_dispatch": "已存在於其他派车单(Dispatch)",
 "was_not_planning_or_assigned_to_carrier": "状态非“计划中”或“已指派承运人”",
 "we_cannot_access_your_camera": "无法存取相机",
 "weight_unit": "重量单位",
 "weight_uom": "重量单位",
 "welcome_to_morrisonone_platform": "欢迎来到 Morrison One. 请在上方清单中选择一个功能",
 "what_is_cookies": "\"Cookies\" are small files that enable us to store information related to your device and you, the user, specifically, while you visit one of our websites. Cookies help us to determine how frequently our internet pages are accessed as well as the number of users. And they help us configure our offers so that they are as convenient and efficient as possible for you. This includes cookies from third party websites if you visit a page which contains embedded content from such websites. Such third party cookies may track your use of the MEC website.",
 "width": "宽",
 "wms_fast_dispatch_create_step_1": "建立送货单(DN)",
 "wms_fast_dispatch_create_step_2": "建立派车单(Dispatch)",
 "y": "Y",
 "yard": "码",
 "yes": "是",
 "you_donot_have_query_permission_below": "您没有以下权限。",
 "you_have_finished_editing_invoice_no_number": "您已经完成编辑发票号码: %{number}",
 "you_have_finished_uploading_invoice_no_number": "您已经完成上传发票号码: %{number}",
 "you_have_unsaved_change_that_will_be_lost": "您修改的资料尚未储存",
 "you_have_unsaved_changes_are_you_want_to_leave": "您有未保存的修改，确定要离开吗？",
 "your_linked_ref_no": "Your Linked Ref No",
 "your_presearch_donot_have_query_permission_below": "您查詢的資料不會顯示以下條件的內容 :",
 "your_ticket_number_number": "Your Ticket Number = %{number}",
 "yyyy": "YYYY",
 "zip_code": "邮编",
 "zipcode": "邮编",
 "error_code_update_delivery_get_country_info": "Country Name does not exist, please check.",
 "filters": "Filters",
 "export_exceeds_rows_limit": "汇出资料笔数超过上限，请设置更多搜寻条件",
 "export_no_matched_data": "搜索条件没有匹配的数据",
 "unhandled_export_action": "未处理的汇出操作",
 "info": "资讯",
 "empty": "空的",
 "are_you_sure_you_want_to_delete_this_file": "您确定要删除此文件吗？",
 "forwarder_shipment_mgmt": "承运商货件管理",
 "upload_file_number_more": "更多 (%{fileNumber})",
 "save_view_delete_with_subscribers": "无法删除被订阅的 报表模板",
 "issue": "事件",
 "query_more_options": "更多选项",
 "are_you_sure_you_want_to_delete_the_address_book": "您确定要删除此地址? ID: %{id}",
 "select_address_book": "选择地址",
 "create_address_book": "创建地址",
 "edit_address_book": "编辑地址",
 "addressbook": "地址簿",
 "addressbook_name": "名字",
 "address_and_city": "地址 & 城市",
 "floor": "楼层/单位",
 "contact_name": "联络人",
 "phone_number": "电话",
 "quick_guide": "快速指引",
 "quick_guide_empty": "尚无快速指引",
 "action_by": "Action By",
 "booking_request": "Booking Request",
 "is_truck_info_correct": "Are you sure the truck information is currect?",
 "combine": "合并",
 "updated_at_utc": "更新时间(UTC)",
 "sheet_status_not_established": "NOT ESTABLISHED",
 "sheet_status_established": "ESTABLISHED",
 "sheet_status_departed": "DEPARTED",
 "sheet_status_arrived": "ARRIVED",
 "save_and_next": "储存并继续",
 "user_type_customer": "Customer",
 "user_type_morrison": "Morrison",
 "user_type_agent": "Agent",
 "user_type_vendor": "Vendor",
 "user_type_api": "Api",
 "user_type_demo": "Demo",
 "user_type_forwarder": "Forwarder",
 "total": "总数",
 "actual_truck_pickup_at": "预定拣货",
 "estimated_delivery_at": "计划送货日期",
 "clear": "清除",
 "revert": "恢复",
 "you_cannot_cancel_the_shipment_without_a_comment": "没有留言，将无法取消货物",
 "are_you_sure_you_want_to_cancel_the_shipment": "确认删除货物?",
 "please_cancel_this_shipment_on_prolink_cw1": "请在Prolink/CW1取消货物",
 "refresh": "Refresh",
 "last_update": "Last Update: %{time}",
 "type": "类型",
 "token_expired": "登入逾期，请重新登入",
 "error_code_token_expired": "登入逾期，请重新登入",
 "release_date": "Release Date",
 "security_level_standard": "STANDARD",
 "security_level_medium": "MEDIUM",
 "security_level_high": "HIGH",
 "view": "自订报表模板",
 "dropzone_placeholder_default": "点击此处或拖曳文件至此",
 "dropzone_placeholder_on_drag": "请拖曳文件至此",
 "dropzone_error_quantity_limit": "文件超过数量上限",
 "dropzone_error_upload_failed": "上传失败",
 "error_only_english_and_numbers_allowed": "仅能输入英文以及数字",
 "english_and_numbers_input_placeholder": "请输入英文或数字",
 "department_ae": "AE",
 "department_ai": "AI",
 "department_se": "SE",
 "department_si": "SI",
 "department_lg": "LG",
 "department_dt": "DT",
 "service_type_dtd": "DTD",
 "service_type_ata": "ATA",
 "service_type_dta": "DTA",
 "service_type_atd": "ATD",
 "service_type_ptp": "PTP",
 "service_type_dtp": "DTP",
 "service_type_ptd": "PTD",
 "service_type_atp": "ATP",
 "service_type_pta": "PTA",
 "service_type_whtd": "WHTD",
 "service_type_whsp": "WHSP",
 "incoterm_exw": "EXW",
 "incoterm_fca": "FCA",
 "incoterm_fas": "FAS",
 "incoterm_fob": "FOB",
 "incoterm_cpt": "CPT",
 "incoterm_cfr": "CFR",
 "incoterm_cif": "CIF",
 "incoterm_cip": "CIP",
 "incoterm_dap": "DAP",
 "incoterm_ddp": "DDP",
 "incoterm_dat": "DAT",
 "incoterm_dpu": "DPU",
 "incoterm_daf": "DAF",
 "incoterm_des": "DES",
 "incoterm_deq": "DEQ",
 "incoterm_ddu": "DDU",
 "tao_test": " ",
 "shipment_created_at_utc": "Shipment Create (UTC)",
 "validation_wrong_format": "Wrong Format",
 "validation_max_date": "Maximum date %{date}",
 "validation_min_date": "Minimum date %{date}",
 "copy": "复制",
 "paste": "贴上",
 "delete_comment_null_id": "This Comment does not exist",
 "too_many_files": "档案数量太多(最多%{max}个)",
 "pagination_first": "第一页",
 "pagination_last": "最后一页",
 "login": "登录",
 "export_exceed_rows_limit": "Over %{limit} Record, please contact with morrison one help",
 "sql_field_error": "您输入的查询条件有误，请更正后重新查询。",
 "forget_password_success": "若您有註冊帳號將重置密碼郵件將發送至%{email}"
}

export default zhHansDictionary